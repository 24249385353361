<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g :stroke="color">
      <line x1="7" x2="7" y1="0" y2="24" stroke-width="1.5"/>
      <line x1="17" x2="17" y1="0" y2="24" stroke-width="1.5"/>
      <line x1="0" x2="24" y1="7" y2="7" stroke-width="1.5"/>
      <line x1="0" x2="24" y1="17" y2="17" stroke-width="1.5"/>
      <circle cx="7" cy="7" r="3" fill="#3EFFF3" stroke="#3EFFF3"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SnapToGrid',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>