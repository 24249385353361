// Styles
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import { aliases, fa } from 'vuetify/iconsets/fa'

import { mdi } from 'vuetify/iconsets/mdi'
import { md } from 'vuetify/iconsets/md'

import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

export const customColors = {
  primary: '#42E3D9',
  secondary: '#DBFCFA',
  green: '#00D42F',
  orange: '#FFA800',
  purple: '#A500F3'
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  icons: {
    sets: {
      fa, mdi, md
    }
  },
  theme: {
    themes: {
      light: {
        colors: customColors
      },
    },
  },
})