<template>  
  <div style="height:100vh; width:100%; position:relative;">
    <canvas id="grid" v-show="store.gridOn"
      class="canvas-style" style="z-index: 0"
      :class="!(store.activeTool == 'resize' || store.activeTool == 'select') ? 'crossHairCursor' : ''"
      onkeydown = "keyPressed" resize="true">
    </canvas>
    <canvas id="canvas"
      class="canvas-style" style="z-index: 1; width: calc(100vw - (100vw - 100%))"
      :class="!(store.activeTool == 'resize' || store.activeTool == 'select') ? 'crossHairCursor' : ''"
      onkeydown = "keyPressed" resize="true">
    </canvas>
    <div v-if="loading">loading</div>
    <template v-else>
      <!-- Top Bar Greyed Out -->
      <div v-if="store.activeTool== 'resize'" 
        style="position: absolute; top:0px;width:100%; height: 50px; 
        background-color: rgb(185, 185, 185); z-index:1000 !important">
      </div>

      <!-- Top Bar Normal -->
      <div ref="topBar"
        style="position: absolute; background:white; width:100%; height: 50px; top:0px; z-index:999" 
        class="d-flex align-center elevation-1 justify-space-between">
        <div class="d-flex align-center">
          <LogoBtn />
          <v-btn v-if="showProjectName" variant="text" style="max-width: 200px; position: absolute; left: 50px" class="ellipsisText">
            <span class="ellipsisText" style="max-width: 200px;">{{store.projectName}}</span>
          </v-btn>
        </div>
        <div class="d-flex">
          <UndoRedo v-if="!(store.analyzeMode || store.userPermissions == 'Viewer')"></UndoRedo >
          <Tools v-if="!store.analyzeMode"></Tools>
          <AnalysisSettings v-if="store.analyzeMode"></AnalysisSettings>
        </div>
        <div class="d-flex pa-1" style="right:0px">
          <UploadImage v-if="this.store.userPermissions != 'Viewer'"/>
          <Share v-if="canShare" :sketch="modelInfo"></Share>
          <Save v-if="isAuthenticated" @modelInfoUpdated="getModelInfo"></Save>
        </div>
      </div>

      <!-- Top Left -->
      <div v-if="store.lineProps" style="position:absolute; top: 55px; display: block; left: 10px; background: white; padding: 5px; z-index:100">
       X: {{store.lineProps.x}},  Y: {{store.lineProps.y}},  Length: {{store.lineProps.length}}
      </div>

      <!-- Top Center -->
      <div style="position:absolute; top: 15px; display: block; left: 50%; transform: translate(-50px, 0); z-index:1000">
        <UploadImageSettings />
      </div>

      <!-- Bottom Right -->
      <div class="d-flex" style="position: absolute; bottom:8px; right: 5px; z-index: 1">
        <ZoomSettings></ZoomSettings>
        <ViewSettings></ViewSettings>
        <Settings></Settings>
      </div>
      
      <!-- Analyze Messages -->
      <div v-if="store.analyzeMode" class="errorMessage d-flex justify-center" style="width:100%">
        <span v-if="!store.analyze.finishedComputing" class="px-5" style="background: white; color:blue">Calculating...</span>
        <template v-else>
          <span v-if="store.analyze.warning" class="px-5" style="background: white; color:red">{{store.analyze.warning}}</span>
        </template>
      </div>
      <div v-if="store.analyze" class="errorMessage d-flex justify-center" style="width:100%">
        <span v-if="store.analyze.okayToRunWarning" v-html="store.analyze.okayToRunWarning"
          class="px-5" style="background: white; color:red; text-align: center"
        ></span>
      </div>
      <div v-if="store.hintMessage" class="errorMessage d-flex justify-center" style="width:100%">
        <span class="px-5" style="background: white;">{{store.hintMessage}}</span>
      </div>
      
      <!-- Element Properties Panel -->
      <elementProps v-if="store.selectedItems.length > 0 && store.selectionComplete 
        && !store.analyzeMode" style="z-index:1">
      </elementProps>

      <!-- Coordinates Icon -->
      <coordinates-icon width="40" height="40" style="position:absolute; bottom: 15px; left: 15px"/>
    </template>
  </div>
</template>

<script>
import paper from 'paper'
import * as grid from '@/tools/Grid'
import {Grid} from '@/tools/Grid'
import elementProps from '@/components/ElementProps.vue'
import {useStore} from '@/store/store'
import Save from '@/components/Save.vue'
import Share from '@/components/Share.vue'
import UndoRedo from '@/components/UndoRedo.vue'
import * as styles from '@/styles/paperStyles'
import {CreatePaperScopeAndLayers, CreateTools, GenerateScrollPickerValues} from '@/composables/setUp.js'
import coordinatesIcon from '../assets/icons/coordinatesIcon.vue'
import History from "@/utilities/History"
import {PanActivate} from "@/viewportControls/Pan.js"
import {ActivatePinchZoom, WheelZoom} from "@/viewportControls/zoom.js"
import Tools from '@/components/Tools.vue'

import ViewSettings from '@/components/ViewSettings.vue'
import ZoomSettings from '@/components/ZoomSettings.vue'
import AnalysisSettings from '../components/AnalysisSettings.vue'
import toggleButton from '@/components/ui/toggleButton.vue'
import UploadImage from '../components/UploadImage.vue'
import UploadImageSettings from '../components/UploadImageSettings.vue'
import Settings from '@/components/Settings.vue'
import LogoBtn from '@/components/ui/LogoBtn.vue'
import { useAuth0 } from '@auth0/auth0-vue';
import {ref as vueRef} from 'vue'

export default {
  components: {
    grid, 
    elementProps, 
    Save, 
    coordinatesIcon, 
    Share, 
    UndoRedo, 
    Tools, 
    ViewSettings,
    ZoomSettings,
    AnalysisSettings,
    toggleButton,
    UploadImage,
    UploadImageSettings,
    Settings,
    LogoBtn,
  },
 props: ['id'],
  setup(){
    const store = useStore()
    const { isAuthenticated, user } = useAuth0();
    
    store.canvas = new paper.PaperScope()
    const topBar = vueRef(null)
    return {
      store, 
      user, 
      isAuthenticated,
      topBar
    }
  },
  data: () => ({
    maxZoomOut: 0.3,
    pinchDistance: 0,
    modelInfo: null,
    loading: false,
    lastCenter: null,
    bottomLeftMostPoint: null
  }),
  watch: {
    //Prevent touch zoom on topBar
    "topBar": function(){ 
      if(this.topBar){
        this.topBar.addEventListener('touchstart', function touchHandler(event){
          if(event.touches.length > 1){
            event.preventDefault()
          }
        })
      }
    }
  },
  async created(){
    this.loading = true

    //check what kind of devise is being used
    if (window.matchMedia("(pointer: coarse)").matches){
      this.store.onMobile = true
    }
    console.log('onmobile',this.store.onMobile)
    
    //Update store projectId on refresh 
    if (!this.store.projectId) {
      this.store.projectId = this.id
    }

    if (this.id){
      if(!(this.store.users || this.store.userPermissions)){
        await this.getSketchUsersAndPermissions()
      }
      await this.getModelInfo()
      if (this.id && this.modelInfo){
        await this.DrawSketch()
      }
    }
  },
  async mounted() {
    //set up
    this.lastCenter = new paper.Point({x: document.body.clientWidth/2, y: document.body.clientHeight/2})
    const canvas = document.getElementById('canvas')

    this.store.grid = this.initGrid()

    CreatePaperScopeAndLayers()
    CreateTools()
    GenerateScrollPickerValues()
            
    //add pinch and scroll zoom
    canvas.addEventListener('wheel', WheelZoom, {passive: true})
    // window resizing
    window.addEventListener("resize", this.resize);
    // listen for undo/redo key press
    document.addEventListener('keypress', this.keyPressed)
    //disable default right click functionality
    document.addEventListener('contextmenu', event => event.preventDefault());

    // Activate Panning
    PanActivate(canvas)
    
    // Activate Pinch Zoom
    if (this.store.onMobile) ActivatePinchZoom()
    this.loading = false
  },
  computed: {
    canShare(){
      if (this.id && !this.id.includes('template') && this.store.userPermissions != 'Viewer') return true
      else false
    },
    showProjectName(){
      if (this.store.projectName && window.innerWidth > 800) return true
      else false
    }
  },
  methods: {
    initGrid() {
      const gridCanvas = document.getElementById('grid');
      const context = gridCanvas.getContext("2d", { alpha: false });
      
      let {stdCanvasH, stdCanvasW} = this.setGridDims()
      gridCanvas.style.width = stdCanvasW + "px";
      gridCanvas.style.height = stdCanvasH + "px";
      
      let grid = new Grid({
        stdCanvasH,
        stdCanvasW,
        minCellSize: 30,
        context,
        gridCanvas,
      });
      grid.draw()
      return grid
    },
    keyPressed(event){
      if (!this.store.analyzeMode) {
        if (event.ctrlKey && event.charCode == 26){ //ctrl+z
          History.undo()
        }
        if (event.ctrlKey && event.charCode == 25){ //ctrl+y
          History.redo()
        }
      }
    },
    resize(){
      this.setGridDims()
      let currentCenter = new paper.Point({x: window.innerWidth/2, y: window.innerHeight/2})
      let offset = currentCenter.subtract(this.lastCenter)
      let offsetCalibratedToZoomLevel = offset.divide(this.store.canvas.view.zoom)
      this.store.grid.axisPosX += offset.x
      this.store.grid.axisPosY += offset.y
      this.store.grid.lastAxisPosX = this.store.grid.axisPosX
      this.store.grid.lastAxisPosY = this.store.grid.axisPosY 
      this.store.grid.height = window.innerHeight
      this.store.grid.width =  document.body.clientWidth


      this.lastCenter = currentCenter
      this.store.grid.draw()
      this.store.canvas.view.center = this.store.canvas.view.center.subtract(offsetCalibratedToZoomLevel)
    },
    setGridDims(){
      const gridCanvas = document.getElementById('grid');
      const context = gridCanvas.getContext("2d", { alpha: false });

      const stdCanvasW = document.body.clientWidth
      const stdCanvasH = window.innerHeight
      const optCanvasW = stdCanvasW * window.devicePixelRatio;
      const optCanvasH = stdCanvasH * window.devicePixelRatio;

      if (window.devicePixelRatio > 1) {
        gridCanvas.width = optCanvasW;
        gridCanvas.height = optCanvasH;
        context.scale(window.devicePixelRatio, window.devicePixelRatio);
      } else {
        gridCanvas.width = stdCanvasW;
        gridCanvas.height = stdCanvasH;
      }
      gridCanvas.style.width = stdCanvasW + "px";
      gridCanvas.style.height = stdCanvasH + "px";
      
      return {stdCanvasW, stdCanvasH}
    },
    async getSketchUsersAndPermissions(){
      let permissions
      if (this.id.includes('template')) {
        permissions = [this.user];
      }
      else {
        let res = await this.$api({
          url: "/api/permission/_permission/get-permission-by-projectId",
          method: "POST",
          data: {
            projectId: this.id,
          }
        })
        permissions = res.data
      }
      this.store.users = permissions;
      let curUser = permissions.find(permission => permission.email.toLowerCase() == this.user.email.toLowerCase());
      if (curUser) {
        this.store.userPermissions = curUser.permissions;
      } else {
        console.log('unable to get current user permissions')
      }
    },
    async getCanvas(id){
      let sketch = await this.$api({
        url: "/api/sketch/_sketch/get-sketch",
        method: "POST",
        data: {
          sketchId: id,
        }
      })
      return sketch.data
    },
    async getTemplate(id){
      let template = await this.$api({
        url: "/api/template/_template/get-template",
        method: "POST",
        data: {
          templateId: id,
        }
      })
      return template.data
    },
    async getModelInfo(){
      var modelInfo
      //Get template info
      if (this.store.projectId.includes("template")){
        var id = this.id.split("template")[1]
        modelInfo = await this.getTemplate(id)
      }
      //Get sketch info
      else {
        modelInfo = await this.getCanvas(this.store.projectId)
        if (this.store.userPermissions == 'Viewer') this.store.tools.select.deactivate()
      }
      this.modelInfo = modelInfo
      this.store.projectName = modelInfo.name
      
      //Set Canvas Center
      if (modelInfo.settings.canvasCenter){
        this.store.canvas.view.center = modelInfo.settings.canvasCenter
      } 
      else { //Find left bottom most frame point and set as canvas center
        let bottomLeftMostPoint = {x: 10000000000, y: 0}
        modelInfo.elements.forEach(line => {
          if (line.start.y > bottomLeftMostPoint.y || (line.start.y === bottomLeftMostPoint.y && line.start.x < bottomLeftMostPoint.x)) {
            bottomLeftMostPoint = line.start;
          }
          if (line.end.y > bottomLeftMostPoint.y || (line.end.y === bottomLeftMostPoint.y && line.end.x < bottomLeftMostPoint.x)) {
            bottomLeftMostPoint = line.end;
          }
        })
        this.store.canvas.view.center = bottomLeftMostPoint
        this.bottomLeftMostPoint = bottomLeftMostPoint
      }
    },
    getUserPermissions(users){
      let currentUser = users.find(user => user.email.toLowerCase() == this.user.email.toLowerCase())
      return currentUser.permissions
    },
    async DrawSketch(){
      const modelInfo = this.modelInfo
      console.log(modelInfo)

      //set settings
      for (const [key, value] of Object.entries(modelInfo.settings)){
        this.store[key] = value
      }
      //Draw Frames
      modelInfo.elements.forEach(element => {
        var path = new paper.Path(styles.line())
        //If sketch was created with old grid, need to recalibrate element start/end points
        let start = this.bottomLeftMostPoint ? this.mapToNewGrid(element.start) : element.start
        let end = this.bottomLeftMostPoint ? this.mapToNewGrid(element.end) : element.end
        path.add(start, end)
        path._id = element.id
        path.data = {
          start: element.startFixity,
          end: element.endFixity,
          frameProps: element.frameProps,
          layer: this.store.drawingLayer.name,
        }
        if (!path.data.frameProps.hasOwnProperty('orientation')){
          path.data.frameProps.orientation = 0
        }
        this.store.tools.draw.addMemberSizeText(path)
        this.store.drawingLayer.addChild(path)
        this.store.tools.draw.addDimensions(path)
        this.store.tools.draw.addEndNode(path)
      })
      //Draw Supports
      modelInfo.supports.forEach(support => {
        var supportIcon = this.store.tools.supports.newSupportIcon(support.type)
        supportIcon.strokeColor = 'black'
        let supportLocation = this.bottomLeftMostPoint ? this.mapToNewGrid(support.location) : support.location
        supportIcon.position = {x: supportLocation.x, y: supportLocation.y+12}
        supportIcon.data = {
          type: support.type,
          location: supportLocation,
          layer: this.store.supportLayer.name
        }
        this.store.supportLayer.addChild(supportIcon)
      })
      //Line Loads
      modelInfo.lineLoads.forEach(load => {
        //find assoc line
        let line = this.store.drawingLayer.children.find(child => child._id == load.elementId)
        let isLineMass = load.type == 'Line Mass' ? true : false
        let lineLoad = this.store.tools.loads.scaleLineLoad(line, load.magnitude, load.dir, isLineMass)
        this.store.tools.loads.addLoadText(lineLoad)
      })
      //Point Loads
      modelInfo.pointLoads.forEach(load => {
        let location = this.bottomLeftMostPoint ? this.mapToNewGrid(load.location) : load.location
        let pointLoad = this.store.tools.loads.scalePointLoad(location, load.magnitude, load.dir)
        this.store.tools.loads.addLoadText(pointLoad)
      })

      //Image Underlay
      if(modelInfo.imageInfo){
        modelInfo.imageInfo.forEach(async i => {
          let imageURL = await this.$api({
            url: "/api/sketch/_sketch/get-image-download-url",
            method: "POST",
            data: {
              imageId: i.id,
            }
          })
          if (imageURL.data){
            var raster = new paper.Raster({source:imageURL.data[0], position:i.imgPosition});
            raster.onLoad = () => {
              raster.rasterName = i.id;
              let boundingRect = new paper.Path.Rectangle(raster.bounds)
              boundingRect.position = i.imgPosition
              boundingRect.strokeColor = styles.selectedImageColor;
              boundingRect.strokeWidth = 1;
              raster.scale(i.scaling)
              boundingRect.scale(i.scaling)
              raster.rotate(i.rotation)
              boundingRect.rotate(i.rotation)
              raster.opacity = this.store.imageOpacity
              raster.data.boundingRect = boundingRect
              this.store.imageBoundingRectLayer.addChild(boundingRect)
              this.store.imageLayer.addChild(raster)
              this.store.imageBoundingRectLayer.visible = false
            }
          }
          this.store.imageFile = {id: i.id}
        })
      }
    },
    mapToNewGrid(point){
      let scaleFactor = 5/this.modelInfo.settings.gridSize
      let x = (point.x - this.bottomLeftMostPoint.x)*(3/scaleFactor) + this.bottomLeftMostPoint.x
      let y = (point.y - this.bottomLeftMostPoint.y)*(3/scaleFactor) + this.bottomLeftMostPoint.y
      return {x: x, y: y}
    },
  },
  unmounted(){
    if (this.store.activeTool){
      this.store.tools[this.store.activeTool].deactivate()
    }
    if (this.store.canvas.project){
      this.store.canvas.project.clear()
    }

    this.store.projectId = null
    
    //TODO - split stores up into different parts
    let user = this.store.user
    this.store.$reset()
    this.store.setUser(user)

    window.removeEventListener('resize', this.onResize, { passive: true })
    document.removeEventListener('keypress', this.keyPressed)
    History.clear()
  }
}

</script>

<style scoped>
  .canvas-style {
    touch-action: manipulation;
    position: absolute;
    top: 0px;
    left: 0px
  }
  canvas[resize]{
    width: 100vw;
    height: 100vh
  }
  .toolsUI{
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    margin-left: auto;
    margin-right: auto
  }
  .errorMessage{
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    margin-left: auto;
    margin-right: auto
  }
  .uploadImageUI{
    position: absolute;
    margin-left: 100px;
    top: 20px;
  }
</style>
