import { auth0Service } from '@/auth'

export async function goToBilling(){
  const token = await auth0Service.getAccessTokenSilently();
  const user = auth0Service.user.value
  let res = await $.ajax({
    url: "/api/user/_user/manage-billing",
    type: 'POST',
    dataType: 'json',
    data: {
      userId: user.sub
    },
    beforeSend: function (xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    },
    success: (response) => {
      console.log(response)
      let stripeRedirectURL = response.data.url
      if (stripeRedirectURL)
        location.href = stripeRedirectURL
    },
    error: function (error) {
    }
  });
}

export async function selectPlan(plan) {
  let stripePlanId
  if (plan == 1){
    stripePlanId = 'price_1OkRPGSCXXPesu7h7n8ZN9M6'
  }
  else {
    stripePlanId = 'price_1OkRPmSCXXPesu7hzyTfiog9'
  }
  const token = await auth0Service.getAccessTokenSilently();
  const user = auth0Service.user.value

  let res = await $.ajax({
    url: "/api/user/_user/subscribe-to-plan",
    type: 'POST',
    dataType: 'json',
    data: {
      userId: user.sub, 
      stripe_plan_id: stripePlanId, 
      success_url: "/account", callback_url: "/account"
    },
    beforeSend: function (xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    },
    success: (response) => {
      let stripeRedirectURL = response.data.url
      if (stripeRedirectURL)
        location.href = stripeRedirectURL
    },
    error: function (error) {
    }
  });
}

export async function verifyAcademicEmail() {
  const token = await auth0Service.getAccessTokenSilently();
  const user = auth0Service.user.value
  let res = await $.ajax({
    url: "/api/user/_user/verify-academic-email",
    type: 'POST',
    dataType: 'json',
    data: {
      email: user.email
    },
    beforeSend: function (xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    },
    success: (response) => {
    },
    error: function (error) {
    }
  });
  return res.data
}
