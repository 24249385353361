window._ = require('lodash');
window.moment = require('moment');
window.$ = window.jQuery = require('jquery');

import "../src/styles/main.css";
import "../src/styles/paperStyles.js";

// Components
import App from './App.vue'
import ScrollPicker from 'vue3-scroll-picker';

// Composables
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { GesturePlugin } from '@vueuse/gesture';
import { auth0Service } from '@/auth';


// Plugins
import { registerPlugins } from '@/plugins'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import router from "@/router"
import {GenerateScrollPickerValues} from "@/composables/setUp.js"
import './registerServiceWorker'
import { installApiPlugin } from "./plugins/apiPlugin.js";

//install highlight
import { H } from 'highlight.run';
if (process.env.NODE_ENV != 'development'){
  H.init('2d1k1jer', {
    environment: 'production',
    version: 'commit:abcdefg12345',
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      "https://www.googleapis.com/identitytoolkit",
      "https://securetoken.googleapis.com",
      ],
    },
      enableCanvasRecording: true,        // enable canvas recording
      samplingStrategy: {
        canvas: 2,                        // snapshot at 2 fps
        canvasMaxSnapshotDimension: 480,  // snapshot at a max 480p resolution
      },
  });
}

async function main() {
  const vuetify = createVuetify({
    components,
    directives,
  })
  const pinia = createPinia()

  const app = createApp(App)
  app.use(pinia)
  app.use(router)
  app.use(ScrollPicker);
  app.use(vuetify)
  app.use(GesturePlugin)
  app.use(auth0Service)
  installApiPlugin(app)

  registerPlugins(app)
  app.mount('#app')
  GenerateScrollPickerValues()
}

main();