<template>
  <v-app-bar color="white" :elevation="0" dense light>
    <LogoBtn />
    <div class="text-uppercase black--text text-body-1 ml-1">
      <span class="font-weight-bold" style="font-family: Ruda; letter-spacing:0px;">
        FORCESKETCH
      </span>
    </div>
    <v-spacer></v-spacer>
    <SignInUpBtn @signInUpBtnClick="dialog = !dialog"/>
  </v-app-bar>
</template>

<script>
import {useStore} from '@/store/store'
import SignInUpBtn from '@/components/SignInUpBtn.vue'
import LogoBtn from '@/components/ui/LogoBtn.vue'

export default {
  components: {
    SignInUpBtn,
    LogoBtn
  },
  setup () {
    const store = useStore()
    return {store}
  },
  data: () => ({
    dialog: false
  }),
  computed: {
  },
  methods: {
  }
};
</script>
<style scoped>
.header {
  align-items: center;
}
.signIn:hover{
  cursor: pointer;
}
.create:hover{
  cursor: pointer;
}
</style>
