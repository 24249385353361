<template>
  <div style="width: 24px; height: 24px">
    <v-icon :color="color" size="20">mdi:mdi-checkbox-blank-circle-outline</v-icon>
    <v-icon :color="color" size="29" style="margin-bottom: -16px; margin-left: -24px">mdi:mdi-minus</v-icon>
  </div>
</template>

<script>
export default {
  name: 'rollerX',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
