import paper from 'paper'
import * as utils from '../utilities/Utils.js'
import * as styles from '../styles/paperStyles.js'
import { useStore } from '@/store/store.js'
import History from '@/utilities/History.js'
import { SupportCommand, BatchCommand } from '@/utilities/Commands.js'

export class Supports{
  store = useStore()
  constructor(){
    this.store = this.store
    this.subTool = 'pin'
    this.closestPoint = null
    this.supportIcon = null
    this.lineToSplit = null
  }
  onMouseDown = (e) => {
    if (this.store.onMobile){
      const [line, point] = utils.getClosestLinePoint(e.point, 10/this.store.grid.canvasZoom)
      if (point) this.closestPoint = point
      if (line) this.lineToSplit = line
    }
    if (this.closestPoint){
      var supportIcon = this.supportIcon.clone()
      supportIcon.strokeColor = styles.supportIcon.strokeColor
      supportIcon.position = this.getSupportPoint(e)
      supportIcon.data = {
        type: this.subTool,
        location: {x: this.closestPoint.x, y: this.closestPoint.y},
        layer: this.store.supportLayer.name
      }
      this.store.supportLayer.addChild(supportIcon)
      
      let batchCommand = new BatchCommand()
      if (this.lineToSplit){
        let dist1 = utils.distance2Points(this.lineToSplit.segments[0].point, this.closestPoint)
        let dist2 = utils.distance2Points(this.lineToSplit.segments[1].point, this.closestPoint)
        if (dist1 < 1 || dist2 < 1) { 
          //do nothing 
        }
        else this.store.tools.draw.hitAndSplit(this.closestPoint, this.lineToSplit, batchCommand)
      }
      this.closestPoint = null
      this.lineToSplit = null
      batchCommand.add(new SupportCommand(supportIcon, true, false))
      History.add(batchCommand)
    }
    else {
      this.store.snackbarText = 'Supports can only be placed on frames'
      this.store.snackbar = true
    }
  }
  onMouseMove = (e) => {
    this.lineToSplit = null
    this.closestPoint = null
    const [line, point] = utils.getClosestLinePoint(e.point, 10/this.store.grid.canvasZoom)
    if (point) this.closestPoint = point
    if (line) this.lineToSplit = line
    this.drawSupport(e)
  }
  activate(subTool){
    if (subTool) this.subTool = subTool
    this.supportIcon = this.newSupportIcon(subTool)
    this.store.supportLayer.addChild(this.supportIcon)
    this.scaleInitial()
    this.store.canvas.tool.onMouseDown = this.onMouseDown
    if (!this.store.onMobile){
      this.store.canvas.tool.onMouseMove = this.onMouseMove
    }
  }
  scaleInitial(support){
    if (!support) support = this.supportIcon
    support.scale(1/this.store.grid.canvasZoom)
    support.children.forEach(child =>{
      child.strokeWidth /= this.store.grid.canvasZoom
    })
  }
  deactivate(){
    if (this.supportIcon) this.supportIcon.remove()
    this.store.canvas.tool.onMouseDown = null
    this.store.canvas.tool.onMouseMove = null
    this.closestPoint = null
  }
  drawSupport(e){
    var supportPoint = this.getSupportPoint(e)
    this.supportIcon.position = supportPoint
    this.supportPoint = supportPoint
    if (this.closestPoint) this.supportIcon.strokeColor = styles.supportIcon.strokeColor
    else this.supportIcon.strokeColor = styles.disabledColor
  }
  getSupportPoint(e){
    var supportPoint
    if (this.closestPoint){
      supportPoint = {x: this.closestPoint.x, y: this.closestPoint.y  + this.supportIcon.bounds.height/2}
    }
    else {
      supportPoint = {x: e.point.x, y: e.point.y + this.supportIcon.bounds.height/2}
    } 
    return supportPoint
  }
  newSupportIcon(subTool){
    var icon
    switch (subTool){
      case 'pin': 
        var triangle = new paper.Path.RegularPolygon({x:0, y:0}, 3, 15)
        triangle.strokeWidth = 1.5
        icon = new paper.Group(triangle)
        break;
      case 'rollerX':
        var circle = new paper.Path.Circle({
          center: {x: 0, y:0},
          radius: 12
        })
        circle.strokeWidth = 1.5
        var line = new paper.Path([-14,12], [14,12])
        line.strokeWidth = 1.5
        icon = new paper.Group(circle, line)
        break
      case 'rollerY':
        var circle = new paper.Path.Circle({
          center: {x: 0, y:0},
          radius: 12
        })
        circle.strokeWidth = 1.5
        var line = new paper.Path([12,-14], [12,14])
        line.strokeWidth = 1.5
        icon = new paper.Group(circle, line)
        break
      case 'fix': 
        var groundLine = new paper.Path([0,0], [25, 0])
        groundLine.strokeWidth = 1.5
        var line1 = new paper.Path([7, 0], [0, 10])
        line1.strokeWidth = 0.5
        var line2 = new paper.Path([14, 0], [7, 10])
        line2.strokeWidth = 0.5
        var line3 = new paper.Path([21, 0], [14, 10])
        line3.strokeWidth = 0.5
        var hiddenDot = new paper.Path([12.5, -5], [12.5, -6])
        var iconGroup = new paper.Group(groundLine, line1, line2, line3, hiddenDot)
        icon = iconGroup
          break
    } 
    icon.data.layer = 'supportLayer'
    return icon
  }
}