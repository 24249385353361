<template>
  <scroll-picker 
    :wheel-speed="wheelSpeed"
    style="background: white; height: 36px; width: 110px; border-radius: 5px"
    :options="scrollPickerValues" 
    v-model="childSelection"
    inactive-style="color: lightgrey; font-size: 12px;"
    active-style="color: black; font-size: 14px">
    <template v-slot:option="prop" >
      <div class="custom-option">
        {{ prop.item.label }}
      </div>
    </template>
    <template v-slot:top-overlay>
      <div class="custom-top-overlay"></div>
    </template>
    <template v-slot:bottom-overlay>
      <div class="custom-bottom-overlay"></div>
    </template>
    <template v-slot:center-overlay>
      <div class="custom-center-overlay"></div>
    </template>
  </scroll-picker>
</template>

<script>
import {useStore} from '@/store/store'
import {scrollPickerValues} from '@/composables/setUp.js'

export default {
  props: {
    selection: Array,
  },
  setup(){
    const store = useStore()
    return {store, scrollPickerValues}
  },
  data: () => ({
    wheelSpeed: 5,
    options: null,
  }),
  created(){
  },
  mounted(){
  },
  computed: {
    childSelection: {
      get() {
        let value = this.selection
        let scrollPickerFormat = ['0', '0', '0', '.', '0']
        let charsArray = value.toFixed(1).toString().split('')
        charsArray.reverse().forEach((char, index) => {
          scrollPickerFormat[4-index] = char
        })
        return scrollPickerFormat
      },
      set(value) {
        let convertedValue = Number(value[0])*100 + Number(value[1])*10 + Number(value[2]) + Number(value[4]/10)
        this.$emit('scroll', convertedValue)
      }
    }
  },
}
</script>

<style scoped>
.custom-center-overlay {
  height: 20px;
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: lightgrey;
}
.custom-top-overlay {
  height: 10px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
}
.custom-bottom-overlay {
  height: 10px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
}
.custom-option {
  padding: 10px 10px; 
  height: 20px; 
  width: 25px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center; 
  align-items: center
}
#column-3 .custom-option {
  width:5px !important;
  padding: 2px 2px !important
}
</style>
