<template>
  <div class="rounded-lg pa-1 bg-white mr-1 ml-1">
    <v-menu :open-on-hover="!store.onMobile" open-delay=0>
      <template v-slot:activator="{props}">
        <v-btn v-bind="props" variant="text" size="35">
          <v-img width="30" height="30" :src="require('@/assets/imgs/ForceSketchLogo.svg')" />
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item @click="$router.push('/dashboard')" v-if="$router.currentRoute.value.fullPath != '/dashboard' && store.getUser">
            Dashboard
          </v-list-item>
          <v-list-item value="tutorials">
            <a href="https://core-studio.gitbook.io/forcesketch/" target="_blank" style="color: inherit; text-decoration: none;">
              Tutorials
            </a>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'

export default {
  components: { toggleButton},
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    
  }),
  created(){
  },
  computed: {},
  mounted() {},
}
</script>