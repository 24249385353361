// This is not a paper.js tool and rather a series of functions that are activated on wheel and pinching
// It is always active and can be used at the same time as paper tools

import {useStore} from '@/store/store'
import paper from 'paper'
import * as hammer from 'hammerjs'

export const  maxZoomOut = 0.01
export const maxZoomIn = 15

export function WheelZoom(e){
  const store = useStore()
  const zoom = e.wheelDelta / 120;
  store.grid.setZoom(zoom, e.clientX, e.clientY);
}

export function ActivatePinchZoom() {
  const store = useStore()
  const canvasElement = store.canvas.view.element;
  const box = canvasElement.getBoundingClientRect();
  const offset = new paper.Point(box.left, box.top);

  if (!store.canvasElement){
    store.pinchHammer = new Hammer(canvasElement, {});
  }
  
  store.pinchHammer.get('pinch').set({ enable: true });

  let startMatrix, startMatrixInverted, p0ProjectCoords, startPoint, pinchStarted;

  store.pinchHammer.on('pinchstart', e => {
    pinchStarted = true
    startMatrix = store.canvas.view.matrix.clone();
    startMatrixInverted = startMatrix.inverted();
    startPoint = getCenterPoint(e);
    p0ProjectCoords = store.canvas.view.viewToProject(startPoint);
    store.grid.lastCanvasZoom = store.canvas.view.zoom
  });

  store.pinchHammer.on('pinch', e => {
    if (!pinchStarted) return
    if (e.additionalEvent == 'pinchin' && store.canvas.view.zoom < maxZoomOut) return
    if (e.additionalEvent == 'pinchout' && store.canvas.view.zoom > maxZoomIn) return 

    const pProject0 = startPoint.transform(startMatrixInverted);
    const delta = pProject0.subtract(p0ProjectCoords).divide(e.scale);
    store.canvas.view.matrix = startMatrix.clone().scale(e.scale, p0ProjectCoords).translate(delta);
    
    store.grid.pinchZoom(e)
  });

  store.pinchHammer.on('pinchend', e => {
    pinchStarted = false
  })

  function getCenterPoint(e) {
    return new paper.Point(e.center.x, e.center.y).subtract(offset);
  }
}