<template>
  <div class="d-flex rounded-lg pa-1 pr-0">
    <toggleButton 
      :tooltip="'Undo (Ctrl+Z)'" 
      @click="History.undo(), modifiers.removeSelection()">
      <v-icon>mdi:mdi-undo</v-icon>
    </toggleButton>
    <toggleButton 
      :tooltip="'Redo (Ctrl+y)'" 
      @click="History.redo(), modifiers.removeSelection()">
      <v-icon>mdi:mdi-redo</v-icon>
    </toggleButton>
    <v-divider vertical thickness="2" class="border-opacity-100 mx-2 my-1"></v-divider>
  </div>
  
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'

export default {
  components: { toggleButton},
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    
  }),
  created(){},
  computed: {},
  mounted() {},
}
</script>
