<template>
  <v-btn size="36"
    flat :style="active ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: #E3FFFD' : ''" 
    style="margin:0 2px 0 2px">
    <slot></slot>
    <v-tooltip v-if="!store.onMobile && tooltip" activator="parent" location="bottom">
      {{tooltip}}
    </v-tooltip>
  </v-btn>
</template>

<script>
import {useStore} from '@/store/store'

export default {
  props: {
    icon: {type: String},
    tooltip: {type: String},
    active: {type: Boolean}
  },
  setup(){
    const store = useStore()
    return {store}
  },
  data: () => ({  
  }),
  created(){},
  computed: {},
  mounted() {},
  methods: {}
}
</script>