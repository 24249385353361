<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 -3 24 15">
    <g :stroke="color">
        <line x1="0" x2="26" y1="0" y2="0" stroke-width="2.5"/>
        <line x1="7" x2="0" y1="0" y2="10" stroke-width="1.5"/>
        <line x1="14" x2="7" y1="0" y2="10" stroke-width="1.5"/>
        <line x1="21" x2="14" y1="0" y2="10" stroke-width="1.5"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'fixIcon',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>