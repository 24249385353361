<template>
  <div class="body d-flex justify-center align-center">
    <div>
      <h3>{{text}}</h3>
    </div>
  </div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';
export default {
  setup(){
    const { isAuthenticated, user } = useAuth0();
    return {
      isAuthenticated,
      user
    }
  },
  data(){
    return {
      text: null
    }
  },
  created(){
    if (this.user && !this.user.email_verified) this.text = "Please verify email to proceed"
    else this.text = "Not Authorized"
  }
};
</script>

<style scoped>
.body {
  background: white;
  background-image: radial-gradient(rgb(194, 194, 194) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  height: 100%;
}
</style>
