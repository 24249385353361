<template>
  <div class="d-flex justify-space-between" style="width: 210px">
    <div class="rounded d-flex ml-1" style="border: 1px solid darkGrey; width:fit-content; height: fit-content; padding:1px">
      <v-btn v-for="unit in units" :key="unit" flat style="margin:1px"
        :style="selectedUnit == unit ? 'border: 1px solid rgb(var(--v-theme-primary)); background-color: rgb(var(--v-theme-secondary))' : ''"
        @click="selectUnit(unit)"
      >
        {{unit}}
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  props: ['defaultUnit'],
  setup() {
    
  },
  data: () => ({
    selectedUnit: null,
    units: ['metric', 'imperial']
  }),
  created(){
    this.selectedUnit = this.defaultUnit
  },
  methods: {
    selectUnit(unit){
      if (this.selectedUnit == unit) return
      else {
        this.selectedUnit = unit
        this.$emit('unitChange', unit)
      }
    }
  }
}
</script>
