// karamba doesn't have an imperial circle catalogue, so these shapes have been converted
// So have to convert to these if using a shape in a unit that Karamba doesn't know

export const defaultMetric = {name: 'W360X51', shape: "I", family: "W-metric", height: 360, weight: 50.018497}
export const defaultImperial = {name: 'W14X30', shape: "I", family: "W-imperial", height: 14, weight: 30.845617}

export const shapes_imperial= {
    I: [
        {name: 'W4X13', shape: "I", family: "W-imperial", height: 4, weight: 13.381798},
        {name: 'W5X16', shape: "I", family: "W-imperial", height: 5, weight: 16.207329},
        {name: 'W5X19', shape: "I", family: "W-imperial", height: 5, weight: 19.177221},
        {name: 'W6X8.5', shape: "I", family: "W-imperial", height: 6, weight: 8.625562},
        {name: 'W6X9', shape: "I", family: "W-imperial", height: 6, weight: 9.456691},
        {name: 'W6X15', shape: "I", family: "W-imperial", height: 6, weight: 15.263671},
        {name: 'W6X12', shape: "I", family: "W-imperial", height: 6, weight: 12.276899},
        {name: 'W6X20', shape: "I", family: "W-imperial", height: 6, weight: 20.423378},
        {name: 'W6X16', shape: "I", family: "W-imperial", height: 6, weight: 16.483137},
        {name: 'W6X25', shape: "I", family: "W-imperial", height: 6, weight: 25.431972},
        {name: 'W8X10', shape: "I", family: "W-imperial", height: 8, weight: 10.453753},
        {name: 'W8X24', shape: "I", family: "W-imperial", height: 8, weight: 24.589692},
        {name: 'W8X13', shape: "I", family: "W-imperial", height: 8, weight: 13.439256},
        {name: 'W8X31', shape: "I", family: "W-imperial", height: 8, weight: 31.88749},
        {name: 'W8X28', shape: "I", family: "W-imperial", height: 8, weight: 28.72429},
        {name: 'W8X15', shape: "I", family: "W-imperial", height: 8, weight: 15.600303},
        {name: 'W8X35', shape: "I", family: "W-imperial", height: 8, weight: 35.658925},
        {name: 'W8X18', shape: "I", family: "W-imperial", height: 8, weight: 18.22081},
        {name: 'W8X21', shape: "I", family: "W-imperial", height: 8, weight: 21.315482},
        {name: 'W8X40', shape: "I", family: "W-imperial", height: 8, weight: 40.266942},
        {name: 'W8X48', shape: "I", family: "W-imperial", height: 8, weight: 48.638967},
        {name: 'W8X58', shape: "I", family: "W-imperial", height: 8, weight: 58.713685},
        {name: 'W8X67', shape: "I", family: "W-imperial", height: 8, weight: 67.788561},
        {name: 'W10X33', shape: "I", family: "W-imperial", height: 10, weight: 33.858201},
        {name: 'W10X12', shape: "I", family: "W-imperial", height: 10, weight: 12.232874},
        {name: 'W10X39', shape: "I", family: "W-imperial", height: 10, weight: 39.73951},
        {name: 'W10X15', shape: "I", family: "W-imperial", height: 10, weight: 15.273835},
        {name: 'W10X49', shape: "I", family: "W-imperial", height: 10, weight: 49.522167},
        {name: 'W10X17', shape: "I", family: "W-imperial", height: 10, weight: 17.297508},
        {name: 'W10X45', shape: "I", family: "W-imperial", height: 10, weight: 45.553932},
        {name: 'W10X54', shape: "I", family: "W-imperial", height: 10, weight: 54.616278},
        {name: 'W10X19', shape: "I", family: "W-imperial", height: 10, weight: 19.494926},
        {name: 'W10X22', shape: "I", family: "W-imperial", height: 10, weight: 22.312558},
        {name: 'W10X60', shape: "I", family: "W-imperial", height: 10, weight: 60.717617},
        {name: 'W10X26', shape: "I", family: "W-imperial", height: 10, weight: 26.250736},
        {name: 'W10X68', shape: "I", family: "W-imperial", height: 10, weight: 68.647631},
        {name: 'W10X30', shape: "I", family: "W-imperial", height: 10, weight: 30.447098},
        {name: 'W10X77', shape: "I", family: "W-imperial", height: 10, weight: 77.711351},
        {name: 'W10X88', shape: "I", family: "W-imperial", height: 10, weight: 88.875738},
        {name: 'W10X100', shape: "I", family: "W-imperial", height: 10, weight: 100.64291},
        {name: 'W10X112', shape: "I", family: "W-imperial", height: 10, weight: 113.160982},
        {name: 'W12X14', shape: "I", family: "W-imperial", height: 12, weight: 14.703405},
        {name: 'W12X40', shape: "I", family: "W-imperial", height: 12, weight: 41.004852},
        {name: 'W12X16', shape: "I", family: "W-imperial", height: 12, weight: 16.677177},
        {name: 'W12X45', shape: "I", family: "W-imperial", height: 12, weight: 45.856334},
        {name: 'W12X53', shape: "I", family: "W-imperial", height: 12, weight: 53.997012},
        {name: 'W12X65', shape: "I", family: "W-imperial", height: 12, weight: 65.941087},
        {name: 'W12X19', shape: "I", family: "W-imperial", height: 12, weight: 19.671019},
        {name: 'W12X26', shape: "I", family: "W-imperial", height: 12, weight: 26.525907},
        {name: 'W12X50', shape: "I", family: "W-imperial", height: 12, weight: 50.633437},
        {name: 'W12X58', shape: "I", family: "W-imperial", height: 12, weight: 58.663994},
        {name: 'W12X22', shape: "I", family: "W-imperial", height: 12, weight: 22.637811},
        {name: 'W12X30', shape: "I", family: "W-imperial", height: 12, weight: 30.4689},
        {name: 'W12X72', shape: "I", family: "W-imperial", height: 12, weight: 72.458067},
        {name: 'W12X79', shape: "I", family: "W-imperial", height: 12, weight: 79.92016},
        {name: 'W12X35', shape: "I", family: "W-imperial", height: 12, weight: 35.657784},
        {name: 'W12X87', shape: "I", family: "W-imperial", height: 12, weight: 87.983285},
        {name: 'W12X96', shape: "I", family: "W-imperial", height: 12, weight: 96.842637},
        {name: 'W12X106', shape: "I", family: "W-imperial", height: 12, weight: 106.648578},
        {name: 'W12X120', shape: "I", family: "W-imperial", height: 12, weight: 121.191464},
        {name: 'W12X136', shape: "I", family: "W-imperial", height: 12, weight: 136.933403},
        {name: 'W14X22', shape: "I", family: "W-imperial", height: 14, weight: 22.570845},
        {name: 'W14X43', shape: "I", family: "W-imperial", height: 14, weight: 44.28216},
        {name: 'W12X152', shape: "I", family: "W-imperial", height: 12, weight: 153.287045},
        {name: 'W14X30', shape: "I", family: "W-imperial", height: 14, weight: 30.845617},
        {name: 'W14X48', shape: "I", family: "W-imperial", height: 14, weight: 49.229229},
        {name: 'W14X26', shape: "I", family: "W-imperial", height: 14, weight: 26.904509},
        {name: 'W14X53', shape: "I", family: "W-imperial", height: 14, weight: 54.185787},
        {name: 'W14X61', shape: "I", family: "W-imperial", height: 14, weight: 62.624356},
        {name: 'W14X34', shape: "I", family: "W-imperial", height: 14, weight: 35.040868},
        {name: 'W14X68', shape: "I", family: "W-imperial", height: 14, weight: 69.378841},
        {name: 'W14X90', shape: "I", family: "W-imperial", height: 14, weight: 90.557431},
        {name: 'W12X170', shape: "I", family: "W-imperial", height: 12, weight: 171.068086},
        {name: 'W14X38', shape: "I", family: "W-imperial", height: 14, weight: 38.723348},
        {name: 'W14X74', shape: "I", family: "W-imperial", height: 14, weight: 75.555714},
        {name: 'W14X99', shape: "I", family: "W-imperial", height: 14, weight: 99.888279},
        {name: 'W14X82', shape: "I", family: "W-imperial", height: 14, weight: 83.224738},
        {name: 'W14X109', shape: "I", family: "W-imperial", height: 14, weight: 109.816195},
        {name: 'W12X190', shape: "I", family: "W-imperial", height: 12, weight: 191.39068},
        {name: 'W14X120', shape: "I", family: "W-imperial", height: 14, weight: 120.976349},
        {name: 'W14X132', shape: "I", family: "W-imperial", height: 14, weight: 133.002182},
        {name: 'W12X210', shape: "I", family: "W-imperial", height: 12, weight: 211.513505},
        {name: 'W14X145', shape: "I", family: "W-imperial", height: 14, weight: 146.041809},
        {name: 'W14X159', shape: "I", family: "W-imperial", height: 14, weight: 159.92272},
        {name: 'W12X230', shape: "I", family: "W-imperial", height: 12, weight: 231.979795},
        {name: 'W14X176', shape: "I", family: "W-imperial", height: 14, weight: 177.149502},
        {name: 'W12X252', shape: "I", family: "W-imperial", height: 12, weight: 253.715576},
        {name: 'W14X193', shape: "I", family: "W-imperial", height: 14, weight: 194.153501},
        {name: 'W16X26', shape: "I", family: "W-imperial", height: 16, weight: 27.210117},
        {name: 'W14X211', shape: "I", family: "W-imperial", height: 14, weight: 211.69382},
        {name: 'W16X31', shape: "I", family: "W-imperial", height: 16, weight: 32.344225},
        {name: 'W16X36', shape: "I", family: "W-imperial", height: 16, weight: 37.17454},
        {name: 'W12X279', shape: "I", family: "W-imperial", height: 12, weight: 280.021953},
        {name: 'W16X40', shape: "I", family: "W-imperial", height: 16, weight: 41.737363},
        {name: 'W14X233', shape: "I", family: "W-imperial", height: 14, weight: 234.052494},
        {name: 'W16X45', shape: "I", family: "W-imperial", height: 16, weight: 46.728018},
        {name: 'W16X50', shape: "I", family: "W-imperial", height: 16, weight: 51.344803},
        {name: 'W16X67', shape: "I", family: "W-imperial", height: 16, weight: 68.71066},
        {name: 'W12X305', shape: "I", family: "W-imperial", height: 12, weight: 306.977498},
        {name: 'W16X57', shape: "I", family: "W-imperial", height: 16, weight: 58.335379},
        {name: 'W14X257', shape: "I", family: "W-imperial", height: 14, weight: 258.599679},
        {name: 'W16X77', shape: "I", family: "W-imperial", height: 16, weight: 78.399223},
        {name: 'W14X283', shape: "I", family: "W-imperial", height: 14, weight: 284.669197},
        {name: 'W16X89', shape: "I", family: "W-imperial", height: 16, weight: 91.000647},
        {name: 'W12X336', shape: "I", family: "W-imperial", height: 12, weight: 338.325119},
        {name: 'W16X100', shape: "I", family: "W-imperial", height: 16, weight: 101.955693},
        {name: 'W14X311', shape: "I", family: "W-imperial", height: 14, weight: 312.359474},
        {name: 'W14X342', shape: "I", family: "W-imperial", height: 14, weight: 343.279468},
        {name: 'W18X35', shape: "I", family: "W-imperial", height: 18, weight: 36.098664},
        {name: 'W18X40', shape: "I", family: "W-imperial", height: 18, weight: 41.491333},
        {name: 'W14X370', shape: "I", family: "W-imperial", height: 14, weight: 372.273581},
        {name: 'W18X50', shape: "I", family: "W-imperial", height: 18, weight: 51.168088},
        {name: 'W18X46', shape: "I", family: "W-imperial", height: 18, weight: 47.1551},
        {name: 'W18X55', shape: "I", family: "W-imperial", height: 18, weight: 56.144508},
        {name: 'W18X60', shape: "I", family: "W-imperial", height: 18, weight: 61.700126},
        {name: 'W18X76', shape: "I", family: "W-imperial", height: 18, weight: 77.349368},
        {name: 'W14X398', shape: "I", family: "W-imperial", height: 14, weight: 400.362606},
        {name: 'W18X65', shape: "I", family: "W-imperial", height: 18, weight: 65.923355},
        {name: 'W18X86', shape: "I", family: "W-imperial", height: 18, weight: 87.388199},
        {name: 'W18X71', shape: "I", family: "W-imperial", height: 18, weight: 72.403163},
        {name: 'W18X97', shape: "I", family: "W-imperial", height: 18, weight: 98.596244},
        {name: 'W18X106', shape: "I", family: "W-imperial", height: 18, weight: 107.25652},
        {name: 'W14X426', shape: "I", family: "W-imperial", height: 14, weight: 428.796269},
        {name: 'W18X119', shape: "I", family: "W-imperial", height: 18, weight: 120.886707},
        {name: 'W14X455', shape: "I", family: "W-imperial", height: 14, weight: 457.140168},
        {name: 'W18X130', shape: "I", family: "W-imperial", height: 18, weight: 131.344063},
        {name: 'W18X143', shape: "I", family: "W-imperial", height: 18, weight: 144.274469},
        {name: 'W14X500', shape: "I", family: "W-imperial", height: 14, weight: 502.030927},
        {name: 'W18X158', shape: "I", family: "W-imperial", height: 18, weight: 159.107751},
        {name: 'W18X175', shape: "I", family: "W-imperial", height: 18, weight: 176.181141},
        {name: 'W14X550', shape: "I", family: "W-imperial", height: 14, weight: 552.330496},
        {name: 'W18X192', shape: "I", family: "W-imperial", height: 18, weight: 193.676502},
        {name: 'W21X48', shape: "I", family: "W-imperial", height: 21, weight: 49.093375},
        {name: 'W21X44', shape: "I", family: "W-imperial", height: 21, weight: 45.236698},
        {name: 'W18X211', shape: "I", family: "W-imperial", height: 18, weight: 212.958285},
        {name: 'W21X50', shape: "I", family: "W-imperial", height: 21, weight: 51.446636},
        {name: 'W21X55', shape: "I", family: "W-imperial", height: 21, weight: 56.606935},
        {name: 'W14X605', shape: "I", family: "W-imperial", height: 14, weight: 608.104809},
        {name: 'W21X62', shape: "I", family: "W-imperial", height: 21, weight: 63.497076},
        {name: 'W21X57', shape: "I", family: "W-imperial", height: 21, weight: 58.389252},
        {name: 'W21X68', shape: "I", family: "W-imperial", height: 21, weight: 69.490889},
        {name: 'W18X234', shape: "I", family: "W-imperial", height: 18, weight: 235.936691},
        {name: 'W21X73', shape: "I", family: "W-imperial", height: 21, weight: 74.691829},
        {name: 'W21X83', shape: "I", family: "W-imperial", height: 21, weight: 84.542706},
        {name: 'W21X101', shape: "I", family: "W-imperial", height: 21, weight: 102.47958},
        {name: 'W21X111', shape: "I", family: "W-imperial", height: 21, weight: 113.098609},
        {name: 'W18X258', shape: "I", family: "W-imperial", height: 18, weight: 259.824487},
        {name: 'W21X93', shape: "I", family: "W-imperial", height: 21, weight: 94.142736},
        {name: 'W14X665', shape: "I", family: "W-imperial", height: 14, weight: 667.797773},
        {name: 'W21X122', shape: "I", family: "W-imperial", height: 21, weight: 123.312111},
        {name: 'W21X132', shape: "I", family: "W-imperial", height: 21, weight: 133.737144},
        {name: 'W18X283', shape: "I", family: "W-imperial", height: 18, weight: 284.917698},
        {name: 'W21X147', shape: "I", family: "W-imperial", height: 21, weight: 148.49055},
        {name: 'W18X311', shape: "I", family: "W-imperial", height: 18, weight: 313.415988},
        {name: 'W14X730', shape: "I", family: "W-imperial", height: 14, weight: 733.152493},
        {name: 'W21X166', shape: "I", family: "W-imperial", height: 21, weight: 167.346188},
        {name: 'W21X182', shape: "I", family: "W-imperial", height: 21, weight: 184.060675},
        {name: 'W21X201', shape: "I", family: "W-imperial", height: 21, weight: 203.072022},
        {name: 'W24X55', shape: "I", family: "W-imperial", height: 24, weight: 57.1789},
        {name: 'W24X62', shape: "I", family: "W-imperial", height: 24, weight: 63.216533},
        {name: 'W24X68', shape: "I", family: "W-imperial", height: 24, weight: 70.354049},
        {name: 'W24X76', shape: "I", family: "W-imperial", height: 24, weight: 77.541739},
        {name: 'W24X84', shape: "I", family: "W-imperial", height: 24, weight: 85.381211},
        {name: 'W24X104', shape: "I", family: "W-imperial", height: 24, weight: 105.428181},
        {name: 'W24X94', shape: "I", family: "W-imperial", height: 24, weight: 96.387742},
        {name: 'W24X117', shape: "I", family: "W-imperial", height: 24, weight: 118.550413},
        {name: 'W24X103', shape: "I", family: "W-imperial", height: 24, weight: 104.51439},
        {name: 'W24X131', shape: "I", family: "W-imperial", height: 24, weight: 133.119993},
        {name: 'W24X146', shape: "I", family: "W-imperial", height: 24, weight: 147.881346},
        {name: 'W24X162', shape: "I", family: "W-imperial", height: 24, weight: 164.366859},
        {name: 'W24X176', shape: "I", family: "W-imperial", height: 24, weight: 177.213655},
        {name: 'W24X192', shape: "I", family: "W-imperial", height: 24, weight: 193.335038},
        {name: 'W24X207', shape: "I", family: "W-imperial", height: 24, weight: 208.39759},
        {name: 'W24X229', shape: "I", family: "W-imperial", height: 24, weight: 230.496027},
        {name: 'W24X250', shape: "I", family: "W-imperial", height: 24, weight: 252.013153},
        {name: 'W27X84', shape: "I", family: "W-imperial", height: 27, weight: 85.133762},
        {name: 'W24X279', shape: "I", family: "W-imperial", height: 24, weight: 281.172206},
        {name: 'W27X94', shape: "I", family: "W-imperial", height: 27, weight: 94.863424},
        {name: 'W27X102', shape: "I", family: "W-imperial", height: 27, weight: 103.454456},
        {name: 'W24X306', shape: "I", family: "W-imperial", height: 24, weight: 307.282134},
        {name: 'W27X114', shape: "I", family: "W-imperial", height: 27, weight: 114.963919},
        {name: 'W27X146', shape: "I", family: "W-imperial", height: 27, weight: 147.933755},
        {name: 'W24X335', shape: "I", family: "W-imperial", height: 24, weight: 337.176026},
        {name: 'W27X129', shape: "I", family: "W-imperial", height: 27, weight: 129.580701},
        {name: 'W27X161', shape: "I", family: "W-imperial", height: 27, weight: 162.199826},
        {name: 'W27X178', shape: "I", family: "W-imperial", height: 27, weight: 179.139159},
        {name: 'W24X370', shape: "I", family: "W-imperial", height: 24, weight: 373.322423},
        {name: 'W27X194', shape: "I", family: "W-imperial", height: 27, weight: 194.774393},
        {name: 'W27X217', shape: "I", family: "W-imperial", height: 27, weight: 218.222216},
        {name: 'W27X235', shape: "I", family: "W-imperial", height: 27, weight: 236.517209},
        {name: 'W27X258', shape: "I", family: "W-imperial", height: 27, weight: 259.297953},
        {name: 'W27X281', shape: "I", family: "W-imperial", height: 27, weight: 283.207641},
        {name: 'W30X90', shape: "I", family: "W-imperial", height: 30, weight: 90.404463},
        {name: 'W27X307', shape: "I", family: "W-imperial", height: 27, weight: 308.582334},
        {name: 'W30X99', shape: "I", family: "W-imperial", height: 30, weight: 99.761505},
        {name: 'W30X108', shape: "I", family: "W-imperial", height: 30, weight: 109.429543},
        {name: 'W30X116', shape: "I", family: "W-imperial", height: 30, weight: 117.926356},
        {name: 'W27X336', shape: "I", family: "W-imperial", height: 27, weight: 338.167077},
        {name: 'W30X124', shape: "I", family: "W-imperial", height: 30, weight: 125.599138},
        {name: 'W30X132', shape: "I", family: "W-imperial", height: 30, weight: 133.568152},
        {name: 'W30X173', shape: "I", family: "W-imperial", height: 30, weight: 175.257977},
        {name: 'W27X368', shape: "I", family: "W-imperial", height: 27, weight: 369.909763},
        {name: 'W30X148', shape: "I", family: "W-imperial", height: 30, weight: 149.079505},
        {name: 'W30X191', shape: "I", family: "W-imperial", height: 30, weight: 192.334896},
        {name: 'W30X211', shape: "I", family: "W-imperial", height: 30, weight: 213.162295},
        {name: 'W30X235', shape: "I", family: "W-imperial", height: 30, weight: 236.257422},
        {name: 'W30X261', shape: "I", family: "W-imperial", height: 30, weight: 262.358218},
        {name: 'W30X292', shape: "I", family: "W-imperial", height: 30, weight: 293.140098},
        {name: 'W30X326', shape: "I", family: "W-imperial", height: 30, weight: 327.337537},
        {name: 'W27X539', shape: "I", family: "W-imperial", height: 27, weight: 541.282871},
        {name: 'W30X357', shape: "I", family: "W-imperial", height: 30, weight: 358.251873},
        {name: 'W33X118', shape: "I", family: "W-imperial", height: 33, weight: 119.011479},
        {name: 'W33X130', shape: "I", family: "W-imperial", height: 33, weight: 131.380242},
        {name: 'W30X391', shape: "I", family: "W-imperial", height: 30, weight: 392.891865},
        {name: 'W33X141', shape: "I", family: "W-imperial", height: 33, weight: 143.007581},
        {name: 'W33X152', shape: "I", family: "W-imperial", height: 33, weight: 154.328417},
        {name: 'W33X201', shape: "I", family: "W-imperial", height: 33, weight: 202.752298},
        {name: 'W33X169', shape: "I", family: "W-imperial", height: 33, weight: 169.479166},
        {name: 'W33X221', shape: "I", family: "W-imperial", height: 33, weight: 223.54977},
        {name: 'W33X241', shape: "I", family: "W-imperial", height: 33, weight: 242.700451},
        {name: 'W33X263', shape: "I", family: "W-imperial", height: 33, weight: 264.796243},
        {name: 'W33X291', shape: "I", family: "W-imperial", height: 33, weight: 292.618431},
        {name: 'W33X318', shape: "I", family: "W-imperial", height: 33, weight: 319.605433},
        {name: 'W36X135', shape: "I", family: "W-imperial", height: 36, weight: 135.998126},
        {name: 'W33X354', shape: "I", family: "W-imperial", height: 33, weight: 356.108996},
        {name: 'W36X150', shape: "I", family: "W-imperial", height: 36, weight: 151.344218},
        {name: 'W36X160', shape: "I", family: "W-imperial", height: 36, weight: 160.340203},
        {name: 'W33X387', shape: "I", family: "W-imperial", height: 33, weight: 389.492806},
        {name: 'W36X170', shape: "I", family: "W-imperial", height: 36, weight: 170.923715},
        {name: 'W36X182', shape: "I", family: "W-imperial", height: 36, weight: 183.094753},
        {name: 'W36X194', shape: "I", family: "W-imperial", height: 36, weight: 194.736616},
        {name: 'W36X231', shape: "I", family: "W-imperial", height: 36, weight: 232.423505},
        {name: 'W36X210', shape: "I", family: "W-imperial", height: 36, weight: 211.141059},
        {name: 'W36X247', shape: "I", family: "W-imperial", height: 36, weight: 247.570838},
        {name: 'W36X262', shape: "I", family: "W-imperial", height: 36, weight: 262.732989},
        {name: 'W36X282', shape: "I", family: "W-imperial", height: 36, weight: 283.85789},
        {name: 'W36X232', shape: "I", family: "W-imperial", height: 36, weight: 232.308083},
        {name: 'W36X302', shape: "I", family: "W-imperial", height: 36, weight: 303.92317},
        {name: 'W36X256', shape: "I", family: "W-imperial", height: 36, weight: 257.179336},
        {name: 'W36X330', shape: "I", family: "W-imperial", height: 36, weight: 331.00299},
        {name: 'W36X361', shape: "I", family: "W-imperial", height: 36, weight: 362.083271},
        {name: 'W40X149', shape: "I", family: "W-imperial", height: 40, weight: 149.607993},
        {name: 'W36X395', shape: "I", family: "W-imperial", height: 36, weight: 397.367335},
        {name: 'W40X167', shape: "I", family: "W-imperial", height: 40, weight: 168.203183},
        {name: 'W40X199', shape: "I", family: "W-imperial", height: 40, weight: 200.165389},
        {name: 'W36X441', shape: "I", family: "W-imperial", height: 36, weight: 442.968501},
        {name: 'W40X183', shape: "I", family: "W-imperial", height: 40, weight: 183.496663},
        {name: 'W40X215', shape: "I", family: "W-imperial", height: 40, weight: 216.305549},
        {name: 'W36X487', shape: "I", family: "W-imperial", height: 36, weight: 489.824882},
        {name: 'W40X211', shape: "I", family: "W-imperial", height: 40, weight: 211.910441},
        {name: 'W40X249', shape: "I", family: "W-imperial", height: 40, weight: 250.17411},
        {name: 'W40X235', shape: "I", family: "W-imperial", height: 40, weight: 235.78097},
        {name: 'W40X277', shape: "I", family: "W-imperial", height: 40, weight: 278.263292},
        {name: 'W40X297', shape: "I", family: "W-imperial", height: 40, weight: 298.250982},
        {name: 'W36X529', shape: "I", family: "W-imperial", height: 36, weight: 531.498148},
        {name: 'W40X264', shape: "I", family: "W-imperial", height: 40, weight: 264.660715},
        {name: 'W40X278', shape: "I", family: "W-imperial", height: 40, weight: 279.235851},
        {name: 'W40X324', shape: "I", family: "W-imperial", height: 40, weight: 327.370582},
        {name: 'W40X294', shape: "I", family: "W-imperial", height: 40, weight: 294.221627},
        {name: 'W40X362', shape: "I", family: "W-imperial", height: 40, weight: 365.258179},
        {name: 'W40X372', shape: "I", family: "W-imperial", height: 40, weight: 373.759218},
        {name: 'W40X327', shape: "I", family: "W-imperial", height: 40, weight: 327.322407},
        {name: 'W40X331', shape: "I", family: "W-imperial", height: 40, weight: 333.204471},
        {name: 'W40X397', shape: "I", family: "W-imperial", height: 40, weight: 400.36108},
        {name: 'W36X652', shape: "I", family: "W-imperial", height: 36, weight: 655.776992},
        {name: 'W40X431', shape: "I", family: "W-imperial", height: 40, weight: 432.223741},
        {name: 'W40X392', shape: "I", family: "W-imperial", height: 40, weight: 393.884347},
        {name: 'W40X503', shape: "I", family: "W-imperial", height: 40, weight: 505.444722},
        {name: 'W44X230', shape: "I", family: "W-imperial", height: 44, weight: 232.168329},
        {name: 'W40X593', shape: "I", family: "W-imperial", height: 40, weight: 595.302652},
        {name: 'W44X262', shape: "I", family: "W-imperial", height: 44, weight: 265.181425},
        {name: 'W44X290', shape: "I", family: "W-imperial", height: 44, weight: 293.838289},
        {name: 'W44X335', shape: "I", family: "W-imperial", height: 44, weight: 336.887034},
  ],
    Rect: [
        {name: 'SHS 1.5x0.1875', shape: "Rect", family: "SHS-imperial", height: 1.5, weight: 3.078037},
        {name: 'SHS 2x0.125', shape: "Rect", family: "SHS-imperial", height: 2, weight: 3.090385},
        {name: 'SHS 2x0.1875', shape: "Rect", family: "SHS-imperial", height: 2, weight: 4.368378},
        {name: 'SHS 2x0.25', shape: "Rect", family: "SHS-imperial", height: 2, weight: 5.387578},
        {name: 'SHS 2x0.3125', shape: "Rect", family: "SHS-imperial", height: 2, weight: 6.308827},
        {name: 'SHS 2.5x0.125', shape: "Rect", family: "SHS-imperial", height: 2.5, weight: 3.950613},
        {name: 'SHS 2.5x0.1875', shape: "Rect", family: "SHS-imperial", height: 2.5, weight: 5.65872},
        {name: 'SHS 2.5x0.25', shape: "Rect", family: "SHS-imperial", height: 2.5, weight: 7.081151},
        {name: 'SHS 2.5x0.3125', shape: "Rect", family: "SHS-imperial", height: 2.5, weight: 8.432515},
        {name: 'RHS 2.5x1.5x0.1875', shape: "Rect", family: "RHS-imperial", height: 2.5, weight: 4.368378},
        {name: 'RHS 2.5x1.5x0.25', shape: "Rect", family: "RHS-imperial", height: 2.5, weight: 5.387578},
        {name: 'SHS 3x0.125', shape: "Rect", family: "SHS-imperial", height: 3, weight: 4.810841},
        {name: 'SHS 3x0.1875', shape: "Rect", family: "SHS-imperial", height: 3, weight: 6.949062},
        {name: 'SHS 3x0.25', shape: "Rect", family: "SHS-imperial", height: 3, weight: 8.774725},
        {name: 'SHS 3x0.3125', shape: "Rect", family: "SHS-imperial", height: 3, weight: 10.556202},
        {name: 'RHS 3x2x0.125', shape: "Rect", family: "RHS-imperial", height: 3, weight: 3.777291},
        {name: 'RHS 3x2x0.1875', shape: "Rect", family: "RHS-imperial", height: 3, weight: 5.255544},
        {name: 'RHS 3x2x0.25', shape: "Rect", family: "RHS-imperial", height: 3, weight: 6.601901},
        {name: 'RHS 3x2x0.3125', shape: "Rect", family: "RHS-imperial", height: 3, weight: 7.896404},
        {name: 'SHS 3.5x0.125', shape: "Rect", family: "SHS-imperial", height: 3.5, weight: 5.671068},
        {name: 'SHS 3.5x0.1875', shape: "Rect", family: "SHS-imperial", height: 3.5, weight: 8.239404},
        {name: 'SHS 3.5x0.25', shape: "Rect", family: "SHS-imperial", height: 3.5, weight: 10.468299},
        {name: 'SHS 3.5x0.3125', shape: "Rect", family: "SHS-imperial", height: 3.5, weight: 12.67989},
        {name: 'SHS 4x0.125', shape: "Rect", family: "SHS-imperial", height: 4, weight: 6.531296},
        {name: 'SHS 4x0.1875', shape: "Rect", family: "SHS-imperial", height: 4, weight: 9.529745},
        {name: 'SHS 4x0.25', shape: "Rect", family: "SHS-imperial", height: 4, weight: 12.161872},
        {name: 'SHS 4x0.3125', shape: "Rect", family: "SHS-imperial", height: 4, weight: 14.803577},
        {name: 'SHS 4x0.375', shape: "Rect", family: "SHS-imperial", height: 4, weight: 17.29021},
        {name: 'SHS 4x0.5', shape: "Rect", family: "SHS-imperial", height: 4, weight: 21.700234},
        {name: 'RHS 4x2x0.125', shape: "Rect", family: "RHS-imperial", height: 4, weight: 4.602304},
        {name: 'RHS 4x2x0.1875', shape: "Rect", family: "RHS-imperial", height: 4, weight: 6.455562},
        {name: 'RHS 4x2x0.25', shape: "Rect", family: "RHS-imperial", height: 4, weight: 8.176924},
        {name: 'RHS 4x2x0.3125', shape: "Rect", family: "RHS-imperial", height: 4, weight: 9.871433},
        {name: 'RHS 4x2x0.375', shape: "Rect", family: "RHS-imperial", height: 4, weight: 11.56305},
        {name: 'RHS 4x3x0.125', shape: "Rect", family: "RHS-imperial", height: 4, weight: 5.427316},
        {name: 'RHS 4x3x0.1875', shape: "Rect", family: "RHS-imperial", height: 4, weight: 7.65558},
        {name: 'RHS 4x3x0.25', shape: "Rect", family: "RHS-imperial", height: 4, weight: 9.751948},
        {name: 'RHS 4x3x0.3125', shape: "Rect", family: "RHS-imperial", height: 4, weight: 11.846463},
        {name: 'SHS 4.5x0.125', shape: "Rect", family: "SHS-imperial", height: 4.5, weight: 7.391524},
        {name: 'SHS 4.5x0.1875', shape: "Rect", family: "SHS-imperial", height: 4.5, weight: 10.820087},
        {name: 'SHS 4.5x0.25', shape: "Rect", family: "SHS-imperial", height: 4.5, weight: 13.855446},
        {name: 'SHS 4.5x0.3125', shape: "Rect", family: "SHS-imperial", height: 4.5, weight: 16.927264},
        {name: 'SHS 4.5x0.375', shape: "Rect", family: "SHS-imperial", height: 4.5, weight: 19.844012},
        {name: 'SHS 5x0.125', shape: "Rect", family: "SHS-imperial", height: 5, weight: 8.251752},
        {name: 'SHS 5x0.1875', shape: "Rect", family: "SHS-imperial", height: 5, weight: 12.110429},
        {name: 'SHS 5x0.25', shape: "Rect", family: "SHS-imperial", height: 5, weight: 15.549019},
        {name: 'SHS 5x0.3125', shape: "Rect", family: "SHS-imperial", height: 5, weight: 19.050952},
        {name: 'SHS 5x0.375', shape: "Rect", family: "SHS-imperial", height: 5, weight: 22.397813},
        {name: 'SHS 5x0.5', shape: "Rect", family: "SHS-imperial", height: 5, weight: 28.528292},
        {name: 'RHS 5x2x0.125', shape: "Rect", family: "RHS-imperial", height: 5, weight: 5.427316},
        {name: 'RHS 5x2x0.1875', shape: "Rect", family: "RHS-imperial", height: 5, weight: 7.65558},
        {name: 'RHS 5x2x0.25', shape: "Rect", family: "RHS-imperial", height: 5, weight: 9.751948},
        {name: 'RHS 5x2x0.3125', shape: "Rect", family: "RHS-imperial", height: 5, weight: 11.846463},
        {name: 'RHS 5x3x0.1875', shape: "Rect", family: "RHS-imperial", height: 5, weight: 8.855598},
        {name: 'RHS 5x3x0.25', shape: "Rect", family: "RHS-imperial", height: 5, weight: 11.326971},
        {name: 'RHS 5x3x0.3125', shape: "Rect", family: "RHS-imperial", height: 5, weight: 13.821492},
        {name: 'RHS 5x3x0.375', shape: "Rect", family: "RHS-imperial", height: 5, weight: 16.413122},
        {name: 'RHS 5x3x0.5', shape: "Rect", family: "RHS-imperial", height: 5, weight: 20.249613},
        {name: 'RHS 5x4x0.1875', shape: "Rect", family: "RHS-imperial", height: 5, weight: 10.055616},
        {name: 'RHS 5x4x0.25', shape: "Rect", family: "RHS-imperial", height: 5, weight: 12.901995},
        {name: 'RHS 5x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 5, weight: 15.796522},
        {name: 'RHS 5x4x0.375', shape: "Rect", family: "RHS-imperial", height: 5, weight: 18.838158},
        {name: 'SHS 5.5x0.125', shape: "Rect", family: "SHS-imperial", height: 5.5, weight: 9.11198},
        {name: 'SHS 5.5x0.1875', shape: "Rect", family: "SHS-imperial", height: 5.5, weight: 13.400771},
        {name: 'SHS 5.5x0.25', shape: "Rect", family: "SHS-imperial", height: 5.5, weight: 17.242593},
        {name: 'SHS 5.5x0.3125', shape: "Rect", family: "SHS-imperial", height: 5.5, weight: 21.174639},
        {name: 'SHS 5.5x0.375', shape: "Rect", family: "SHS-imperial", height: 5.5, weight: 24.951614},
        {name: 'SHS 6x0.125', shape: "Rect", family: "SHS-imperial", height: 6, weight: 9.972208},
        {name: 'SHS 6x0.1875', shape: "Rect", family: "SHS-imperial", height: 6, weight: 14.691112},
        {name: 'SHS 6x0.25', shape: "Rect", family: "SHS-imperial", height: 6, weight: 18.936166},
        {name: 'SHS 6x0.3125', shape: "Rect", family: "SHS-imperial", height: 6, weight: 23.298327},
        {name: 'SHS 6x0.375', shape: "Rect", family: "SHS-imperial", height: 6, weight: 27.505416},
        {name: 'SHS 6x0.5', shape: "Rect", family: "SHS-imperial", height: 6, weight: 35.35635},
        {name: 'SHS 6x0.625', shape: "Rect", family: "SHS-imperial", height: 6, weight: 42.494695},
        {name: 'RHS 6x2x0.1875', shape: "Rect", family: "RHS-imperial", height: 6, weight: 8.855598},
        {name: 'RHS 6x2x0.25', shape: "Rect", family: "RHS-imperial", height: 6, weight: 11.326971},
        {name: 'RHS 6x2x0.3125', shape: "Rect", family: "RHS-imperial", height: 6, weight: 13.821492},
        {name: 'RHS 6x2x0.375', shape: "Rect", family: "RHS-imperial", height: 6, weight: 16.413122},
        {name: 'RHS 6x3x0.1875', shape: "Rect", family: "RHS-imperial", height: 6, weight: 10.055616},
        {name: 'RHS 6x3x0.25', shape: "Rect", family: "RHS-imperial", height: 6, weight: 12.901995},
        {name: 'RHS 6x3x0.3125', shape: "Rect", family: "RHS-imperial", height: 6, weight: 15.796522},
        {name: 'RHS 6x3x0.375', shape: "Rect", family: "RHS-imperial", height: 6, weight: 18.838158},
        {name: 'RHS 6x4x0.1875', shape: "Rect", family: "RHS-imperial", height: 6, weight: 11.255634},
        {name: 'RHS 6x4x0.25', shape: "Rect", family: "RHS-imperial", height: 6, weight: 14.477018},
        {name: 'RHS 6x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 6, weight: 17.771551},
        {name: 'RHS 6x4x0.375', shape: "Rect", family: "RHS-imperial", height: 6, weight: 21.263194},
        {name: 'RHS 6x4x0.5', shape: "Rect", family: "RHS-imperial", height: 6, weight: 26.599707},
        {name: 'SHS 7x0.1875', shape: "Rect", family: "SHS-imperial", height: 7, weight: 17.271796},
        {name: 'SHS 7x0.25', shape: "Rect", family: "SHS-imperial", height: 7, weight: 22.323313},
        {name: 'SHS 7x0.3125', shape: "Rect", family: "SHS-imperial", height: 7, weight: 27.545702},
        {name: 'SHS 7x0.375', shape: "Rect", family: "SHS-imperial", height: 7, weight: 32.613018},
        {name: 'SHS 7x0.5', shape: "Rect", family: "SHS-imperial", height: 7, weight: 42.184409},
        {name: 'SHS 7x0.625', shape: "Rect", family: "SHS-imperial", height: 7, weight: 51.043209},
        {name: 'RHS 7x3x0.1875', shape: "Rect", family: "RHS-imperial", height: 7, weight: 11.255634},
        {name: 'RHS 7x3x0.25', shape: "Rect", family: "RHS-imperial", height: 7, weight: 14.477018},
        {name: 'RHS 7x3x0.3125', shape: "Rect", family: "RHS-imperial", height: 7, weight: 17.771551},
        {name: 'RHS 7x3x0.375', shape: "Rect", family: "RHS-imperial", height: 7, weight: 21.263194},
        {name: 'RHS 7x4x0.1875', shape: "Rect", family: "RHS-imperial", height: 7, weight: 12.455651},
        {name: 'RHS 7x4x0.25', shape: "Rect", family: "RHS-imperial", height: 7, weight: 16.052041},
        {name: 'RHS 7x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 7, weight: 19.74658},
        {name: 'RHS 7x4x0.375', shape: "Rect", family: "RHS-imperial", height: 7, weight: 23.68823},
        {name: 'RHS 7x5x0.1875', shape: "Rect", family: "RHS-imperial", height: 7, weight: 13.655669},
        {name: 'RHS 7x5x0.25', shape: "Rect", family: "RHS-imperial", height: 7, weight: 17.627065},
        {name: 'RHS 7x5x0.3125', shape: "Rect", family: "RHS-imperial", height: 7, weight: 21.72161},
        {name: 'RHS 7x5x0.375', shape: "Rect", family: "RHS-imperial", height: 7, weight: 26.113266},
        {name: 'RHS 7x5x0.5', shape: "Rect", family: "RHS-imperial", height: 7, weight: 32.949802},
        {name: 'SHS 8x0.1875', shape: "Rect", family: "SHS-imperial", height: 8, weight: 19.852479},
        {name: 'SHS 8x0.25', shape: "Rect", family: "SHS-imperial", height: 8, weight: 25.710461},
        {name: 'SHS 8x0.3125', shape: "Rect", family: "SHS-imperial", height: 8, weight: 31.793077},
        {name: 'SHS 8x0.375', shape: "Rect", family: "SHS-imperial", height: 8, weight: 37.720621},
        {name: 'SHS 8x0.5', shape: "Rect", family: "SHS-imperial", height: 8, weight: 49.012467},
        {name: 'SHS 8x0.625', shape: "Rect", family: "SHS-imperial", height: 8, weight: 59.591723},
        {name: 'RHS 8x2x0.1875', shape: "Rect", family: "RHS-imperial", height: 8, weight: 11.255634},
        {name: 'RHS 8x2x0.25', shape: "Rect", family: "RHS-imperial", height: 8, weight: 14.477018},
        {name: 'RHS 8x2x0.3125', shape: "Rect", family: "RHS-imperial", height: 8, weight: 17.771551},
        {name: 'RHS 8x2x0.375', shape: "Rect", family: "RHS-imperial", height: 8, weight: 21.263194},
        {name: 'RHS 8x3x0.1875', shape: "Rect", family: "RHS-imperial", height: 8, weight: 12.455651},
        {name: 'RHS 8x3x0.25', shape: "Rect", family: "RHS-imperial", height: 8, weight: 16.052041},
        {name: 'RHS 8x3x0.3125', shape: "Rect", family: "RHS-imperial", height: 8, weight: 19.74658},
        {name: 'RHS 8x3x0.375', shape: "Rect", family: "RHS-imperial", height: 8, weight: 23.68823},
        {name: 'RHS 8x4x0.1875', shape: "Rect", family: "RHS-imperial", height: 8, weight: 13.655669},
        {name: 'RHS 8x4x0.25', shape: "Rect", family: "RHS-imperial", height: 8, weight: 17.627065},
        {name: 'RHS 8x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 8, weight: 21.72161},
        {name: 'RHS 8x4x0.375', shape: "Rect", family: "RHS-imperial", height: 8, weight: 26.113266},
        {name: 'RHS 8x4x0.5', shape: "Rect", family: "RHS-imperial", height: 8, weight: 32.949802},
        {name: 'RHS 8x6x0.1875', shape: "Rect", family: "RHS-imperial", height: 8, weight: 16.055705},
        {name: 'RHS 8x6x0.25', shape: "Rect", family: "RHS-imperial", height: 8, weight: 20.777112},
        {name: 'RHS 8x6x0.3125', shape: "Rect", family: "RHS-imperial", height: 8, weight: 25.671668},
        {name: 'RHS 8x6x0.375', shape: "Rect", family: "RHS-imperial", height: 8, weight: 30.963338},
        {name: 'RHS 8x6x0.5', shape: "Rect", family: "RHS-imperial", height: 8, weight: 39.299896},
        {name: 'SHS 10x0.1875', shape: "Rect", family: "SHS-imperial", height: 10, weight: 25.013846},
        {name: 'SHS 10x0.3125', shape: "Rect", family: "SHS-imperial", height: 10, weight: 40.287827},
        {name: 'SHS 10x0.375', shape: "Rect", family: "SHS-imperial", height: 10, weight: 47.935827},
        {name: 'SHS 10x0.5', shape: "Rect", family: "SHS-imperial", height: 10, weight: 62.668584},
        {name: 'SHS 10x0.625', shape: "Rect", family: "SHS-imperial", height: 10, weight: 76.688751},
        {name: 'RHS 10x2x0.1875', shape: "Rect", family: "RHS-imperial", height: 10, weight: 13.655669},
        {name: 'RHS 10x2x0.25', shape: "Rect", family: "RHS-imperial", height: 10, weight: 17.627065},
        {name: 'RHS 10x2x0.3125', shape: "Rect", family: "RHS-imperial", height: 10, weight: 21.72161},
        {name: 'RHS 10x2x0.375', shape: "Rect", family: "RHS-imperial", height: 10, weight: 26.113266},
        {name: 'RHS 10x4x0.1875', shape: "Rect", family: "RHS-imperial", height: 10, weight: 16.055705},
        {name: 'RHS 10x4x0.25', shape: "Rect", family: "RHS-imperial", height: 10, weight: 20.777112},
        {name: 'RHS 10x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 10, weight: 25.671668},
        {name: 'RHS 10x4x0.375', shape: "Rect", family: "RHS-imperial", height: 10, weight: 30.963338},
        {name: 'RHS 10x4x0.5', shape: "Rect", family: "RHS-imperial", height: 10, weight: 39.299896},
        {name: 'RHS 10x6x0.1875', shape: "Rect", family: "RHS-imperial", height: 10, weight: 18.455741},
        {name: 'RHS 10x6x0.25', shape: "Rect", family: "RHS-imperial", height: 10, weight: 23.927158},
        {name: 'RHS 10x6x0.3125', shape: "Rect", family: "RHS-imperial", height: 10, weight: 29.621727},
        {name: 'RHS 10x6x0.375', shape: "Rect", family: "RHS-imperial", height: 10, weight: 35.81341},
        {name: 'RHS 10x6x0.5', shape: "Rect", family: "RHS-imperial", height: 10, weight: 45.64999},
        {name: 'SHS 10.25x0.25', shape: "Rect", family: "SHS-imperial", height: 10.25, weight: 33.338209},
        {name: 'SHS 12x0.25', shape: "Rect", family: "SHS-imperial", height: 12, weight: 39.259049},
        {name: 'SHS 12x0.3125', shape: "Rect", family: "SHS-imperial", height: 12, weight: 48.782576},
        {name: 'SHS 12x0.375', shape: "Rect", family: "SHS-imperial", height: 12, weight: 58.151032},
        {name: 'SHS 12x0.5', shape: "Rect", family: "SHS-imperial", height: 12, weight: 76.324701},
        {name: 'SHS 12x0.625', shape: "Rect", family: "SHS-imperial", height: 12, weight: 93.785779},
        {name: 'RHS 12x2x0.1875', shape: "Rect", family: "RHS-imperial", height: 12, weight: 16.055705},
        {name: 'RHS 12x2x0.25', shape: "Rect", family: "RHS-imperial", height: 12, weight: 20.777112},
        {name: 'RHS 12x4x0.1875', shape: "Rect", family: "RHS-imperial", height: 12, weight: 18.455741},
        {name: 'RHS 12x4x0.25', shape: "Rect", family: "RHS-imperial", height: 12, weight: 23.927158},
        {name: 'RHS 12x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 12, weight: 29.621727},
        {name: 'RHS 12x4x0.375', shape: "Rect", family: "RHS-imperial", height: 12, weight: 35.81341},
        {name: 'RHS 12x4x0.5', shape: "Rect", family: "RHS-imperial", height: 12, weight: 45.64999},
        {name: 'RHS 12x6x0.1875', shape: "Rect", family: "RHS-imperial", height: 12, weight: 20.855776},
        {name: 'RHS 12x6x0.25', shape: "Rect", family: "RHS-imperial", height: 12, weight: 27.077205},
        {name: 'RHS 12x6x0.3125', shape: "Rect", family: "RHS-imperial", height: 12, weight: 33.571786},
        {name: 'RHS 12x6x0.375', shape: "Rect", family: "RHS-imperial", height: 12, weight: 40.663482},
        {name: 'RHS 12x6x0.5', shape: "Rect", family: "RHS-imperial", height: 12, weight: 52.000085},
        {name: 'RHS 12x8x0.25', shape: "Rect", family: "RHS-imperial", height: 12, weight: 30.227252},
        {name: 'RHS 12x8x0.3125', shape: "Rect", family: "RHS-imperial", height: 12, weight: 37.521844},
        {name: 'RHS 12x8x0.375', shape: "Rect", family: "RHS-imperial", height: 12, weight: 45.513554},
        {name: 'RHS 12x8x0.5', shape: "Rect", family: "RHS-imperial", height: 12, weight: 58.350179},
        {name: 'RHS 12x8x0.625', shape: "Rect", family: "RHS-imperial", height: 12, weight: 71.825879},
        {name: 'SHS 14x0.3125', shape: "Rect", family: "SHS-imperial", height: 14, weight: 57.277326},
        {name: 'SHS 14x0.375', shape: "Rect", family: "SHS-imperial", height: 14, weight: 68.366238},
        {name: 'SHS 14x0.5', shape: "Rect", family: "SHS-imperial", height: 14, weight: 89.980818},
        {name: 'SHS 14x0.625', shape: "Rect", family: "SHS-imperial", height: 14, weight: 110.882808},
        {name: 'RHS 14x4x0.25', shape: "Rect", family: "RHS-imperial", height: 14, weight: 27.077205},
        {name: 'RHS 14x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 14, weight: 33.571786},
        {name: 'RHS 14x4x0.375', shape: "Rect", family: "RHS-imperial", height: 14, weight: 40.663482},
        {name: 'RHS 14x4x0.5', shape: "Rect", family: "RHS-imperial", height: 14, weight: 52.000085},
        {name: 'RHS 14x6x0.25', shape: "Rect", family: "RHS-imperial", height: 14, weight: 30.227252},
        {name: 'RHS 14x6x0.3125', shape: "Rect", family: "RHS-imperial", height: 14, weight: 37.521844},
        {name: 'RHS 14x6x0.375', shape: "Rect", family: "RHS-imperial", height: 14, weight: 45.513554},
        {name: 'RHS 14x6x0.5', shape: "Rect", family: "RHS-imperial", height: 14, weight: 58.350179},
        {name: 'RHS 14x10x0.3125', shape: "Rect", family: "RHS-imperial", height: 14, weight: 45.421962},
        {name: 'RHS 14x10x0.375', shape: "Rect", family: "RHS-imperial", height: 14, weight: 55.213698},
        {name: 'RHS 14x10x0.5', shape: "Rect", family: "RHS-imperial", height: 14, weight: 71.050368},
        {name: 'SHS 16x0.3125', shape: "Rect", family: "SHS-imperial", height: 16, weight: 65.772076},
        {name: 'SHS 16x0.375', shape: "Rect", family: "SHS-imperial", height: 16, weight: 78.581443},
        {name: 'SHS 16x0.5', shape: "Rect", family: "SHS-imperial", height: 16, weight: 103.636935},
        {name: 'SHS 16x0.625', shape: "Rect", family: "SHS-imperial", height: 16, weight: 127.979836},
        {name: 'RHS 16x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 16, weight: 37.521844},
        {name: 'RHS 16x4x0.375', shape: "Rect", family: "RHS-imperial", height: 16, weight: 45.513554},
        {name: 'RHS 16x4x0.5', shape: "Rect", family: "RHS-imperial", height: 16, weight: 58.350179},
        {name: 'RHS 16x8x0.3125', shape: "Rect", family: "RHS-imperial", height: 16, weight: 45.421962},
        {name: 'RHS 16x8x0.375', shape: "Rect", family: "RHS-imperial", height: 16, weight: 55.213698},
        {name: 'RHS 16x8x0.5', shape: "Rect", family: "RHS-imperial", height: 16, weight: 71.050368},
        {name: 'RHS 16x12x0.3125', shape: "Rect", family: "RHS-imperial", height: 16, weight: 53.322079},
        {name: 'RHS 16x12x0.375', shape: "Rect", family: "RHS-imperial", height: 16, weight: 64.913842},
        {name: 'RHS 16x12x0.5', shape: "Rect", family: "RHS-imperial", height: 16, weight: 83.750556},
        {name: 'SHS 18x0.375', shape: "Rect", family: "SHS-imperial", height: 18, weight: 88.796649},
        {name: 'SHS 18x0.5', shape: "Rect", family: "SHS-imperial", height: 18, weight: 117.293052},
        {name: 'SHS 18x0.625', shape: "Rect", family: "SHS-imperial", height: 18, weight: 145.076864},
        {name: 'RHS 18x6x0.3125', shape: "Rect", family: "RHS-imperial", height: 18, weight: 45.421962},
        {name: 'RHS 18x6x0.375', shape: "Rect", family: "RHS-imperial", height: 18, weight: 55.213698},
        {name: 'RHS 18x6x0.5', shape: "Rect", family: "RHS-imperial", height: 18, weight: 71.050368},
        {name: 'SHS 20x0.375', shape: "Rect", family: "SHS-imperial", height: 20, weight: 99.011854},
        {name: 'SHS 20x0.5', shape: "Rect", family: "SHS-imperial", height: 20, weight: 130.949169},
        {name: 'SHS 20x0.625', shape: "Rect", family: "SHS-imperial", height: 20, weight: 162.173892},
        {name: 'RHS 20x4x0.3125', shape: "Rect", family: "RHS-imperial", height: 20, weight: 45.421962},
        {name: 'RHS 20x4x0.375', shape: "Rect", family: "RHS-imperial", height: 20, weight: 55.213698},
        {name: 'RHS 20x4x0.5', shape: "Rect", family: "RHS-imperial", height: 20, weight: 71.050368},
        {name: 'RHS 20x8x0.3125', shape: "Rect", family: "RHS-imperial", height: 20, weight: 53.322079},
        {name: 'RHS 20x8x0.375', shape: "Rect", family: "RHS-imperial", height: 20, weight: 64.913842},
        {name: 'RHS 20x8x0.5', shape: "Rect", family: "RHS-imperial", height: 20, weight: 83.750556},
        {name: 'RHS 20x12x0.3125', shape: "Rect", family: "RHS-imperial", height: 20, weight: 61.222196},
        {name: 'RHS 20x12x0.375', shape: "Rect", family: "RHS-imperial", height: 20, weight: 74.613986},
        {name: 'RHS 20x12x0.5', shape: "Rect", family: "RHS-imperial", height: 20, weight: 96.450745},
        {name: 'SHS 22x0.375', shape: "Rect", family: "SHS-imperial", height: 22, weight: 109.22706},
        {name: 'SHS 22x0.5', shape: "Rect", family: "SHS-imperial", height: 22, weight: 144.605285},
        {name: 'SHS 22x0.625', shape: "Rect", family: "SHS-imperial", height: 22, weight: 179.27092},
        {name: 'SHS 24x0.375', shape: "Rect", family: "SHS-imperial", height: 24, weight: 119.442266},
        {name: 'SHS 24x0.5', shape: "Rect", family: "SHS-imperial", height: 24, weight: 158.261402},
        {name: 'SHS 24x0.625', shape: "Rect", family: "SHS-imperial", height: 24, weight: 196.367949},
        {name: 'SHS 26x0.625', shape: "Rect", family: "SHS-imperial", height: 26, weight: 213.464977},
        {name: 'SHS 28x0.625', shape: "Rect", family: "SHS-imperial", height: 28, weight: 230.562005},
        {name: 'SHS 30x0.625', shape: "Rect", family: "SHS-imperial", height: 30, weight: 247.659033},        
  ],
    Circle: [
        {name: 'HSS1.66X0.14', shape: "Circle", family: "HSS-imperial", height: 1.66, weight: 2.125808},
        {name: 'HSS1.9X0.11', shape: "Circle", family: "HSS-imperial", height: 1.9, weight: 2.125808},
        {name: 'HSS1.9X0.14', shape: "Circle", family: "HSS-imperial", height: 1.9, weight: 2.547804},
        {name: 'HSS1.9X0.18', shape: "Circle", family: "HSS-imperial", height: 1.9, weight: 3.207174},
        {name: 'HSS2.37X0.12', shape: "Circle", family: "HSS-imperial", height: 2.37, weight: 2.801002},
        {name: 'HSS2.37X0.15', shape: "Circle", family: "HSS-imperial", height: 2.37, weight: 3.402347},
        {name: 'HSS2.37X0.18', shape: "Circle", family: "HSS-imperial", height: 2.37, weight: 4.082816},
        {name: 'HSS2.37X0.21', shape: "Circle", family: "HSS-imperial", height: 2.37, weight: 4.731636},
        {name: 'HSS2.37X0.25', shape: "Circle", family: "HSS-imperial", height: 2.37, weight: 5.327706},
        {name: 'HSS2.5X0.12', shape: "Circle", family: "HSS-imperial", height: 2.5, weight: 2.959251},
        {name: 'HSS2.5X0.18', shape: "Circle", family: "HSS-imperial", height: 2.5, weight: 4.320189},
        {name: 'HSS2.5X0.25', shape: "Circle", family: "HSS-imperial", height: 2.5, weight: 5.644204},
        {name: 'HSS2.87X0.12', shape: "Circle", family: "HSS-imperial", height: 2.87, weight: 3.439272},
        {name: 'HSS2.87X0.18', shape: "Circle", family: "HSS-imperial", height: 2.87, weight: 5.037584},
        {name: 'HSS2.87X0.2', shape: "Circle", family: "HSS-imperial", height: 2.87, weight: 5.433205},
        {name: 'HSS2.87X0.25', shape: "Circle", family: "HSS-imperial", height: 2.87, weight: 6.593696},
        {name: 'HSS3X0.12', shape: "Circle", family: "HSS-imperial", height: 3, weight: 3.571146},
        {name: 'HSS3X0.13', shape: "Circle", family: "HSS-imperial", height: 3, weight: 3.813794},
        {name: 'HSS3X0.15', shape: "Circle", family: "HSS-imperial", height: 3, weight: 4.320189},
        {name: 'HSS3X0.18', shape: "Circle", family: "HSS-imperial", height: 3, weight: 5.243307},
        {name: 'HSS3X0.2', shape: "Circle", family: "HSS-imperial", height: 3, weight: 5.696953},
        {name: 'HSS3X0.21', shape: "Circle", family: "HSS-imperial", height: 3, weight: 6.013451},
        {name: 'HSS3X0.25', shape: "Circle", family: "HSS-imperial", height: 3, weight: 6.910193},
        {name: 'HSS3.5X0.12', shape: "Circle", family: "HSS-imperial", height: 3.5, weight: 4.188316},
        {name: 'HSS3.5X0.18', shape: "Circle", family: "HSS-imperial", height: 3.5, weight: 6.171699},
        {name: 'HSS3.5X0.2', shape: "Circle", family: "HSS-imperial", height: 3.5, weight: 6.699195},
        {name: 'HSS3.5X0.21', shape: "Circle", family: "HSS-imperial", height: 3.5, weight: 7.068442},
        {name: 'HSS3.5X0.25', shape: "Circle", family: "HSS-imperial", height: 3.5, weight: 8.123433},
        {name: 'HSS3.5X0.29', shape: "Circle", family: "HSS-imperial", height: 3.5, weight: 9.600421},
        {name: 'HSS3.5X0.31', shape: "Circle", family: "HSS-imperial", height: 3.5, weight: 9.969668},
        {name: 'HSS4X0.12', shape: "Circle", family: "HSS-imperial", height: 4, weight: 4.83186},
        {name: 'HSS4X0.18', shape: "Circle", family: "HSS-imperial", height: 4, weight: 7.121191},
        {name: 'HSS4X0.22', shape: "Circle", family: "HSS-imperial", height: 4, weight: 8.281682},
        {name: 'HSS4X0.22', shape: "Circle", family: "HSS-imperial", height: 4, weight: 8.49268},
        {name: 'HSS4X0.23', shape: "Circle", family: "HSS-imperial", height: 4, weight: 8.861927},
        {name: 'HSS4X0.25', shape: "Circle", family: "HSS-imperial", height: 4, weight: 9.389423},
        {name: 'HSS4X0.31', shape: "Circle", family: "HSS-imperial", height: 4, weight: 11.552155},
        {name: 'HSS4.5X0.12', shape: "Circle", family: "HSS-imperial", height: 4.5, weight: 5.433205},
        {name: 'HSS4.5X0.18', shape: "Circle", family: "HSS-imperial", height: 4.5, weight: 8.017934},
        {name: 'HSS4.5X0.23', shape: "Circle", family: "HSS-imperial", height: 4.5, weight: 10.075167},
        {name: 'HSS4.5X0.33', shape: "Circle", family: "HSS-imperial", height: 4.5, weight: 14.031385},
        {name: 'HSS4.5X0.37', shape: "Circle", family: "HSS-imperial", height: 4.5, weight: 15.508373},
        {name: 'HSS5X0.12', shape: "Circle", family: "HSS-imperial", height: 5, weight: 6.0662},
        {name: 'HSS5X0.18', shape: "Circle", family: "HSS-imperial", height: 5, weight: 8.967426},
        {name: 'HSS5X0.25', shape: "Circle", family: "HSS-imperial", height: 5, weight: 11.868652},
        {name: 'HSS5X0.25', shape: "Circle", family: "HSS-imperial", height: 5, weight: 12.237899},
        {name: 'HSS5X0.31', shape: "Circle", family: "HSS-imperial", height: 5, weight: 14.61163},
        {name: 'HSS5X0.37', shape: "Circle", family: "HSS-imperial", height: 5, weight: 17.354607},
        {name: 'HSS5X0.5', shape: "Circle", family: "HSS-imperial", height: 5, weight: 22.524065},
        {name: 'HSS5.5X0.25', shape: "Circle", family: "HSS-imperial", height: 5.5, weight: 13.503889},
        {name: 'HSS5.5X0.37', shape: "Circle", family: "HSS-imperial", height: 5.5, weight: 19.253592},
        {name: 'HSS5.5X0.5', shape: "Circle", family: "HSS-imperial", height: 5.5, weight: 25.056044},
        {name: 'HSS5.56X0.13', shape: "Circle", family: "HSS-imperial", height: 5.56, weight: 7.226691},
        {name: 'HSS5.56X0.18', shape: "Circle", family: "HSS-imperial", height: 5.56, weight: 10.022418},
        {name: 'HSS5.56X0.25', shape: "Circle", family: "HSS-imperial", height: 5.56, weight: 13.662138},
        {name: 'HSS5.56X0.37', shape: "Circle", family: "HSS-imperial", height: 5.56, weight: 19.46459},
        {name: 'HSS5.56X0.5', shape: "Circle", family: "HSS-imperial", height: 5.56, weight: 25.372541},
        {name: 'HSS6X0.12', shape: "Circle", family: "HSS-imperial", height: 6, weight: 7.27944},
        {name: 'HSS6X0.18', shape: "Circle", family: "HSS-imperial", height: 6, weight: 10.813661},
        {name: 'HSS6X0.25', shape: "Circle", family: "HSS-imperial", height: 6, weight: 14.347882},
        {name: 'HSS6X0.27', shape: "Circle", family: "HSS-imperial", height: 6, weight: 15.983119},
        {name: 'HSS6X0.31', shape: "Circle", family: "HSS-imperial", height: 6, weight: 17.776604},
        {name: 'HSS6X0.37', shape: "Circle", family: "HSS-imperial", height: 6, weight: 21.099827},
        {name: 'HSS6X0.5', shape: "Circle", family: "HSS-imperial", height: 6, weight: 27.535274},
        {name: 'HSS6.63X0.12', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 8.070684},
        {name: 'HSS6.63X0.18', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 12.026901},
        {name: 'HSS6.63X0.25', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 15.930369},
        {name: 'HSS6.63X0.27', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 17.671105},
        {name: 'HSS6.63X0.31', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 19.728338},
        {name: 'HSS6.63X0.37', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 23.420808},
        {name: 'HSS6.63X0.43', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 26.74403},
        {name: 'HSS6.63X0.5', shape: "Circle", family: "HSS-imperial", height: 6.63, weight: 30.647498},
        {name: 'HSS6.87X0.18', shape: "Circle", family: "HSS-imperial", height: 6.87, weight: 12.448898},
        {name: 'HSS6.87X0.25', shape: "Circle", family: "HSS-imperial", height: 6.87, weight: 16.563364},
        {name: 'HSS6.87X0.31', shape: "Circle", family: "HSS-imperial", height: 6.87, weight: 20.466832},
        {name: 'HSS6.87X0.37', shape: "Circle", family: "HSS-imperial", height: 6.87, weight: 24.3703},
        {name: 'HSS6.87X0.5', shape: "Circle", family: "HSS-imperial", height: 6.87, weight: 31.860738},
        {name: 'HSS7X0.12', shape: "Circle", family: "HSS-imperial", height: 7, weight: 8.54543},
        {name: 'HSS7X0.18', shape: "Circle", family: "HSS-imperial", height: 7, weight: 12.712646},
        {name: 'HSS7X0.25', shape: "Circle", family: "HSS-imperial", height: 7, weight: 16.827112},
        {name: 'HSS7X0.31', shape: "Circle", family: "HSS-imperial", height: 7, weight: 20.836079},
        {name: 'HSS7X0.37', shape: "Circle", family: "HSS-imperial", height: 7, weight: 24.792296},
        {name: 'HSS7X0.5', shape: "Circle", family: "HSS-imperial", height: 7, weight: 32.493733},
        {name: 'HSS7.5X0.18', shape: "Circle", family: "HSS-imperial", height: 7.5, weight: 13.609388},
        {name: 'HSS7.5X0.25', shape: "Circle", family: "HSS-imperial", height: 7.5, weight: 18.093101},
        {name: 'HSS7.5X0.31', shape: "Circle", family: "HSS-imperial", height: 7.5, weight: 22.418566},
        {name: 'HSS7.5X0.37', shape: "Circle", family: "HSS-imperial", height: 7.5, weight: 26.691281},
        {name: 'HSS7.5X0.5', shape: "Circle", family: "HSS-imperial", height: 7.5, weight: 35.078462},
        {name: 'HSS7.63X0.32', shape: "Circle", family: "HSS-imperial", height: 7.63, weight: 23.842804},
        {name: 'HSS7.63X0.37', shape: "Circle", family: "HSS-imperial", height: 7.63, weight: 27.166027},
        {name: 'HSS8.63X0.18', shape: "Circle", family: "HSS-imperial", height: 8.63, weight: 15.719371},
        {name: 'HSS8.63X0.25', shape: "Circle", family: "HSS-imperial", height: 8.63, weight: 20.888828},
        {name: 'HSS8.63X0.32', shape: "Circle", family: "HSS-imperial", height: 8.63, weight: 26.691281},
        {name: 'HSS8.63X0.37', shape: "Circle", family: "HSS-imperial", height: 8.63, weight: 30.858496},
        {name: 'HSS8.63X0.5', shape: "Circle", family: "HSS-imperial", height: 8.63, weight: 40.511667},
        {name: 'HSS8.63X0.62', shape: "Circle", family: "HSS-imperial", height: 8.63, weight: 50.006589},
        {name: 'HSS9.63X0.18', shape: "Circle", family: "HSS-imperial", height: 9.63, weight: 17.618355},
        {name: 'HSS9.63X0.25', shape: "Circle", family: "HSS-imperial", height: 9.63, weight: 23.368058},
        {name: 'HSS9.63X0.31', shape: "Circle", family: "HSS-imperial", height: 9.63, weight: 29.012262},
        {name: 'HSS9.63X0.37', shape: "Circle", family: "HSS-imperial", height: 9.63, weight: 34.709215},
        {name: 'HSS9.63X0.5', shape: "Circle", family: "HSS-imperial", height: 9.63, weight: 45.628375},
        {name: 'HSS10X0.18', shape: "Circle", family: "HSS-imperial", height: 10, weight: 18.25135},
        {name: 'HSS10X0.25', shape: "Circle", family: "HSS-imperial", height: 10, weight: 24.31755},
        {name: 'HSS10X0.31', shape: "Circle", family: "HSS-imperial", height: 10, weight: 30.225502},
        {name: 'HSS10X0.37', shape: "Circle", family: "HSS-imperial", height: 10, weight: 36.080703},
        {name: 'HSS10X0.5', shape: "Circle", family: "HSS-imperial", height: 10, weight: 47.316361},
        {name: 'HSS10X0.62', shape: "Circle", family: "HSS-imperial", height: 10, weight: 58.552019},
        {name: 'HSS10.75X0.25', shape: "Circle", family: "HSS-imperial", height: 10.75, weight: 26.216535},
        {name: 'HSS10.75X0.37', shape: "Circle", family: "HSS-imperial", height: 10.75, weight: 38.770931},
        {name: 'HSS10.75X0.5', shape: "Circle", family: "HSS-imperial", height: 10.75, weight: 51.06158},
        {name: 'HSS12.75X0.25', shape: "Circle", family: "HSS-imperial", height: 12.75, weight: 31.174994},
        {name: 'HSS12.75X0.37', shape: "Circle", family: "HSS-imperial", height: 12.75, weight: 46.26137},
        {name: 'HSS12.75X0.5', shape: "Circle", family: "HSS-imperial", height: 12.75, weight: 60.662001},
        {name: 'HSS14X0.25', shape: "Circle", family: "HSS-imperial", height: 14, weight: 34.392717},
        {name: 'HSS14X0.31', shape: "Circle", family: "HSS-imperial", height: 14, weight: 42.516151},
        {name: 'HSS14X0.37', shape: "Circle", family: "HSS-imperial", height: 14, weight: 51.06158},
        {name: 'HSS14X0.5', shape: "Circle", family: "HSS-imperial", height: 14, weight: 67.519445},
        {name: 'HSS14X0.62', shape: "Circle", family: "HSS-imperial", height: 14, weight: 83.344315},
        {name: 'HSS16X0.25', shape: "Circle", family: "HSS-imperial", height: 16, weight: 39.140178},
        {name: 'HSS16X0.31', shape: "Circle", family: "HSS-imperial", height: 16, weight: 49.004347},
        {name: 'HSS16X0.37', shape: "Circle", family: "HSS-imperial", height: 16, weight: 58.552019},
        {name: 'HSS16X0.43', shape: "Circle", family: "HSS-imperial", height: 16, weight: 67.519445},
        {name: 'HSS16X0.5', shape: "Circle", family: "HSS-imperial", height: 16, weight: 77.014367},
        {name: 'HSS16X0.62', shape: "Circle", family: "HSS-imperial", height: 16, weight: 95.476715},
        {name: 'HSS18X0.37', shape: "Circle", family: "HSS-imperial", height: 18, weight: 65.936958},
        {name: 'HSS18X0.5', shape: "Circle", family: "HSS-imperial", height: 18, weight: 87.036785},
        {name: 'HSS20X0.37', shape: "Circle", family: "HSS-imperial", height: 20, weight: 73.321897},
        {name: 'HSS20X0.5', shape: "Circle", family: "HSS-imperial", height: 20, weight: 97.059202},        
  ]
}

export const shapes_metric = {
    I: [
        {name: 'W100X19.3', shape: "I", family: "W-metric", height: 100, weight: 19.3895},
        {name: 'W130X23.8', shape: "I", family: "W-metric", height: 130, weight: 23.864},
        {name: 'W130X28.1', shape: "I", family: "W-metric", height: 130, weight: 28.1815},
        {name: 'W150X13', shape: "I", family: "W-metric", height: 150, weight: 12.44042},
        {name: 'W150X13.5', shape: "I", family: "W-metric", height: 150, weight: 13.5805},
        {name: 'W150X22.5', shape: "I", family: "W-metric", height: 150, weight: 22.451},
        {name: 'W150X18', shape: "I", family: "W-metric", height: 150, weight: 17.83364},
        {name: 'W150X29.8', shape: "I", family: "W-metric", height: 150, weight: 29.7515},
        {name: 'W150X24', shape: "I", family: "W-metric", height: 150, weight: 23.828054},
        {name: 'W150X37.1', shape: "I", family: "W-metric", height: 150, weight: 37.209},
        {name: 'W200X15', shape: "I", family: "W-metric", height: 200, weight: 14.64299},
        {name: 'W200X35.9', shape: "I", family: "W-metric", height: 200, weight: 35.8745},
        {name: 'W200X19.3', shape: "I", family: "W-metric", height: 200, weight: 19.468},
        {name: 'W200X46.1', shape: "I", family: "W-metric", height: 200, weight: 46.2365},
        {name: 'W200X41.7', shape: "I", family: "W-metric", height: 200, weight: 41.762},
        {name: 'W200X22.5', shape: "I", family: "W-metric", height: 200, weight: 22.451},
        {name: 'W200X52', shape: "I", family: "W-metric", height: 200, weight: 51.720691},
        {name: 'W200X26.6', shape: "I", family: "W-metric", height: 200, weight: 26.6115},
        {name: 'W200X31.3', shape: "I", family: "W-metric", height: 200, weight: 31.1645},
        {name: 'W200X59', shape: "I", family: "W-metric", height: 200, weight: 58.893623},
        {name: 'W200X71', shape: "I", family: "W-metric", height: 200, weight: 70.9748},
        {name: 'W200X86', shape: "I", family: "W-metric", height: 200, weight: 86.469339},
        {name: 'W200X100', shape: "I", family: "W-metric", height: 200, weight: 99.272051},
        {name: 'W250X49.1', shape: "I", family: "W-metric", height: 250, weight: 49.141},
        {name: 'W250X17.9', shape: "I", family: "W-metric", height: 250, weight: 17.898},
        {name: 'W250X58', shape: "I", family: "W-metric", height: 250, weight: 57.275526},
        {name: 'W250X22.3', shape: "I", family: "W-metric", height: 250, weight: 22.3725},
        {name: 'W250X73', shape: "I", family: "W-metric", height: 250, weight: 71.948198},
        {name: 'W250X25.3', shape: "I", family: "W-metric", height: 250, weight: 25.277},
        {name: 'W250X67', shape: "I", family: "W-metric", height: 250, weight: 66.16929},
        {name: 'W250X80', shape: "I", family: "W-metric", height: 250, weight: 79.228036},
        {name: 'W250X28.4', shape: "I", family: "W-metric", height: 250, weight: 28.4955},
        {name: 'W250X32.7', shape: "I", family: "W-metric", height: 250, weight: 32.8915},
        {name: 'W250X89', shape: "I", family: "W-metric", height: 250, weight: 88.737744},
        {name: 'W250X38.5', shape: "I", family: "W-metric", height: 250, weight: 38.5435},
        {name: 'W250X101', shape: "I", family: "W-metric", height: 250, weight: 100.390805},
        {name: 'W250X44.8', shape: "I", family: "W-metric", height: 250, weight: 44.745},
        {name: 'W250X115', shape: "I", family: "W-metric", height: 250, weight: 114.086251},
        {name: 'W250X131', shape: "I", family: "W-metric", height: 250, weight: 130.55664},
        {name: 'W250X149', shape: "I", family: "W-metric", height: 250, weight: 148.50774},
        {name: 'W250X167', shape: "I", family: "W-metric", height: 250, weight: 167.056708},
        {name: 'W310X21', shape: "I", family: "W-metric", height: 310, weight: 20.835135},
        {name: 'W310X60', shape: "I", family: "W-metric", height: 310, weight: 58.133122},
        {name: 'W310X23.8', shape: "I", family: "W-metric", height: 310, weight: 23.864},
        {name: 'W310X67', shape: "I", family: "W-metric", height: 310, weight: 65.358122},
        {name: 'W310X79', shape: "I", family: "W-metric", height: 310, weight: 77.505958},
        {name: 'W310X97', shape: "I", family: "W-metric", height: 310, weight: 95.546412},
        {name: 'W310X28.3', shape: "I", family: "W-metric", height: 310, weight: 28.1815},
        {name: 'W310X38.7', shape: "I", family: "W-metric", height: 310, weight: 38.779},
        {name: 'W310X74', shape: "I", family: "W-metric", height: 310, weight: 73.084197},
        {name: 'W310X86', shape: "I", family: "W-metric", height: 310, weight: 84.933111},
        {name: 'W310X32.7', shape: "I", family: "W-metric", height: 310, weight: 32.813},
        {name: 'W310X44.5', shape: "I", family: "W-metric", height: 310, weight: 44.5095},
        {name: 'W310X107', shape: "I", family: "W-metric", height: 310, weight: 105.686649},
        {name: 'W310X117', shape: "I", family: "W-metric", height: 310, weight: 117.75},
        {name: 'W310X52', shape: "I", family: "W-metric", height: 310, weight: 52.101623},
        {name: 'W310X129', shape: "I", family: "W-metric", height: 310, weight: 128.519594},
        {name: 'W310X143', shape: "I", family: "W-metric", height: 310, weight: 142.00406},
        {name: 'W310X158', shape: "I", family: "W-metric", height: 310, weight: 156.39046},
        {name: 'W310X179', shape: "I", family: "W-metric", height: 310, weight: 178.041642},
        {name: 'W310X202', shape: "I", family: "W-metric", height: 310, weight: 202.05532},
        {name: 'W310X226', shape: "I", family: "W-metric", height: 310, weight: 226.354412},
        {name: 'W310X253', shape: "I", family: "W-metric", height: 310, weight: 252.717023},
        {name: 'W310X283', shape: "I", family: "W-metric", height: 310, weight: 283.008224},
        {name: 'W310X342', shape: "I", family: "W-metric", height: 310, weight: 344.205302},
        {name: 'W310X375', shape: "I", family: "W-metric", height: 310, weight: 376.150168},
        {name: 'W360X32.9', shape: "I", family: "W-metric", height: 360, weight: 32.8915},
        {name: 'W360X64', shape: "I", family: "W-metric", height: 360, weight: 62.456187},
        {name: 'W360X44', shape: "I", family: "W-metric", height: 360, weight: 44.8235},
        {name: 'W360X72', shape: "I", family: "W-metric", height: 360, weight: 70.061142},
        {name: 'W360X39', shape: "I", family: "W-metric", height: 360, weight: 38.518027},
        {name: 'W360X79', shape: "I", family: "W-metric", height: 360, weight: 77.833062},
        {name: 'W360X91', shape: "I", family: "W-metric", height: 360, weight: 89.433888},
        {name: 'W360X51', shape: "I", family: "W-metric", height: 360, weight: 50.018497},
        {name: 'W360X101', shape: "I", family: "W-metric", height: 360, weight: 99.857924},
        {name: 'W360X134', shape: "I", family: "W-metric", height: 360, weight: 132.713259},
        {name: 'W360X57.8', shape: "I", family: "W-metric", height: 360, weight: 56.7555},
        {name: 'W360X110', shape: "I", family: "W-metric", height: 360, weight: 108.852073},
        {name: 'W360X147', shape: "I", family: "W-metric", height: 360, weight: 146.313449},
        {name: 'W360X122', shape: "I", family: "W-metric", height: 360, weight: 120.510715},
        {name: 'W360X162', shape: "I", family: "W-metric", height: 360, weight: 160.821194},
        {name: 'W360X179', shape: "I", family: "W-metric", height: 360, weight: 178.226263},
        {name: 'W360X196', shape: "I", family: "W-metric", height: 360, weight: 195.642457},
        {name: 'W310X313', shape: "I", family: "W-metric", height: 310, weight: 313.929311},
        {name: 'W360X216', shape: "I", family: "W-metric", height: 360, weight: 215.464758},
        {name: 'W360X237', shape: "I", family: "W-metric", height: 360, weight: 235.573368},
        {name: 'W360X262', shape: "I", family: "W-metric", height: 360, weight: 262.29102},
        {name: 'W360X287', shape: "I", family: "W-metric", height: 360, weight: 287.194198},
        {name: 'W410X38.8', shape: "I", family: "W-metric", height: 410, weight: 38.8575},
        {name: 'W360X314', shape: "I", family: "W-metric", height: 360, weight: 313.355602},
        {name: 'W410X46.1', shape: "I", family: "W-metric", height: 410, weight: 46.2365},
        {name: 'W410X53', shape: "I", family: "W-metric", height: 410, weight: 53.694},
        {name: 'W310X415', shape: "I", family: "W-metric", height: 310, weight: 417.108457},
        {name: 'W410X60', shape: "I", family: "W-metric", height: 410, weight: 58.94812},
        {name: 'W360X347', shape: "I", family: "W-metric", height: 360, weight: 347.174911},
        {name: 'W410X67', shape: "I", family: "W-metric", height: 410, weight: 66.974121},
        {name: 'W410X75', shape: "I", family: "W-metric", height: 410, weight: 74.418},
        {name: 'W410X100', shape: "I", family: "W-metric", height: 410, weight: 99.108381},
        {name: 'W310X454', shape: "I", family: "W-metric", height: 310, weight: 455.971561},
        {name: 'W410X85', shape: "I", family: "W-metric", height: 410, weight: 84.527565},
        {name: 'W360X382', shape: "I", family: "W-metric", height: 360, weight: 382.921881},
        {name: 'W410X114', shape: "I", family: "W-metric", height: 410, weight: 114.101607},
        {name: 'W360X421', shape: "I", family: "W-metric", height: 360, weight: 422.691078},
        {name: 'W410X132', shape: "I", family: "W-metric", height: 410, weight: 131.764286},
        {name: 'W310X500', shape: "I", family: "W-metric", height: 310, weight: 503.110847},
        {name: 'W410X149', shape: "I", family: "W-metric", height: 410, weight: 149.019289},
        {name: 'W360X463', shape: "I", family: "W-metric", height: 360, weight: 464.357861},
        {name: 'W360X509', shape: "I", family: "W-metric", height: 360, weight: 511.567594},
        {name: 'W460X52', shape: "I", family: "W-metric", height: 460, weight: 51.524185},
        {name: 'W460X60', shape: "I", family: "W-metric", height: 460, weight: 58.980223},
        {name: 'W360X551', shape: "I", family: "W-metric", height: 360, weight: 553.258201},
        {name: 'W460X74', shape: "I", family: "W-metric", height: 460, weight: 73.678424},
        {name: 'W460X68', shape: "I", family: "W-metric", height: 460, weight: 67.981076},
        {name: 'W460X82', shape: "I", family: "W-metric", height: 460, weight: 81.470396},
        {name: 'W460X89', shape: "I", family: "W-metric", height: 460, weight: 88.808973},
        {name: 'W460X113', shape: "I", family: "W-metric", height: 460, weight: 112.658151},
        {name: 'W360X592', shape: "I", family: "W-metric", height: 360, weight: 595.877671},
        {name: 'W460X97', shape: "I", family: "W-metric", height: 460, weight: 96.141206},
        {name: 'W460X128', shape: "I", family: "W-metric", height: 460, weight: 128.103239},
        {name: 'W460X106', shape: "I", family: "W-metric", height: 460, weight: 105.412095},
        {name: 'W460X144', shape: "I", family: "W-metric", height: 460, weight: 144.280541},
        {name: 'W460X158', shape: "I", family: "W-metric", height: 460, weight: 157.547793},
        {name: 'W360X634', shape: "I", family: "W-metric", height: 360, weight: 638.015428},
        {name: 'W460X177', shape: "I", family: "W-metric", height: 460, weight: 177.232018},
        {name: 'W360X677', shape: "I", family: "W-metric", height: 360, weight: 682.709194},
        {name: 'W460X193', shape: "I", family: "W-metric", height: 460, weight: 193.001161},
        {name: 'W460X213', shape: "I", family: "W-metric", height: 460, weight: 212.456427},
        {name: 'W360X744', shape: "I", family: "W-metric", height: 360, weight: 750.197064},
        {name: 'W460X235', shape: "I", family: "W-metric", height: 460, weight: 234.698371},
        {name: 'W460X260', shape: "I", family: "W-metric", height: 460, weight: 259.96606},
        {name: 'W360X818', shape: "I", family: "W-metric", height: 360, weight: 826.297255},
        {name: 'W460X286', shape: "I", family: "W-metric", height: 460, weight: 285.558539},
        {name: 'W530X72', shape: "I", family: "W-metric", height: 530, weight: 71.435},
        {name: 'W530X66', shape: "I", family: "W-metric", height: 530, weight: 64.932726},
        {name: 'W460X315', shape: "I", family: "W-metric", height: 460, weight: 314.380499},
        {name: 'W530X74', shape: "I", family: "W-metric", height: 530, weight: 73.976318},
        {name: 'W530X82', shape: "I", family: "W-metric", height: 530, weight: 82.425},
        {name: 'W360X900', shape: "I", family: "W-metric", height: 360, weight: 910.93546},
        {name: 'W530X92', shape: "I", family: "W-metric", height: 530, weight: 91.658509},
        {name: 'W530X85', shape: "I", family: "W-metric", height: 530, weight: 83.842182},
        {name: 'W530X101', shape: "I", family: "W-metric", height: 530, weight: 100.629107},
        {name: 'W460X349', shape: "I", family: "W-metric", height: 460, weight: 349.182024},
        {name: 'W530X109', shape: "I", family: "W-metric", height: 530, weight: 108.247562},
        {name: 'W530X123', shape: "I", family: "W-metric", height: 530, weight: 122.543372},
        {name: 'W530X150', shape: "I", family: "W-metric", height: 530, weight: 149.923746},
        {name: 'W530X165', shape: "I", family: "W-metric", height: 530, weight: 164.709355},
        {name: 'W460X384', shape: "I", family: "W-metric", height: 460, weight: 384.76367},
        {name: 'W530X138', shape: "I", family: "W-metric", height: 530, weight: 137.714345},
        {name: 'W360X990', shape: "I", family: "W-metric", height: 360, weight: 1002.033047},
        {name: 'W530X182', shape: "I", family: "W-metric", height: 530, weight: 181.146609},
        {name: 'W530X196', shape: "I", family: "W-metric", height: 530, weight: 196.101557},
        {name: 'W460X421', shape: "I", family: "W-metric", height: 460, weight: 422.413644},
        {name: 'W530X219', shape: "I", family: "W-metric", height: 530, weight: 218.661545},
        {name: 'W460X464', shape: "I", family: "W-metric", height: 460, weight: 464.78678},
        {name: 'W360X1086', shape: "I", family: "W-metric", height: 360, weight: 1100.973463},
        {name: 'W530X248', shape: "I", family: "W-metric", height: 530, weight: 245.908883},
        {name: 'W530X272', shape: "I", family: "W-metric", height: 530, weight: 271.455795},
        {name: 'W530X300', shape: "I", family: "W-metric", height: 530, weight: 299.118408},
        {name: 'W610X82', shape: "I", family: "W-metric", height: 610, weight: 81.180621},
        {name: 'W610X92', shape: "I", family: "W-metric", height: 610, weight: 91.604806},
        {name: 'W610X101', shape: "I", family: "W-metric", height: 610, weight: 100.955969},
        {name: 'W610X113', shape: "I", family: "W-metric", height: 610, weight: 112.687306},
        {name: 'W610X125', shape: "I", family: "W-metric", height: 610, weight: 124.283065},
        {name: 'W610X155', shape: "I", family: "W-metric", height: 610, weight: 154.253138},
        {name: 'W610X140', shape: "I", family: "W-metric", height: 610, weight: 139.373664},
        {name: 'W610X174', shape: "I", family: "W-metric", height: 610, weight: 173.699487},
        {name: 'W610X153', shape: "I", family: "W-metric", height: 610, weight: 152.802284},
        {name: 'W610X195', shape: "I", family: "W-metric", height: 610, weight: 195.156452},
        {name: 'W610X217', shape: "I", family: "W-metric", height: 610, weight: 217.342546},
        {name: 'W610X241', shape: "I", family: "W-metric", height: 610, weight: 241.179553},
        {name: 'W610X262', shape: "I", family: "W-metric", height: 610, weight: 260.500722},
        {name: 'W610X285', shape: "I", family: "W-metric", height: 610, weight: 284.813416},
        {name: 'W610X307', shape: "I", family: "W-metric", height: 610, weight: 306.759253},
        {name: 'W610X341', shape: "I", family: "W-metric", height: 610, weight: 340.054717},
        {name: 'W610X372', shape: "I", family: "W-metric", height: 610, weight: 372.019609},
        {name: 'W690X125', shape: "I", family: "W-metric", height: 690, weight: 124.502313},
        {name: 'W610X415', shape: "I", family: "W-metric", height: 610, weight: 415.558687},
        {name: 'W690X140', shape: "I", family: "W-metric", height: 690, weight: 138.711225},
        {name: 'W690X152', shape: "I", family: "W-metric", height: 690, weight: 150.952679},
        {name: 'W610X455', shape: "I", family: "W-metric", height: 610, weight: 454.291185},
        {name: 'W690X170', shape: "I", family: "W-metric", height: 690, weight: 168.839806},
        {name: 'W690X217', shape: "I", family: "W-metric", height: 690, weight: 216.807197},
        {name: 'W610X498', shape: "I", family: "W-metric", height: 610, weight: 498.758477},
        {name: 'W690X192', shape: "I", family: "W-metric", height: 690, weight: 190.202984},
        {name: 'W690X240', shape: "I", family: "W-metric", height: 690, weight: 239.002061},
        {name: 'W690X265', shape: "I", family: "W-metric", height: 690, weight: 263.737525},
        {name: 'W610X551', shape: "I", family: "W-metric", height: 610, weight: 552.033613},
        {name: 'W690X289', shape: "I", family: "W-metric", height: 690, weight: 286.868872},
        {name: 'W690X323', shape: "I", family: "W-metric", height: 690, weight: 322.277562},
        {name: 'W690X350', shape: "I", family: "W-metric", height: 690, weight: 349.109353},
        {name: 'W690X384', shape: "I", family: "W-metric", height: 690, weight: 382.789961},
        {name: 'W690X419', shape: "I", family: "W-metric", height: 690, weight: 417.269051},
        {name: 'W760X134', shape: "I", family: "W-metric", height: 760, weight: 131.954054},
        {name: 'W690X457', shape: "I", family: "W-metric", height: 690, weight: 456.640092},
        {name: 'W760X147', shape: "I", family: "W-metric", height: 760, weight: 145.91854},
        {name: 'W760X161', shape: "I", family: "W-metric", height: 760, weight: 159.195522},
        {name: 'W760X173', shape: "I", family: "W-metric", height: 760, weight: 172.409508},
        {name: 'W690X500', shape: "I", family: "W-metric", height: 690, weight: 498.990655},
        {name: 'W760X185', shape: "I", family: "W-metric", height: 760, weight: 183.561028},
        {name: 'W760X196', shape: "I", family: "W-metric", height: 760, weight: 195.499301},
        {name: 'W760X257', shape: "I", family: "W-metric", height: 760, weight: 256.381406},
        {name: 'W690X548', shape: "I", family: "W-metric", height: 690, weight: 547.556432},
        {name: 'W760X220', shape: "I", family: "W-metric", height: 760, weight: 218.732869},
        {name: 'W760X284', shape: "I", family: "W-metric", height: 760, weight: 282.735008},
        {name: 'W760X314', shape: "I", family: "W-metric", height: 760, weight: 315.57},
        {name: 'W760X350', shape: "I", family: "W-metric", height: 760, weight: 348.126827},
        {name: 'W760X389', shape: "I", family: "W-metric", height: 760, weight: 387.284938},
        {name: 'W760X434', shape: "I", family: "W-metric", height: 760, weight: 432.549701},
        {name: 'W760X484', shape: "I", family: "W-metric", height: 760, weight: 483.754972},
        {name: 'W690X802', shape: "I", family: "W-metric", height: 690, weight: 803.057696},
        {name: 'W760X531', shape: "I", family: "W-metric", height: 760, weight: 530.131799},
        {name: 'W840X176', shape: "I", family: "W-metric", height: 840, weight: 174.546312},
        {name: 'W840X193', shape: "I", family: "W-metric", height: 840, weight: 192.060658},
        {name: 'W760X582', shape: "I", family: "W-metric", height: 760, weight: 581.734376},
        {name: 'W840X210', shape: "I", family: "W-metric", height: 840, weight: 209.211092},
        {name: 'W840X226', shape: "I", family: "W-metric", height: 840, weight: 225.01956},
        {name: 'W840X299', shape: "I", family: "W-metric", height: 840, weight: 297.981923},
        {name: 'W840X251', shape: "I", family: "W-metric", height: 840, weight: 248.804552},
        {name: 'W840X329', shape: "I", family: "W-metric", height: 840, weight: 328.110366},
        {name: 'W840X359', shape: "I", family: "W-metric", height: 840, weight: 358.087749},
        {name: 'W840X392', shape: "I", family: "W-metric", height: 840, weight: 390.096423},
        {name: 'W840X433', shape: "I", family: "W-metric", height: 840, weight: 431.895007},
        {name: 'W840X473', shape: "I", family: "W-metric", height: 840, weight: 471.946969},
        {name: 'W920X201', shape: "I", family: "W-metric", height: 920, weight: 199.752044},
        {name: 'W840X527', shape: "I", family: "W-metric", height: 840, weight: 526.622057},
        {name: 'W920X223', shape: "I", family: "W-metric", height: 920, weight: 222.559549},
        {name: 'W920X238', shape: "I", family: "W-metric", height: 920, weight: 236.541332},
        {name: 'W840X576', shape: "I", family: "W-metric", height: 840, weight: 575.101115},
        {name: 'W920X253', shape: "I", family: "W-metric", height: 920, weight: 251.978876},
        {name: 'W920X271', shape: "I", family: "W-metric", height: 920, weight: 270.013943},
        {name: 'W920X289', shape: "I", family: "W-metric", height: 920, weight: 286.976516},
        {name: 'W920X344', shape: "I", family: "W-metric", height: 920, weight: 341.536983},
        {name: 'W920X313', shape: "I", family: "W-metric", height: 920, weight: 311.296137},
        {name: 'W920X368', shape: "I", family: "W-metric", height: 920, weight: 363.869905},
        {name: 'W920X390', shape: "I", family: "W-metric", height: 920, weight: 386.405408},
        {name: 'W920X420', shape: "I", family: "W-metric", height: 920, weight: 417.532126},
        {name: 'W920X345', shape: "I", family: "W-metric", height: 920, weight: 343.288443},
        {name: 'W920X449', shape: "I", family: "W-metric", height: 920, weight: 446.884222},
        {name: 'W920X381', shape: "I", family: "W-metric", height: 920, weight: 379.749027},
        {name: 'W920X491', shape: "I", family: "W-metric", height: 920, weight: 487.653561},
        {name: 'W920X537', shape: "I", family: "W-metric", height: 920, weight: 534.370677},
        {name: 'W1000X222', shape: "I", family: "W-metric", height: 1000, weight: 216.880598},
        {name: 'W920X588', shape: "I", family: "W-metric", height: 920, weight: 586.243794},
        {name: 'W1000X249', shape: "I", family: "W-metric", height: 1000, weight: 243.35495},
        {name: 'W1000X296', shape: "I", family: "W-metric", height: 1000, weight: 290.949499},
        {name: 'W920X656', shape: "I", family: "W-metric", height: 920, weight: 654.802176},
        {name: 'W1000X272', shape: "I", family: "W-metric", height: 1000, weight: 266.424832},
        {name: 'W1000X321', shape: "I", family: "W-metric", height: 1000, weight: 315.094832},
        {name: 'W920X725', shape: "I", family: "W-metric", height: 920, weight: 724.062285},
        {name: 'W1000X314', shape: "I", family: "W-metric", height: 1000, weight: 308.533665},
        {name: 'W1000X371', shape: "I", family: "W-metric", height: 1000, weight: 365.338695},
        {name: 'W1000X350', shape: "I", family: "W-metric", height: 1000, weight: 343.669928},
        {name: 'W1000X412', shape: "I", family: "W-metric", height: 1000, weight: 406.469928},
        {name: 'W1000X443', shape: "I", family: "W-metric", height: 1000, weight: 437.187388},
        {name: 'W920X787', shape: "I", family: "W-metric", height: 920, weight: 786.211041},
        {name: 'W1000X393', shape: "I", family: "W-metric", height: 1000, weight: 387.374517},
        {name: 'W1000X415', shape: "I", family: "W-metric", height: 1000, weight: 409.180706},
        {name: 'W1000X483', shape: "I", family: "W-metric", height: 1000, weight: 477.585464},
        {name: 'W1000X438', shape: "I", family: "W-metric", height: 1000, weight: 431.428881},
        {name: 'W1000X539', shape: "I", family: "W-metric", height: 1000, weight: 534.420917},
        {name: 'W1000X554', shape: "I", family: "W-metric", height: 1000, weight: 549.895998},
        {name: 'W1000X486', shape: "I", family: "W-metric", height: 1000, weight: 481.255143},
        {name: 'W1000X494', shape: "I", family: "W-metric", height: 1000, weight: 489.362331},
        {name: 'W1000X591', shape: "I", family: "W-metric", height: 1000, weight: 586.020854},
        {name: 'W920X970', shape: "I", family: "W-metric", height: 920, weight: 971.504566},
        {name: 'W1000X642', shape: "I", family: "W-metric", height: 1000, weight: 636.918796},
        {name: 'W1000X584', shape: "I", family: "W-metric", height: 1000, weight: 580.369936},
        {name: 'W1000X748', shape: "I", family: "W-metric", height: 1000, weight: 745.759206},
        {name: 'W1100X343', shape: "I", family: "W-metric", height: 1100, weight: 340.500749},
        {name: 'W1000X883', shape: "I", family: "W-metric", height: 1000, weight: 879.938961},
        {name: 'W1100X390', shape: "I", family: "W-metric", height: 1100, weight: 389.41857},
        {name: 'W1100X433', shape: "I", family: "W-metric", height: 1100, weight: 431.990774},
        {name: 'W1100X499', shape: "I", family: "W-metric", height: 1100, weight: 496.249207},
    ],
    Rect: [
        {name: 'HSS50.8X25.4X3.2', shape: "Rect", family: "HSS-metric", height: 50.8, weight: 3.0772},
        {name: 'HSS50.8X38.1X3.2', shape: "Rect", family: "HSS-metric", height: 50.8, weight: 3.66595},
        {name: 'HSS50.8X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 50.8, weight: 4.2547},
        {name: 'HSS50.8X25.4X4.8', shape: "Rect", family: "HSS-metric", height: 50.8, weight: 4.27825},
        {name: 'HSS50.8X38.1X4.8', shape: "Rect", family: "HSS-metric", height: 50.8, weight: 5.1653},
        {name: 'HSS50.8X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 50.8, weight: 6.0288},
        {name: 'HSS50.8X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 50.8, weight: 7.6459},
        {name: 'HSS57.2X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 57.2, weight: 4.54515},
        {name: 'HSS57.2X57.2X3.2', shape: "Rect", family: "HSS-metric", height: 57.2, weight: 4.84345},
        {name: 'HSS57.2X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 57.2, weight: 6.4841},
        {name: 'HSS57.2X57.2X4.8', shape: "Rect", family: "HSS-metric", height: 57.2, weight: 6.9394},
        {name: 'HSS57.2X57.2X6.4', shape: "Rect", family: "HSS-metric", height: 57.2, weight: 8.792},
        {name: 'HSS63.5X25.4X3.2', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 3.66595},
        {name: 'HSS63.5X38.1X3.2', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 4.2547},
        {name: 'HSS63.5X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 4.84345},
        {name: 'HSS63.5X25.4X4.8', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 5.1653},
        {name: 'HSS63.5X63.5X3.2', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 5.4165},
        {name: 'HSS63.5X38.1X4.8', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 6.0288},
        {name: 'HSS63.5X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 6.9394},
        {name: 'HSS63.5X38.1X6.4', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 7.6459},
        {name: 'HSS63.5X63.5X4.8', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 7.8029},
        {name: 'HSS63.5X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 8.792},
        {name: 'HSS63.5X63.5X6.4', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 9.9695},
        {name: 'HSS63.5X63.5X7.9', shape: "Rect", family: "HSS-metric", height: 63.5, weight: 11.932},
        {name: 'HSS76.2X25.4X3.2', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 4.2547},
        {name: 'HSS76.2X38.1X3.2', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 4.84345},
        {name: 'HSS76.2X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 5.4165},
        {name: 'HSS76.2X63.5X3.2', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 6.0288},
        {name: 'HSS76.2X25.4X4.8', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 6.0288},
        {name: 'HSS76.2X76.2X3.2', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 6.58615},
        {name: 'HSS76.2X38.1X4.8', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 6.9394},
        {name: 'HSS76.2X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 7.8029},
        {name: 'HSS76.2X63.5X4.8', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 8.635},
        {name: 'HSS76.2X38.1X6.4', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 8.792},
        {name: 'HSS76.2X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 9.577},
        {name: 'HSS76.2X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 9.9695},
        {name: 'HSS76.2X63.5X6.4', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 11.2255},
        {name: 'HSS76.2X50.8X7.9', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 11.932},
        {name: 'HSS76.2X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 12.3245},
        {name: 'HSS76.2X63.5X7.9', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 13.345},
        {name: 'HSS76.2X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 14.915},
        {name: 'HSS76.2X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 76.2, weight: 17.1915},
        {name: 'HSS88.9X38.1X3.2', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 5.4165},
        {name: 'HSS88.9X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 6.0288},
        {name: 'HSS88.9X63.5X3.2', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 6.58615},
        {name: 'HSS88.9X38.1X4.8', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 7.8029},
        {name: 'HSS88.9X88.9X3.2', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 7.8029},
        {name: 'HSS88.9X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 8.635},
        {name: 'HSS88.9X63.5X4.8', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 9.577},
        {name: 'HSS88.9X38.1X6.4', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 9.9695},
        {name: 'HSS88.9X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 11.2255},
        {name: 'HSS88.9X88.9X4.8', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 11.3825},
        {name: 'HSS88.9X63.5X6.4', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 12.3245},
        {name: 'HSS88.9X88.9X6.4', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 14.758},
        {name: 'HSS88.9X63.5X7.9', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 14.915},
        {name: 'HSS88.9X63.5X9.5', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 17.1915},
        {name: 'HSS88.9X88.9X7.9', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 17.8195},
        {name: 'HSS88.9X88.9X9.5', shape: "Rect", family: "HSS-metric", height: 88.9, weight: 20.724},
        {name: 'HSS101.6X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 6.58615},
        {name: 'HSS101.6X63.5X3.2', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 7.1906},
        {name: 'HSS101.6X76.2X3.2', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 7.8029},
        {name: 'HSS101.6X101.6X3.2', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 8.949},
        {name: 'HSS101.6X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 9.577},
        {name: 'HSS101.6X63.5X4.8', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 10.4405},
        {name: 'HSS101.6X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 11.3825},
        {name: 'HSS101.6X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 12.3245},
        {name: 'HSS101.6X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 13.031},
        {name: 'HSS101.6X63.5X6.4', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 13.502},
        {name: 'HSS101.6X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 14.758},
        {name: 'HSS101.6X50.8X7.9', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 14.915},
        {name: 'HSS101.6X63.5X7.9', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 16.328},
        {name: 'HSS101.6X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 17.0345},
        {name: 'HSS101.6X50.8X9.5', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 17.1915},
        {name: 'HSS101.6X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 17.8195},
        {name: 'HSS101.6X63.5X9.5', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 18.9185},
        {name: 'HSS101.6X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 20.724},
        {name: 'HSS101.6X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 20.8025},
        {name: 'HSS101.6X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 24.178},
        {name: 'HSS101.6X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 101.6, weight: 30.458},
        {name: 'HSS114.3X114.3X3.2', shape: "Rect", family: "HSS-metric", height: 114.3, weight: 10.1265},
        {name: 'HSS114.3X114.3X4.8', shape: "Rect", family: "HSS-metric", height: 114.3, weight: 14.8365},
        {name: 'HSS114.3X114.3X6.4', shape: "Rect", family: "HSS-metric", height: 114.3, weight: 19.468},
        {name: 'HSS114.3X114.3X7.9', shape: "Rect", family: "HSS-metric", height: 114.3, weight: 23.707},
        {name: 'HSS114.3X114.3X9.5', shape: "Rect", family: "HSS-metric", height: 114.3, weight: 27.789},
        {name: 'HSS114.3X114.3X12.7', shape: "Rect", family: "HSS-metric", height: 114.3, weight: 35.168},
        {name: 'HSS127X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 127, weight: 7.8029},
        {name: 'HSS127X63.5X3.2', shape: "Rect", family: "HSS-metric", height: 127, weight: 8.321},
        {name: 'HSS127X76.2X3.2', shape: "Rect", family: "HSS-metric", height: 127, weight: 8.949},
        {name: 'HSS127X101.6X3.2', shape: "Rect", family: "HSS-metric", height: 127, weight: 10.1265},
        {name: 'HSS127X127X3.2', shape: "Rect", family: "HSS-metric", height: 127, weight: 11.304},
        {name: 'HSS127X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 127, weight: 11.3825},
        {name: 'HSS127X63.5X4.8', shape: "Rect", family: "HSS-metric", height: 127, weight: 12.1675},
        {name: 'HSS127X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 127, weight: 13.031},
        {name: 'HSS127X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 127, weight: 14.758},
        {name: 'HSS127X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 127, weight: 14.8365},
        {name: 'HSS127X63.5X6.4', shape: "Rect", family: "HSS-metric", height: 127, weight: 15.9355},
        {name: 'HSS127X127X4.8', shape: "Rect", family: "HSS-metric", height: 127, weight: 16.642},
        {name: 'HSS127X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 127, weight: 17.0345},
        {name: 'HSS127X50.8X7.9', shape: "Rect", family: "HSS-metric", height: 127, weight: 17.8195},
        {name: 'HSS127X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 127, weight: 19.468},
        {name: 'HSS127X50.8X9.5', shape: "Rect", family: "HSS-metric", height: 127, weight: 20.724},
        {name: 'HSS127X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 127, weight: 20.8025},
        {name: 'HSS127X127X6.4', shape: "Rect", family: "HSS-metric", height: 127, weight: 21.7445},
        {name: 'HSS127X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 127, weight: 23.707},
        {name: 'HSS127X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 127, weight: 24.178},
        {name: 'HSS127X127X7.9', shape: "Rect", family: "HSS-metric", height: 127, weight: 26.6115},
        {name: 'HSS127X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 127, weight: 27.789},
        {name: 'HSS127X76.2X12.7', shape: "Rect", family: "HSS-metric", height: 127, weight: 30.458},
        {name: 'HSS127X127X9.5', shape: "Rect", family: "HSS-metric", height: 127, weight: 31.3215},
        {name: 'HSS127X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 127, weight: 35.168},
        {name: 'HSS127X127X12.7', shape: "Rect", family: "HSS-metric", height: 127, weight: 39.878},
        {name: 'HSS139.7X139.7X3.2', shape: "Rect", family: "HSS-metric", height: 139.7, weight: 12.4815},
        {name: 'HSS139.7X139.7X4.8', shape: "Rect", family: "HSS-metric", height: 139.7, weight: 18.369},
        {name: 'HSS139.7X139.7X6.4', shape: "Rect", family: "HSS-metric", height: 139.7, weight: 24.178},
        {name: 'HSS139.7X139.7X7.9', shape: "Rect", family: "HSS-metric", height: 139.7, weight: 29.5945},
        {name: 'HSS139.7X139.7X9.5', shape: "Rect", family: "HSS-metric", height: 139.7, weight: 34.854},
        {name: 'HSS152.4X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 8.949},
        {name: 'HSS152.4X76.2X3.2', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 10.1265},
        {name: 'HSS152.4X101.6X3.2', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 11.304},
        {name: 'HSS152.4X127X3.2', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 12.4815},
        {name: 'HSS152.4X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 13.031},
        {name: 'HSS152.4X152.4X3.2', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 13.659},
        {name: 'HSS152.4X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 14.8365},
        {name: 'HSS152.4X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 16.642},
        {name: 'HSS152.4X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 17.0345},
        {name: 'HSS152.4X127X4.8', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 18.369},
        {name: 'HSS152.4X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 19.468},
        {name: 'HSS152.4X152.4X4.8', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 20.1745},
        {name: 'HSS152.4X50.8X7.9', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 20.8025},
        {name: 'HSS152.4X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 21.7445},
        {name: 'HSS152.4X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 23.707},
        {name: 'HSS152.4X127X6.4', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 24.178},
        {name: 'HSS152.4X50.8X9.5', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 24.178},
        {name: 'HSS152.4X152.4X6.4', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 26.533},
        {name: 'HSS152.4X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 26.6115},
        {name: 'HSS152.4X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 27.789},
        {name: 'HSS152.4X127X7.9', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 29.5945},
        {name: 'HSS152.4X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 31.3215},
        {name: 'HSS152.4X152.4X7.9', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 32.5775},
        {name: 'HSS152.4X127X9.5', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 34.854},
        {name: 'HSS152.4X76.2X12.7', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 35.168},
        {name: 'HSS152.4X152.4X9.5', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 38.3865},
        {name: 'HSS152.4X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 39.878},
        {name: 'HSS152.4X127X12.7', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 44.588},
        {name: 'HSS152.4X152.4X12.7', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 49.298},
        {name: 'HSS152.4X152.4X15.9', shape: "Rect", family: "HSS-metric", height: 152.4, weight: 59.2675},
        {name: 'HSS177.8X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 10.1265},
        {name: 'HSS177.8X76.2X3.2', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 11.304},
        {name: 'HSS177.8X101.6X3.2', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 12.4815},
        {name: 'HSS177.8X127X3.2', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 13.659},
        {name: 'HSS177.8X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 14.8365},
        {name: 'HSS177.8X177.8X3.2', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 16.014},
        {name: 'HSS177.8X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 16.642},
        {name: 'HSS177.8X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 18.369},
        {name: 'HSS177.8X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 19.468},
        {name: 'HSS177.8X127X4.8', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 20.1745},
        {name: 'HSS177.8X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 21.7445},
        {name: 'HSS177.8X177.8X4.8', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 23.6285},
        {name: 'HSS177.8X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 24.178},
        {name: 'HSS177.8X127X6.4', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 26.533},
        {name: 'HSS177.8X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 26.6115},
        {name: 'HSS177.8X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 29.5945},
        {name: 'HSS177.8X177.8X6.4', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 31.243},
        {name: 'HSS177.8X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 31.3215},
        {name: 'HSS177.8X127X7.9', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 32.5775},
        {name: 'HSS177.8X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 34.854},
        {name: 'HSS177.8X127X9.5', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 38.3865},
        {name: 'HSS177.8X177.8X7.9', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 38.465},
        {name: 'HSS177.8X76.2X12.7', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 39.878},
        {name: 'HSS177.8X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 44.588},
        {name: 'HSS177.8X177.8X9.5', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 45.4515},
        {name: 'HSS177.8X127X12.7', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 49.298},
        {name: 'HSS177.8X177.8X12.7', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 58.718},
        {name: 'HSS177.8X177.8X15.9', shape: "Rect", family: "HSS-metric", height: 177.8, weight: 70.8855},
        {name: 'HSS203.2X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 11.304},
        {name: 'HSS203.2X76.2X3.2', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 12.4815},
        {name: 'HSS203.2X101.6X3.2', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 13.659},
        {name: 'HSS203.2X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 16.642},
        {name: 'HSS203.2X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 18.369},
        {name: 'HSS203.2X203.2X3.2', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 18.369},
        {name: 'HSS203.2X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 20.1745},
        {name: 'HSS203.2X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 21.7445},
        {name: 'HSS203.2X152.4X4.8', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 23.6285},
        {name: 'HSS203.2X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 24.178},
        {name: 'HSS203.2X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 26.533},
        {name: 'HSS203.2X50.8X7.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 26.6115},
        {name: 'HSS203.2X203.2X4.8', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 27.161},
        {name: 'HSS203.2X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 29.5945},
        {name: 'HSS203.2X152.4X6.4', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 31.243},
        {name: 'HSS203.2X50.8X9.5', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 31.3215},
        {name: 'HSS203.2X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 32.5775},
        {name: 'HSS203.2X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 34.854},
        {name: 'HSS203.2X203.2X6.4', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 35.953},
        {name: 'HSS203.2X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 38.3865},
        {name: 'HSS203.2X152.4X7.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 38.465},
        {name: 'HSS203.2X203.2X7.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 44.3525},
        {name: 'HSS203.2X76.2X12.7', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 44.588},
        {name: 'HSS203.2X152.4X9.5', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 45.4515},
        {name: 'HSS203.2X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 49.298},
        {name: 'HSS203.2X203.2X9.5', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 52.6735},
        {name: 'HSS203.2X152.4X12.7', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 58.718},
        {name: 'HSS203.2X101.6X15.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 59.2675},
        {name: 'HSS203.2X203.2X12.7', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 68.3735},
        {name: 'HSS203.2X152.4X15.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 70.8855},
        {name: 'HSS203.2X203.2X15.9', shape: "Rect", family: "HSS-metric", height: 203.2, weight: 83.21},
        {name: 'HSS228.6X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 20.1745},
        {name: 'HSS228.6X228.6X3.2', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 20.724},
        {name: 'HSS228.6X127X4.8', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 23.6285},
        {name: 'HSS228.6X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 26.533},
        {name: 'HSS228.6X177.8X4.8', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 27.161},
        {name: 'HSS228.6X228.6X74.8', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 30.6935},
        {name: 'HSS228.6X127X6.4', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 31.243},
        {name: 'HSS228.6X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 32.5775},
        {name: 'HSS228.6X177.8X6.4', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 35.953},
        {name: 'HSS228.6X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 38.3865},
        {name: 'HSS228.6X127X7.9', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 38.465},
        {name: 'HSS228.6X228.6X6.4', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 40.663},
        {name: 'HSS228.6X177.8X7.9', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 44.3525},
        {name: 'HSS228.6X127X9.5', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 45.4515},
        {name: 'HSS228.6X76.2X12.7', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 49.298},
        {name: 'HSS228.6X228.6X7.9', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 50.24},
        {name: 'HSS228.6X177.8X9.5', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 52.6735},
        {name: 'HSS228.6X127X12.7', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 58.718},
        {name: 'HSS228.6X228.6X9.5', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 59.7385},
        {name: 'HSS228.6X177.8X12.7', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 68.3735},
        {name: 'HSS228.6X127X15.9', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 70.8855},
        {name: 'HSS228.6X228.6X12.7', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 77.4795},
        {name: 'HSS228.6X177.8X15.9', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 83.21},
        {name: 'HSS228.6X228.6X15.9', shape: "Rect", family: "HSS-metric", height: 228.6, weight: 94.985},
        {name: 'HSS254X50.8X3.2', shape: "Rect", family: "HSS-metric", height: 254, weight: 13.659},
        {name: 'HSS254X76.2X3.2', shape: "Rect", family: "HSS-metric", height: 254, weight: 14.8365},
        {name: 'HSS254X88.9X3.2', shape: "Rect", family: "HSS-metric", height: 254, weight: 15.386},
        {name: 'HSS254X101.6X3.2', shape: "Rect", family: "HSS-metric", height: 254, weight: 16.014},
        {name: 'HSS254X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 20.1745},
        {name: 'HSS254X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 21.9015},
        {name: 'HSS254X88.9X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 22.765},
        {name: 'HSS254X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 23.6285},
        {name: 'HSS254X127X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 25.434},
        {name: 'HSS254X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 26.533},
        {name: 'HSS254X152.4X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 27.161},
        {name: 'HSS254X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 28.888},
        {name: 'HSS254X88.9X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 30.0655},
        {name: 'HSS254X203.2X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 30.6935},
        {name: 'HSS254X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 31.243},
        {name: 'HSS254X50.8X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 32.5775},
        {name: 'HSS254X127X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 33.598},
        {name: 'HSS254X254X4.8', shape: "Rect", family: "HSS-metric", height: 254, weight: 34.226},
        {name: 'HSS254X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 35.482},
        {name: 'HSS254X152.4X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 35.953},
        {name: 'HSS254X88.9X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 36.9735},
        {name: 'HSS254X50.8X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 38.3865},
        {name: 'HSS254X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 38.465},
        {name: 'HSS254X203.2X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 40.663},
        {name: 'HSS254X127X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 41.3695},
        {name: 'HSS254X76.2X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 41.919},
        {name: 'HSS254X88.9X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 43.646},
        {name: 'HSS254X152.4X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 44.3525},
        {name: 'HSS254X254X6.4', shape: "Rect", family: "HSS-metric", height: 254, weight: 45.373},
        {name: 'HSS254X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 45.4515},
        {name: 'HSS254X127X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 48.984},
        {name: 'HSS254X203.2X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 50.24},
        {name: 'HSS254X152.4X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 52.6735},
        {name: 'HSS254X88.9X12.7', shape: "Rect", family: "HSS-metric", height: 254, weight: 56.206},
        {name: 'HSS254X254X7.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 56.206},
        {name: 'HSS254X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 254, weight: 58.718},
        {name: 'HSS254X203.2X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 59.7385},
        {name: 'HSS254X254X9.5', shape: "Rect", family: "HSS-metric", height: 254, weight: 66.882},
        {name: 'HSS254X152.4X12.7', shape: "Rect", family: "HSS-metric", height: 254, weight: 68.3735},
        {name: 'HSS254X101.6X15.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 70.8855},
        {name: 'HSS254X203.2X12.7', shape: "Rect", family: "HSS-metric", height: 254, weight: 77.4795},
        {name: 'HSS254X152.4X15.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 83.21},
        {name: 'HSS254X254X12.7', shape: "Rect", family: "HSS-metric", height: 254, weight: 87.135},
        {name: 'HSS254X203.2X15.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 94.985},
        {name: 'HSS254X254X15.9', shape: "Rect", family: "HSS-metric", height: 254, weight: 105.975},
        {name: 'HSS304.8X50.8X4.8', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 23.6285},
        {name: 'HSS304.8X76.2X4.8', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 25.434},
        {name: 'HSS304.8X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 27.161},
        {name: 'HSS304.8X152.4X4.8', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 30.6935},
        {name: 'HSS304.8X50.8X6.4', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 31.243},
        {name: 'HSS304.8X76.2X6.4', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 33.598},
        {name: 'HSS304.8X203.2X4.8', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 34.226},
        {name: 'HSS304.8X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 35.953},
        {name: 'HSS304.8X50.8X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 38.465},
        {name: 'HSS304.8X152.4X6.4', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 40.663},
        {name: 'HSS304.8X304.8X4.8', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 41.291},
        {name: 'HSS304.8X76.2X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 41.3695},
        {name: 'HSS304.8X88.9X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 42.861},
        {name: 'HSS304.8X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 44.3525},
        {name: 'HSS304.8X203.2X6.4', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 45.373},
        {name: 'HSS304.8X254X6.4', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 50.1615},
        {name: 'HSS304.8X152.4X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 50.24},
        {name: 'HSS304.8X88.9X9.5', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 50.6325},
        {name: 'HSS304.8X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 52.6735},
        {name: 'HSS304.8X304.8X6.4', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 54.7145},
        {name: 'HSS304.8X203.2X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 56.206},
        {name: 'HSS304.8X152.4X9.5', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 59.7385},
        {name: 'HSS304.8X254X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 61.7795},
        {name: 'HSS304.8X203.2X9.5', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 66.882},
        {name: 'HSS304.8X304.8X7.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 67.9025},
        {name: 'HSS304.8X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 68.3735},
        {name: 'HSS304.8X254X9.5', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 73.947},
        {name: 'HSS304.8X152.4X12.7', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 77.4795},
        {name: 'HSS304.8X304.8X9.5', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 80.855},
        {name: 'HSS304.8X101.6X15.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 83.21},
        {name: 'HSS304.8X203.2X12.7', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 87.135},
        {name: 'HSS304.8X152.4X15.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 94.985},
        {name: 'HSS304.8X254X12.7', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 96.555},
        {name: 'HSS304.8X203.2X15.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 105.975},
        {name: 'HSS304.8X304.8X12.7', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 105.975},
        {name: 'HSS304.8X304.8X15.9', shape: "Rect", family: "HSS-metric", height: 304.8, weight: 130.31},
        {name: 'HSS355.6X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 30.6935},
        {name: 'HSS355.6X152.4X4.8', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 34.226},
        {name: 'HSS355.6X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 40.663},
        {name: 'HSS355.6X152.4X6.4', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 45.373},
        {name: 'HSS355.6X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 50.24},
        {name: 'HSS355.6X254X6.4', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 54.7145},
        {name: 'HSS355.6X152.4X7.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 56.206},
        {name: 'HSS355.6X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 59.7385},
        {name: 'HSS355.6X152.4X9.5', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 66.882},
        {name: 'HSS355.6X254X7.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 67.9025},
        {name: 'HSS355.6X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 77.4795},
        {name: 'HSS355.6X355.6X7.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 79.285},
        {name: 'HSS355.6X254X9.5', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 80.855},
        {name: 'HSS355.6X152.4X12.7', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 87.135},
        {name: 'HSS355.6X101.6X15.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 94.985},
        {name: 'HSS355.6X355.6X9.5', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 94.985},
        {name: 'HSS355.6X254X12.7', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 105.975},
        {name: 'HSS355.6X152.4X15.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 105.975},
        {name: 'HSS355.6X355.6X12.7', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 124.815},
        {name: 'HSS355.6X254X15.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 130.31},
        {name: 'HSS355.6X355.6X15.9', shape: "Rect", family: "HSS-metric", height: 355.6, weight: 153.075},
        {name: 'HSS406.4X101.6X4.8', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 34.226},
        {name: 'HSS406.4X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 45.373},
        {name: 'HSS406.4X203.2X6.4', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 54.7145},
        {name: 'HSS406.4X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 56.206},
        {name: 'HSS406.4X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 66.882},
        {name: 'HSS406.4X203.2X7.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 67.9025},
        {name: 'HSS406.4X304.8X7.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 79.285},
        {name: 'HSS406.4X203.2X9.5', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 80.855},
        {name: 'HSS406.4X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 87.135},
        {name: 'HSS406.4X406.4X7.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 91.845},
        {name: 'HSS406.4X304.8X9.5', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 94.985},
        {name: 'HSS406.4X203.2X12.7', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 105.975},
        {name: 'HSS406.4X101.6X15.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 105.975},
        {name: 'HSS406.4X406.4X9.5', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 109.115},
        {name: 'HSS406.4X304.8X12.7', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 124.815},
        {name: 'HSS406.4X203.2X15.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 130.31},
        {name: 'HSS406.4X406.4X12.7', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 143.655},
        {name: 'HSS406.4X304.8X15.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 153.075},
        {name: 'HSS406.4X406.4X15.9', shape: "Rect", family: "HSS-metric", height: 406.4, weight: 177.41},
        {name: 'HSS457.2X152.4X6.4', shape: "Rect", family: "HSS-metric", height: 457.2, weight: 54.7145},
        {name: 'HSS457.2X152.4X7.9', shape: "Rect", family: "HSS-metric", height: 457.2, weight: 67.9025},
        {name: 'HSS457.2X152.4X9.5', shape: "Rect", family: "HSS-metric", height: 457.2, weight: 80.855},
        {name: 'HSS457.2X152.4X12.7', shape: "Rect", family: "HSS-metric", height: 457.2, weight: 105.975},
        {name: 'HSS457.2X152.4X15.9', shape: "Rect", family: "HSS-metric", height: 457.2, weight: 130.31},
        {name: 'HSS508X101.6X6.4', shape: "Rect", family: "HSS-metric", height: 508, weight: 54.7145},
        {name: 'HSS508X101.6X7.9', shape: "Rect", family: "HSS-metric", height: 508, weight: 67.9025},
        {name: 'HSS508X203.2X7.9', shape: "Rect", family: "HSS-metric", height: 508, weight: 79.285},
        {name: 'HSS508X101.6X9.5', shape: "Rect", family: "HSS-metric", height: 508, weight: 80.855},
        {name: 'HSS508X304.8X7.9', shape: "Rect", family: "HSS-metric", height: 508, weight: 91.845},
        {name: 'HSS508X203.2X9.5', shape: "Rect", family: "HSS-metric", height: 508, weight: 94.985},
        {name: 'HSS508X101.6X12.7', shape: "Rect", family: "HSS-metric", height: 508, weight: 105.975},
        {name: 'HSS508X304.8X9.5', shape: "Rect", family: "HSS-metric", height: 508, weight: 109.115},
        {name: 'HSS508X203.2X12.7', shape: "Rect", family: "HSS-metric", height: 508, weight: 124.815},
        {name: 'HSS508X304.8X12.7', shape: "Rect", family: "HSS-metric", height: 508, weight: 143.655},
        {name: 'HSS508X203.2X15.9', shape: "Rect", family: "HSS-metric", height: 508, weight: 153.075},
        {name: 'HSS508X304.8X15.9', shape: "Rect", family: "HSS-metric", height: 508, weight: 177.41},
    ],
    Circle: [
        {name: 'HSS42.2X3.6', shape: "Circle", family: "HSRO-metric", height: 42.2, weight: 3.16355},
        {name: 'HSS48.3X3.0', shape: "Circle", family: "HSRO-metric", height: 48.3, weight: 3.16355},
        {name: 'HSS48.3X3.7', shape: "Circle", family: "HSRO-metric", height: 48.3, weight: 3.79155},
        {name: 'HSS48.3X4.8', shape: "Circle", family: "HSRO-metric", height: 48.3, weight: 4.7728},
        {name: 'HSS60.3X3.2', shape: "Circle", family: "HSRO-metric", height: 60.3, weight: 4.16835},
        {name: 'HSS60.3X3.9', shape: "Circle", family: "HSRO-metric", height: 60.3, weight: 5.06325},
        {name: 'HSS60.3X4.8', shape: "Circle", family: "HSRO-metric", height: 60.3, weight: 6.0759},
        {name: 'HSS60.3X5.5', shape: "Circle", family: "HSRO-metric", height: 60.3, weight: 7.04145},
        {name: 'HSS60.3X6.4', shape: "Circle", family: "HSRO-metric", height: 60.3, weight: 7.9285},
        {name: 'HSS63.5X3.2', shape: "Circle", family: "HSRO-metric", height: 63.5, weight: 4.40385},
        {name: 'HSS63.5X4.8', shape: "Circle", family: "HSRO-metric", height: 63.5, weight: 6.42915},
        {name: 'HSS63.5X6.4', shape: "Circle", family: "HSRO-metric", height: 63.5, weight: 8.3995},
        {name: 'HSS73X3.2', shape: "Circle", family: "HSRO-metric", height: 73, weight: 5.1182},
        {name: 'HSS73X4.8', shape: "Circle", family: "HSRO-metric", height: 73, weight: 7.49675},
        {name: 'HSS73X5.2', shape: "Circle", family: "HSRO-metric", height: 73, weight: 8.0855},
        {name: 'HSS73X6.4', shape: "Circle", family: "HSRO-metric", height: 73, weight: 9.8125},
        {name: 'HSS76.2X3.2', shape: "Circle", family: "HSRO-metric", height: 76.2, weight: 5.31445},
        {name: 'HSS76.2X3.4', shape: "Circle", family: "HSRO-metric", height: 76.2, weight: 5.67555},
        {name: 'HSS76.2X3.9', shape: "Circle", family: "HSRO-metric", height: 76.2, weight: 6.42915},
        {name: 'HSS76.2X4.8', shape: "Circle", family: "HSRO-metric", height: 76.2, weight: 7.8029},
        {name: 'HSS76.2X5.2', shape: "Circle", family: "HSRO-metric", height: 76.2, weight: 8.478},
        {name: 'HSS76.2X5.5', shape: "Circle", family: "HSRO-metric", height: 76.2, weight: 8.949},
        {name: 'HSS76.2X6.4', shape: "Circle", family: "HSRO-metric", height: 76.2, weight: 10.2835},
        {name: 'HSS88.9X3.2', shape: "Circle", family: "HSRO-metric", height: 88.9, weight: 6.2329},
        {name: 'HSS88.9X4.8', shape: "Circle", family: "HSRO-metric", height: 88.9, weight: 9.1845},
        {name: 'HSS88.9X5.2', shape: "Circle", family: "HSRO-metric", height: 88.9, weight: 9.9695},
        {name: 'HSS88.9X5.5', shape: "Circle", family: "HSRO-metric", height: 88.9, weight: 10.519},
        {name: 'HSS88.9X6.4', shape: "Circle", family: "HSRO-metric", height: 88.9, weight: 12.089},
        {name: 'HSS88.9X7.6', shape: "Circle", family: "HSRO-metric", height: 88.9, weight: 14.287},
        {name: 'HSS88.9X8', shape: "Circle", family: "HSRO-metric", height: 88.9, weight: 14.8365},
        {name: 'HSS101.6X3.2', shape: "Circle", family: "HSRO-metric", height: 101.6, weight: 7.1906},
        {name: 'HSS101.6X4.8', shape: "Circle", family: "HSRO-metric", height: 101.6, weight: 10.5975},
        {name: 'HSS101.6X5.6', shape: "Circle", family: "HSRO-metric", height: 101.6, weight: 12.3245},
        {name: 'HSS101.6X5.7', shape: "Circle", family: "HSRO-metric", height: 101.6, weight: 12.6385},
        {name: 'HSS101.6X6', shape: "Circle", family: "HSRO-metric", height: 101.6, weight: 13.188},
        {name: 'HSS101.6X6.4', shape: "Circle", family: "HSRO-metric", height: 101.6, weight: 13.973},
        {name: 'HSS101.6X8', shape: "Circle", family: "HSRO-metric", height: 101.6, weight: 17.1915},
        {name: 'HSS114.3X3.2', shape: "Circle", family: "HSRO-metric", height: 114.3, weight: 8.0855},
        {name: 'HSS114.3X4.8', shape: "Circle", family: "HSRO-metric", height: 114.3, weight: 11.932},
        {name: 'HSS114.3X6', shape: "Circle", family: "HSRO-metric", height: 114.3, weight: 14.9935},
        {name: 'HSS114.3X8.6', shape: "Circle", family: "HSRO-metric", height: 114.3, weight: 20.881},
        {name: 'HSS114.3X9.5', shape: "Circle", family: "HSRO-metric", height: 114.3, weight: 23.079},
        {name: 'HSS127X3.2', shape: "Circle", family: "HSRO-metric", height: 127, weight: 9.0275},
        {name: 'HSS127X4.8', shape: "Circle", family: "HSRO-metric", height: 127, weight: 13.345},
        {name: 'HSS127X6.4', shape: "Circle", family: "HSRO-metric", height: 127, weight: 17.6625},
        {name: 'HSS127X6.6', shape: "Circle", family: "HSRO-metric", height: 127, weight: 18.212},
        {name: 'HSS127X7.9', shape: "Circle", family: "HSRO-metric", height: 127, weight: 21.7445},
        {name: 'HSS127X9.5', shape: "Circle", family: "HSRO-metric", height: 127, weight: 25.8265},
        {name: 'HSS127X12.7', shape: "Circle", family: "HSRO-metric", height: 127, weight: 33.5195},
        {name: 'HSS139.7X6.6', shape: "Circle", family: "HSRO-metric", height: 139.7, weight: 20.096},
        {name: 'HSS139.7X9.5', shape: "Circle", family: "HSRO-metric", height: 139.7, weight: 28.6525},
        {name: 'HSS139.7X12.7', shape: "Circle", family: "HSRO-metric", height: 139.7, weight: 37.2875},
        {name: 'HSS141.3X3.4', shape: "Circle", family: "HSRO-metric", height: 141.3, weight: 10.7545},
        {name: 'HSS141.3X4.8', shape: "Circle", family: "HSRO-metric", height: 141.3, weight: 14.915},
        {name: 'HSS141.3X6.6', shape: "Circle", family: "HSRO-metric", height: 141.3, weight: 20.3315},
        {name: 'HSS141.3X9.5', shape: "Circle", family: "HSRO-metric", height: 141.3, weight: 28.9665},
        {name: 'HSS141.3X12.7', shape: "Circle", family: "HSRO-metric", height: 141.3, weight: 37.7585},
        {name: 'HSS152.4X3.2', shape: "Circle", family: "HSRO-metric", height: 152.4, weight: 10.833},
        {name: 'HSS152.4X4.8', shape: "Circle", family: "HSRO-metric", height: 152.4, weight: 16.0925},
        {name: 'HSS152.4X6.4', shape: "Circle", family: "HSRO-metric", height: 152.4, weight: 21.352},
        {name: 'HSS152.4X7.1', shape: "Circle", family: "HSRO-metric", height: 152.4, weight: 23.7855},
        {name: 'HSS152.4X7.9', shape: "Circle", family: "HSRO-metric", height: 152.4, weight: 26.4545},
        {name: 'HSS152.4X9.5', shape: "Circle", family: "HSRO-metric", height: 152.4, weight: 31.4},
        {name: 'HSS152.4X12.7', shape: "Circle", family: "HSRO-metric", height: 152.4, weight: 40.977},
        {name: 'HSS168.3X3.2', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 12.0105},
        {name: 'HSS168.3X4.8', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 17.898},
        {name: 'HSS168.3X6.4', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 23.707},
        {name: 'HSS168.3X7.1', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 26.2975},
        {name: 'HSS168.3X7.9', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 29.359},
        {name: 'HSS168.3X9.5', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 34.854},
        {name: 'HSS168.3X11', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 39.7995},
        {name: 'HSS168.3X12.7', shape: "Circle", family: "HSRO-metric", height: 168.3, weight: 45.6085},
        {name: 'HSS174.6X4.8', shape: "Circle", family: "HSRO-metric", height: 174.6, weight: 18.526},
        {name: 'HSS174.6X6.4', shape: "Circle", family: "HSRO-metric", height: 174.6, weight: 24.649},
        {name: 'HSS174.6X7.9', shape: "Circle", family: "HSRO-metric", height: 174.6, weight: 30.458},
        {name: 'HSS174.6X9.5', shape: "Circle", family: "HSRO-metric", height: 174.6, weight: 36.267},
        {name: 'HSS174.6X12.7', shape: "Circle", family: "HSRO-metric", height: 174.6, weight: 47.414},
        {name: 'HSS177.8X3.2', shape: "Circle", family: "HSRO-metric", height: 177.8, weight: 12.717},
        {name: 'HSS177.8X4.8', shape: "Circle", family: "HSRO-metric", height: 177.8, weight: 18.9185},
        {name: 'HSS177.8X6.4', shape: "Circle", family: "HSRO-metric", height: 177.8, weight: 25.0415},
        {name: 'HSS177.8X7.9', shape: "Circle", family: "HSRO-metric", height: 177.8, weight: 31.0075},
        {name: 'HSS177.8X9.5', shape: "Circle", family: "HSRO-metric", height: 177.8, weight: 36.895},
        {name: 'HSS177.8X12.7', shape: "Circle", family: "HSRO-metric", height: 177.8, weight: 48.356},
        {name: 'HSS190.5X4.8', shape: "Circle", family: "HSRO-metric", height: 190.5, weight: 20.253},
        {name: 'HSS190.5X6.4', shape: "Circle", family: "HSRO-metric", height: 190.5, weight: 26.9255},
        {name: 'HSS190.5X7.9', shape: "Circle", family: "HSRO-metric", height: 190.5, weight: 33.3625},
        {name: 'HSS190.5X9.5', shape: "Circle", family: "HSRO-metric", height: 190.5, weight: 39.721},
        {name: 'HSS190.5X12.7', shape: "Circle", family: "HSRO-metric", height: 190.5, weight: 52.2025},
        {name: 'HSS193.7X8.3', shape: "Circle", family: "HSRO-metric", height: 193.7, weight: 35.482},
        {name: 'HSS193.7X9.5', shape: "Circle", family: "HSRO-metric", height: 193.7, weight: 40.4275},
        {name: 'HSS219.1X4.8', shape: "Circle", family: "HSRO-metric", height: 219.1, weight: 23.393},
        {name: 'HSS219.1X6.4', shape: "Circle", family: "HSRO-metric", height: 219.1, weight: 31.086},
        {name: 'HSS219.1X8.2', shape: "Circle", family: "HSRO-metric", height: 219.1, weight: 39.721},
        {name: 'HSS219.1X9.5', shape: "Circle", family: "HSRO-metric", height: 219.1, weight: 45.9225},
        {name: 'HSS219.1X12.7', shape: "Circle", family: "HSRO-metric", height: 219.1, weight: 60.288},
        {name: 'HSS219.1X15.9', shape: "Circle", family: "HSRO-metric", height: 219.1, weight: 74.418},
        {name: 'HSS244.5X4.8', shape: "Circle", family: "HSRO-metric", height: 244.5, weight: 26.219},
        {name: 'HSS244.5X6.4', shape: "Circle", family: "HSRO-metric", height: 244.5, weight: 34.7755},
        {name: 'HSS244.5X7.9', shape: "Circle", family: "HSRO-metric", height: 244.5, weight: 43.175},
        {name: 'HSS244.5X9.5', shape: "Circle", family: "HSRO-metric", height: 244.5, weight: 51.653},
        {name: 'HSS244.5X12.7', shape: "Circle", family: "HSRO-metric", height: 244.5, weight: 67.9025},
        {name: 'HSS254X4.8', shape: "Circle", family: "HSRO-metric", height: 254, weight: 27.161},
        {name: 'HSS254X6.4', shape: "Circle", family: "HSRO-metric", height: 254, weight: 36.1885},
        {name: 'HSS254X7.9', shape: "Circle", family: "HSRO-metric", height: 254, weight: 44.9805},
        {name: 'HSS254X9.5', shape: "Circle", family: "HSRO-metric", height: 254, weight: 53.694},
        {name: 'HSS254X12.7', shape: "Circle", family: "HSRO-metric", height: 254, weight: 70.4145},
        {name: 'HSS254X15.9', shape: "Circle", family: "HSRO-metric", height: 254, weight: 87.135},
        {name: 'HSS273.1X6.4', shape: "Circle", family: "HSRO-metric", height: 273.1, weight: 39.0145},
        {name: 'HSS273.1X9.5', shape: "Circle", family: "HSRO-metric", height: 273.1, weight: 57.6975},
        {name: 'HSS273.1X12.7', shape: "Circle", family: "HSRO-metric", height: 273.1, weight: 75.988},
        {name: 'HSS323.9X6.4', shape: "Circle", family: "HSRO-metric", height: 323.9, weight: 46.3935},
        {name: 'HSS323.9X9.5', shape: "Circle", family: "HSRO-metric", height: 323.9, weight: 68.8445},
        {name: 'HSS323.9X12.7', shape: "Circle", family: "HSRO-metric", height: 323.9, weight: 90.275},
        {name: 'HSS355.6X6.4', shape: "Circle", family: "HSRO-metric", height: 355.6, weight: 51.182},
        {name: 'HSS355.6X7.9', shape: "Circle", family: "HSRO-metric", height: 355.6, weight: 63.271},
        {name: 'HSS355.6X9.5', shape: "Circle", family: "HSRO-metric", height: 355.6, weight: 75.988},
        {name: 'HSS355.6X12.7', shape: "Circle", family: "HSRO-metric", height: 355.6, weight: 100.48},
        {name: 'HSS355.6X15.9', shape: "Circle", family: "HSRO-metric", height: 355.6, weight: 124.03},
        {name: 'HSS406.4X6.4', shape: "Circle", family: "HSRO-metric", height: 406.4, weight: 58.247},
        {name: 'HSS406.4X7.9', shape: "Circle", family: "HSRO-metric", height: 406.4, weight: 72.9265},
        {name: 'HSS406.4X9.5', shape: "Circle", family: "HSRO-metric", height: 406.4, weight: 87.135},
        {name: 'HSS406.4X11.1', shape: "Circle", family: "HSRO-metric", height: 406.4, weight: 100.48},
        {name: 'HSS406.4X12.7', shape: "Circle", family: "HSRO-metric", height: 406.4, weight: 114.61},
        {name: 'HSS406.4X15.9', shape: "Circle", family: "HSRO-metric", height: 406.4, weight: 142.085},
        {name: 'HSS457.2X9.5', shape: "Circle", family: "HSRO-metric", height: 457.2, weight: 98.125},
        {name: 'HSS457.2X12.7', shape: "Circle", family: "HSRO-metric", height: 457.2, weight: 129.525},
        {name: 'HSS508X9.5', shape: "Circle", family: "HSRO-metric", height: 508, weight: 109.115},
        {name: 'HSS508X12.7', shape: "Circle", family: "HSRO-metric", height: 508, weight: 144.44},
    ]
}