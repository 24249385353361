<template>
  <v-card width="280" height="300" class="pa-6 d-flex flex-column" 
    style="font-size:20px; border: 3px solid rgb(var(--v-theme-orange)); border-radius: 15px">
    <div class="font-weight-bold">
      {{planName}}
    </div>
    <br>
    <div>
      <span >Unlimited Computes</span>
    </div>
    <div>
      <span>Image Underlay</span>
    </div>
    <div>
      <span >Save Sketches</span>
    </div>
    <div>
      <span >Share Sketches</span>
    </div>
    <div>
      <span>SSO Integration</span>
    </div>
  </v-card>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'

export default {
  props: {
    planName: {
      type: String,
    },
  },
  components: { toggleButton},
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    
  }),
  created(){},
  computed: {},
  mounted() {},
}
</script>