export const materials_imperial = [
    {
        name: 'Steel',
        E: {name: 'Modulus of Elasticity', value: 29000, unit: 'ksi'},
        Density: {name: 'Density', value: 490, unit: 'lb/ft3'}
    },
    {
        name: 'Timber',
        E: {name: 'Modulus of Elasticity', value: 1900, unit: 'ksi'},
        Density: {name: 'Density', value: 35, unit: 'lb/ft3'}
    },
    {
        name: 'Concrete - 4000',
        fc: {name: 'Strength', value: 4000, unit: 'psi'},
        E: {name: 'Modulus of Elasticity', value: 3605, unit: 'ksi'},
        Density: {name: 'Density', value: 150, unit: 'lb/ft3'}
    },
    {
        name: 'Aluminum',
        E: {name: 'Modulus of Elasticity', value: 10000, unit: 'ksi'},
        Density: {name: 'Density', value: 170, unit: 'lb/ft3'}
    }
];

export const materials_metric = [
    {
        name: 'Steel',
        E: {name: 'Modulus of Elasticity', value: 200, unit: 'GPa'},
        Density: {name: 'Density', value: 7849, unit: 'kg/m³'}
    },
    {
        name: 'Timber',
        E: {name: 'Modulus of Elasticity', value: 13.1, unit: 'GPa'},
        Density: {name: 'Density', value: 560, unit: 'kg/m³'}
    },
    {
        name: 'Concrete - 4000',
        fc: {name: 'Strength', value: 27.6, unit: 'MPa'},
        E: {name: 'Modulus of Elasticity', value: 24.8, unit: 'GPa'},
        Density: {name: 'Density', value: 2400, unit: 'kg/m³'}
    },
    {
        name: 'Aluminum',
        E: {name: 'Modulus of Elasticity', value: 69, unit: 'GPa'},
        Density: {name: 'Density', value: 2700, unit: 'kg/m³'}
    }
];