<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 25 20">
  <g fill="none" fill-rule="nonzero" :stroke="color" stroke-linecap="butt" stroke-linejoin="none" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
    <path d="M0,0h25" stroke-width="3" stroke-linejoin="miter"></path>
    <g stroke-width="2">
      <path d="M0,0v20l3,-5" stroke-linejoin="round"></path>
      <path d="M0,20l-3,-5" stroke-linejoin="miter"></path>
    </g>
    <g stroke-width="2">
      <path d="M12.5,0v20l3,-5" stroke-linejoin="round"></path>
      <path d="M12.5,20l-3,-5" stroke-linejoin="miter"></path>
    </g>
    <g stroke-width="2">
      <path d="M25,0v20l3,-5" stroke-linejoin="round"></path>
      <path d="M25,20l-3,-5" stroke-linejoin="miter"></path>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: 'lineLoad',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  },
}
</script>
