import {useStore} from '@/store/store'

const font = "Arial"
export const disabledColor = '#9c9c9c'
export const loadColor = 'orange'
export const secondaryColor = 'orange'
export const massColor = 'grey'
export const textColor = "#3366ff"
export const releaseColor = "#3366ff"
export const highlightColor = '#3EFFF3'
export const selectedImageColor = '#3EFFF3'

export const analysisPaths = {
  deflection:  {
    strokeColor: "#b97eed",
    strokeJoin: 'round',
    strokeWidth: 1.5
  },
  moment:  {
    strokeColor: "#63db80",
    fillColor: "#cfe8d5",
    strokeJoin: 'round',
    strokeWidth: 1.5
  },
  shear:  {
    fillColor: '#f7d78b',
    strokeColor: "#f0b835",
    strokeJoin: 'round',
    strokeWidth: 1.5          
  },
  axial: {
    strokeColor: "#f03535",
    fillColor: '#edcccc',
    strokeJoin: 'round',
    strokeWidth: 1.5          
  },
  mode: {
    strokeColor: "grey",
    strokeJoin: 'round',
    strokeWidth: 1.5  
  },
  utilization: {
    strokeJoin: 'round',
    strokeWidth: 5
  }
}
export const axialTension = {
  strokeColor: '#b7e5e8',
  fillColor: '#daeaeb'
}
export const axialTensionText = {
  fontFamily: font,
  fontWeight: 'bold',
  fontSize: 14,
  fillColor: '#b7e5e8',
  justification: 'center'    
}
export const analysisTextStyles = {
  deflection: {
    fontFamily: font,
    fontSize: 14,
    fontWeight: 'bold',
    fillColor: "#b97eed",
    justification: 'center',
    leading: 0      
  },
  moment: {
    fontFamily: font,
    fontWeight: 'bold',
    fontSize: 14,
    fillColor: "#63db80",
    justification: 'center',
    leading: 0         
  },
  shear:  {
    fontFamily: font,
    fontWeight: 'bold',
    fontSize: 14,
    fillColor: "#f0b835",
    justification: 'center',
    leading: 0         
  },
  axial: {
    fontFamily: font,
    fontWeight: 'bold',
    fontSize: 14,
    fillColor: "#f03535",
    justification: 'center',
    leading: 0          
  },
  utilization: {
    fontFamily: font,
    fontWeight: 'bold',
    fontSize: 14,
    fillColor: "black",
    justification: 'center',
    leading: 0          
  }
}
export const lengthText = {
  fontFamily: font,
  fontSize: 20,
  fillColor: 'blue',
  justification: 'center'
};
export const dimText = {
  fontFamily: font,
  fontSize: 20,
  fillColor: '#3EFFF3',
  justification: 'center'
};
export const dimTextGrey = {
  fontFamily: font,
  fontSize: 14,
  fillColor: '#696969',
  justification: 'center',
  leading: 0
};
export const dimStyle = {
  strokeWidth: 1,
  strokeColor: '#696969'
}
export const rollerText = {
  fontFamily: font,
  fontSize: 12,
  fontWeight: 'normal',
  fillColor: 'black',
  justification: 'center'
}
export const reactionText = {
  fontFamily: font,
  fontSize: 14,
  fillColor: 'black',
  justification: 'center'
}
export const reactionBase = {
  strokeWidth: 2,
  strokeColor: 'blue'
}
export function reaction(){
  const store = useStore()
  let zoomedStyle = JSON.parse(JSON.stringify(reactionBase))
  zoomedStyle.strokeWidth = reactionBase.strokeWidth/store.grid.canvasZoom
  return zoomedStyle
}
export const lineBase = {
  strokeColor: "#000000",
  strokeWidth: 1.5
}
export function line(){
  const store = useStore()
  let zoomedStyle = JSON.parse(JSON.stringify(lineBase))
  zoomedStyle.strokeWidth = lineBase.strokeWidth/store.grid.canvasZoom
  return zoomedStyle
}
export const nodeBase = {
  strokeColor: 'black',
  fillColor: "white",
  strokeWidth: 1
}
export function node(){
  const store = useStore()
  let zoomedStyle = JSON.parse(JSON.stringify(nodeBase))
  zoomedStyle.strokeWidth = nodeBase.strokeWidth/store.grid.canvasZoom
  return zoomedStyle
}
export const gridLine = {
  strokeColor: '#969696',
  strokeWidth: 4,
  dashArray: [2, 46, 2, 0]
}
export const snappingIcon = {
  strokeColor: secondaryColor,
  fillColor: secondaryColor,
}
export const endSnappingIcon = {
  strokeColor: secondaryColor,
  strokeWidth: 2
}
export const supportIcon = {
  strokeColor: "#000000",
  strokeWidth: 1.5
}
export const memberSize = {
  fontFamily: font,
  fontSize: 16,
  fillColor: textColor,
  justification: 'center'
}
export const loadValue = {
  fontFamily: font,
  fontSize: 16,
  fillColor: textColor,
  justification: 'center',
}

export const lasso = {
  strokeColor: 'grey',
  strokeWidth: 1,
  dashArray: [5, 5]
}

    
