<template>
  <v-card width="300" height="320" class="pa-6 d-flex flex-column" style="font-size:20px">
    <div class="font-weight-bold">
      FREE
    </div>
    <div class="mb-2">
      <span class="text-purple">$0</span>
      <span class="text-grey font-weight-regular">/Month</span>
    </div>
    <div>
      <span>25 Computes</span>
    </div>
    <div class="text-white">blank</div>
    <v-btn @click="$emit('selected')" :disabled="disabled"
      class="mt-auto" :color="btnColor" v-if="btnText != ''" >
      <span class="text-white">{{btnText}}</span>  
    </v-btn>
  </v-card>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'

export default {
  props: {
    btnColor: {
      type: String,
      default: 'orange'
    },
    btnText: { 
      type: String,
      default: 'Get Started'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { toggleButton},
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    
  }),
  created(){},
  computed: {},
  mounted() {},
}
</script>