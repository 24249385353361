//import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router'

import guards from '@/router/guards';

import Home from "@/pages/Home.vue";
import Dashboard from "@/pages/Dashboard.vue";
import Page404 from "@/pages/Page404.vue";
import Page401 from "@/pages/Page401.vue";
import Canvas from "@/pages/Canvas.vue"
import Account from "@/pages/Account.vue"
import Plans from "@/pages/Plans.vue"

const routes = [
  {
    path: "/",
    name: 'Home',
    component: Home,
    meta: {
      title: route => "ForceSketch",
      guards: [ guards.redirectToDashboard ]
    },
    props: true
  },
  {
    path: "/dashboard",
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: route => "Dashboard",
      guards: [guards.authGuard]
    },
    props: true
  },
  {
    path: "/canvas",
    name: 'Canvas',
    component: Canvas,
    meta: {
      title: route => "Canvas",
      guards: [guards.authGuard]
    },
    props: true
  },
  {
    path: "/template/:id",
    name: 'Template',
    component: Canvas,
    meta: {
      title: route => "Template",
      guards: [guards.authGuard]
    },
    props: true
  },
  {
    path: '/canvas/:id',
    name: 'SavedCanvas',
    component: Canvas,
    meta: {
      title: route => "Canvas",
      guards: [guards.authGuard, guards.canvasGuard]
    },
    props: true
  },
  {
    path: "/account",
    name: 'Account',
    component: Account,
    meta: {
      title: route => "Account",
      guards: [guards.authGuard]
    },
    props: true
  },
  {
    path: "/plans",
    name: 'Plans',
    component: Plans,
    meta: {
      title: route => "Account",
      guards: [guards.authGuard]
    },
    props: true
  },
  {
    // catch all 404
    path: "/404",
    name: "404",
    component: Page404,
    meta: {
      title: route => "App: Unknown"
    }
  },
  {
    // not authorized
    path: "/401",
    name: "401",
    component: Page401,
    meta: {
      title: route => "App: NotAuthorized"
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0 ){
    return next({ path: '/404' });
  }
  if (to.meta && to.meta.title) {
    document.title = to.meta.title(to);
  }
  if (!to.meta.guards || to.meta.guards.length < 1) {
    return next();
  }
  return guards.pipeline(to, from, next, to.meta.guards);
});

export default router;