<template>
  <toggleButton :tooltip="'Share'" @click="openShareDialog()">
    <v-icon>
      mdi:mdi-account-multiple-plus
    </v-icon>
  </toggleButton>

  <!-- Share Dialog -->
  <ShareDialog 
    :shareDialog="shareDialog" 
    :sketch="sketch" 
    @shareDialogClosed="shareDialog=false">
  </ShareDialog>
</template>

<script>
import {useStore} from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import ShareDialog from '@/components/ui/ShareDialog.vue';

export default {
  props: {
    sketch: {type: Object}
  },
  components: {
    toggleButton,
    ShareDialog
  },
  setup(){
    const store = useStore()
    return {store}
  },
  data: () => ({
    shareDialog: false,
  }),
  async created(){
  },
  computed: {},
  mounted() {
  },
  methods: { 
    openShareDialog(){
      this.shareDialog = true
    },
  },
}
</script>

<style scoped>
</style>
