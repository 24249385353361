import paper from 'paper'
import * as grid from '@/tools/Grid'
import * as draw from '@/tools/Draw.js'
import * as select from '@/tools/Select.js'
import * as supports from '@/tools/Supports.js'
import * as loads from '@/tools/Loads.js'
import * as analyze from '@/tools/Analyze.js'
import * as resize from '@/tools/Resize.js'
import {createSymbols} from '@/constants/Symbols.js'
import {layers} from '@/constants/Layers.js'
import {useStore} from '@/store/store'

var scrollPickerValues = null

const CreatePaperScopeAndLayers = () => {
  const store = useStore()
  store.canvas = new paper.PaperScope();

  store.canvas.setup(document.getElementById('canvas'));
  store.canvasCenter = store.canvas.view.center

  new paper.Tool()

  layers.forEach(layer => {
    let newLayer = new paper.Layer()
    newLayer.name = layer.name
    newLayer.visible = layer.visible
  })
  
  createSymbols()
}

const CreateTools = () => {
  const store = useStore()
  store.tools['draw'] =  new draw.Draw()
  store.tools['loads'] = new loads.Loads()
  store.tools['select'] = new select.Select()
  store.tools['supports'] = new supports.Supports()
  store.tools['resize'] = new resize.Resize()
  store.analyze = new analyze.AnalyzeMode()
  store.tools.select.activate('select')
}

const GenerateScrollPickerValues = () => {
  let numberPickerValues = []
  for (let i = 0; i<10; i++){
    numberPickerValues.push({label: i.toString(), value: i.toString()})
  }
  let decimal = [{label: '.', value: '.'}]
  scrollPickerValues = [numberPickerValues, numberPickerValues, numberPickerValues, decimal, numberPickerValues]
}

export {CreatePaperScopeAndLayers, CreateTools, GenerateScrollPickerValues, scrollPickerValues}