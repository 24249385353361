<template>
  <v-dialog
    :model-value="dialog"
    width="450"
    >
    <v-card class="pa-2">
      <v-card-title>
        <slot></slot>
      </v-card-title>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="red" @click="$emit('deleteCancel')">Cancel</v-btn>
        <v-btn color="primary" @click="onClick" :loading="loading">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>  
export default {
  props: {
    onClick: {
      type: Function,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    loading: {
        type: Boolean,
        required: true
    }
  }
}
</script>