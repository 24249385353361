<template>

  <div>
    <!-- Upload -->
    <div>
      <v-btn flat size="36" color="white" id="imageUnderlay" @click="onUploadClick" style="margin:1px">
        <v-icon color="black">fa:fas fa-image</v-icon>
        <v-tooltip activator="parent" location="bottom" v-if="!store.onMobile">
          {{store.imageFile ? 'Edit Image' : 'Upload Image'}}
        </v-tooltip>
      </v-btn>

      <input
        class="d-none"
        ref="uploader"
        type="file"
        @change="uploadFile($event)"
      />
    </div>

  </div>
</template>

<script>
  import paper from 'paper'
  import {useStore} from '@/store/store'
  import { nanoid } from "nanoid";
  export default {
    setup(){
    const store = useStore()
    return {store}
   },
    data: () => ({}),
    methods: {
      onUploadClick() {
        this.store.changeTool('resize', 'resize')
        if (!this.store.imageFile) this.$refs.uploader.click();
        this.store.changeBackgroundColor('dark')
      },
      uploadFile(e){
        var files = e.target.files || e.dataTransfer.files;
        let file = files[0]
        if (!file) return;

        const allowedExtensions = ['png', 'jpg', 'jpeg', 'jfif'];
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.store.snackbarText = 'Image File Type Not Supported'
          this.store.snackbar = true
          e.target.value = ''; // Clear the file input
          return;
        }

        var fileURL = URL.createObjectURL(file)
        this.store.imageFile = {
          file: file,
          id: nanoid()
        }
        var raster = new paper.Raster({source: fileURL, position: this.store.canvas.view.center});
        raster.onLoad = () => {
          if (raster.bounds.width > (window.innerWidth*0.8)){
            let scaleFactor = 0.8*window.innerWidth/raster.bounds.width
            raster.scale(scaleFactor)
          }
          raster.opacity = this.store.imageOpacity
          this.store.imageLayer.addChild(raster)
          e.target.value = ''
        }
      },
    }
  };
</script>

<style scoped>
</style>
  