<template>
  <v-app>
    <v-main v-if="$route.name">
      <Navbar v-if="!($route.name.includes('Canvas') || $route.name.includes('Template'))"/>
        <!-- Alert -->
        <!-- <v-alert
          class="mb-0"
          border="start"
          :model-value="store.promptPWAInstall"
          closable
          close-label="Close Alert"
          color="secondary"
          prominent
          icon="fa: fas fa-mobile-screen"
          @click:close="store.promptPWAInstall = false"
        >
          <b>Get the Best Experience with ForceSketch On Mobile Devices!</b>
          <br>
          <p>Enhance your experience by installing ForceSketch as a Progressive Web App (PWA). 
            It's quick and easy to implement. Click the button below for detailed instructions.</p>
          <a href="https://core-studio.gitbook.io/forcesketch/usage-on-mobile-devices" target="_blank" style="color: inherit; text-decoration: none;">
            <v-btn class="my-1"><v-icon class="mr-2">mdi: mdi-open-in-new</v-icon> Use On Mobile Device</v-btn>
          </a>
        </v-alert> -->

        <!-- Router View -->
        <router-view style="overflow:hidden">
        </router-view>
        
        <!-- Snackbar -->
        <v-snackbar v-model="store.snackbar" :timeout="store.snackbarTimeOut ? store.snackbarTimeOut : 2000"  location="top"  
          style="margin-top: 60px" color="primary" min-height="30" rounded="15">
          <span class="text-white text-capitalize">{{store.snackbarText}}</span>
          <template v-slot:actions>
            <v-btn
              color="white"
              variant="text"
              @click="handleSnackBarClose"
            >
              {{(store.copy || store.moving) ? 'ESC' : 'Close'}}
            </v-btn>
          </template>
        </v-snackbar>

      <Footer v-if="!($route.name.includes('Canvas') || $route.name.includes('Template'))"/>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/TheNavbar";
import Footer from "./components/TheFooter";
import {useStore} from '@/store/store'

export default {
  name: "App",
  setup(){
    const store = useStore()
    return {store}
  },
  components: {
    Navbar,
    Footer,
  },
  created(){
    function isInStandaloneMode() {
      return (window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches;
    }
    function isOnMobile(){
      return window.matchMedia("(pointer: coarse)").matches
    }
    this.store.promptPWAInstall = false
    if (isOnMobile() && !isInStandaloneMode()){
      this.store.promptPWAInstall = true
    }
  },
  methods: {
    handleSnackBarClose(){
      if (this.store.copy || this.store.moving){
        this.store.copy = false
        this.store.copiedData = null,
        this.store.startPastePoint = null,
        this.store.pastePoint = null
        this.store.moving = false
        this.store.moveStartPoint = null
        if (this.store.tools.draw.snappingIcon) this.store.tools.draw.snappingIcon.remove()
      }
      this.store.snackbarText = ''
      this.store.snackbarTimeOut = null
      this.store.snackbar = false
    }
  },

  data: () => ({
    //
  }),
};
</script>
<style scoped>

</style>
