class History {
  constructor(){
    this.history = []
    this.index = 0
    this.maxHistorySize = 30
  }

  add(command){
    this.history = this.history.slice(0, this.index)
    if (command.constructor.name == 'BatchCommand' && command.commands.length == 1){
      this.history.push(command.commands[0]) 
    }
    else this.history.push(command)

    // Limit the history size
    if (this.history.length > this.maxHistorySize) {
      this.history.shift()
    }
    this.index = this.history.length
  }
  undo(){
    if (this.index > 0){
      this.history[this.index-1].undo()
      this.index--
    }
  }
  redo(){
    if (this.index < this.history.length){
      this.index++
      this.history[this.index-1].redo()
    }
  }
  clear(){
    this.history = []
    this.index = 0
  }
}

export default new History()
