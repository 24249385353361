<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
    <rect width="256" height="256" fill="none"/>
    <path d="M24,128c104-221.7,104,221.7,208,0" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" :stroke-width="strokeWidth"/>
  </svg>
</template>

<script>
export default {
  name: 'waveIcon',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    },
    strokeWidth: {
      type: String,
      default: 30
    }
  }
}
</script>