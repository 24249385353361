<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1413.000000pt" height="1369.000000pt" viewBox="0 0 1413.000000 1369.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1369.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M170 6825 l0 -6665 6900 0 6900 0 0 6665 0 6665 -6900 0 -6900 0 0
-6665z m13350 0 l0 -6205 -6450 0 -6450 0 0 6205 0 6205 6450 0 6450 0 0
-6205z"/>
<path d="M2538 11353 c-2 -137 -2 -330 0 -430 l2 -183 405 0 405 0 0 430 0
430 -405 0 -404 0 -3 -247z"/>
<path d="M5400 11170 l0 -430 410 0 410 0 0 430 0 430 -410 0 -410 0 0 -430z"/>
<path d="M8265 11587 c-3 -6 -4 -199 -3 -427 l3 -415 408 -3 407 -2 0 430 0
430 -405 0 c-315 0 -407 -3 -410 -13z"/>
<path d="M11130 11170 l0 -430 410 0 410 0 -2 428 -3 427 -407 3 -408 2 0
-430z"/>
<path d="M2537 8488 c2 -134 3 -325 3 -425 l0 -183 405 0 405 0 0 425 0 425
-407 0 -408 0 2 -242z"/>
<path d="M5400 8305 l0 -425 410 0 410 0 0 425 0 425 -410 0 -410 0 0 -425z"/>
<path d="M8260 8305 l0 -425 410 0 410 0 0 425 0 425 -410 0 -410 0 0 -425z"/>
<path d="M11130 8305 l0 -425 410 0 410 0 0 425 0 425 -410 0 -410 0 0 -425z"/>
<path d="M2538 5623 c-2 -137 -2 -330 0 -430 l2 -183 405 0 405 0 0 430 0 430
-405 0 -404 0 -3 -247z"/>
<path d="M5400 5440 l0 -430 410 0 410 0 0 430 0 430 -410 0 -410 0 0 -430z"/>
<path d="M8265 5857 c-3 -6 -4 -199 -3 -427 l3 -415 408 -3 407 -2 0 430 0
430 -405 0 c-315 0 -407 -3 -410 -13z"/>
<path d="M11130 5440 l0 -430 410 0 410 0 0 430 0 430 -410 0 -410 0 0 -430z"/>
<path d="M2537 2758 c2 -134 3 -325 3 -425 l0 -183 405 0 405 0 0 425 0 425
-407 0 -408 0 2 -242z"/>
<path d="M5400 2575 l0 -425 410 0 410 0 0 425 0 425 -410 0 -410 0 0 -425z"/>
<path d="M8260 2575 l0 -425 410 0 410 0 0 425 0 425 -410 0 -410 0 0 -425z"/>
<path d="M11130 2575 l0 -425 410 0 410 0 0 425 0 425 -410 0 -410 0 0 -425z"/>
</g>
</svg>
</template>

<script>
export default {
  name: 'GridOn',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>