<template>
  <div class="body d-flex justify-center align-center">
    <div style="text-align: center">
      <h3>404</h3>
      <h3>Page Not Found</h3>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
.body {
  background: white;
  background-image: radial-gradient(rgb(194, 194, 194) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  height: 100%;
}
</style>
