<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
    <path d="M1840 5103 c-19 -10 -45 -33 -57 -52 l-23 -34 0 -502 0 -502 23 -34
    c14 -21 41 -41 69 -53 42 -17 52 -18 89 -7 54 16 94 57 109 109 7 29 10 188 8
    509 -3 447 -4 470 -23 502 -43 72 -126 100 -195 64z"/>
    <path d="M3055 5106 c-39 -17 -64 -46 -81 -93 -17 -49 -20 -925 -3 -987 14
    -51 55 -91 108 -107 37 -11 47 -10 89 7 28 12 55 32 69 53 l23 34 0 502 0 502
    -23 34 c-40 61 -116 84 -182 55z"/>
    <path d="M2455 4806 c-37 -17 -70 -52 -84 -89 -7 -19 -11 -188 -11 -494 0
    -514 0 -510 64 -556 43 -31 129 -31 172 0 66 47 65 41 62 572 -3 467 -4 480
    -24 507 -47 64 -119 88 -179 60z"/>
    <path d="M2005 3305 c-382 -71 -721 -353 -983 -820 -189 -336 -295 -743 -279
    -1065 13 -253 73 -436 198 -603 68 -91 154 -159 270 -215 92 -44 97 -49 112
    -92 44 -122 161 -265 282 -345 210 -138 507 -192 832 -150 237 31 448 97 688
    215 643 317 992 814 926 1314 -34 252 -176 501 -372 652 -117 90 -305 172
    -434 189 -107 14 -103 12 -110 63 -49 354 -246 626 -564 779 -163 78 -394 110
    -566 78z"/>
    <path d="M3570 2850 c-47 -10 -113 -30 -147 -45 l-62 -27 22 -60 22 -59 88
    -30 c48 -16 104 -37 125 -48 28 -14 51 -17 94 -14 127 11 281 -41 387 -132 84
    -72 129 -82 202 -44 43 22 78 80 79 130 0 76 -109 182 -266 260 -173 85 -359
    109 -544 69z"/>
  </g>
  </svg>
</template>

<script>
export default {
  name: 'gravityIcon',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>