export const layers = [
  {
    name: "image",
    visible: true
  },
  {
    name: "imageDims",
    visible: true
  },
  {
    name: "grid",
    visible: true
  },
  {
    name: "imageBoundingRect",
    visible: true,
  },
  {
    name: "imageHandles",
    visible: true
  },
  {
    name: "selection",
    visible: true
  },
  {
    name: "drawing",
    visible: true
  },
  {
    name: "support",
    visible: true
  },
  {
    name: "node",
    visible: true
  },
  {
    name: "load",
    visible: true
  },
  {
    name: "loadValues",
    visible: true
  },
  {
    name: "deflection",
    visible: true
  },
  {
    name: "moment",
    visible: false
  },
  {
    name: "shear",
    visible: false
  },
  {
    name: "axial",
    visible: false
  },
  {
    name: "mode",
    visible: false
  },
  {
    name: 'dimension',
    visible: false
  },
  {
    name: "deflectionValues",
    visible: false
  },
  {
    name: "shearValues",
    visible: false
  },
  {
    name: "momentValues",
    visible: false
  },
  {
    name: "axialValues",
    visible: false
  },
  {
    name: "reaction",
    visible: true
  },
  {
    name: "utilization",
    visible: false
  },
  {
    name: "utilizationValues",
    visible: false
  },
  { 
    name: "memberSizes",
    visible: true
  },
  {
    name: "release",
    visible: true
  },
  {
    name: "other",
    visible: true
  }
]