import paper from 'paper'
import * as utils from '../utilities/Utils.js'
import * as styles from '../styles/paperStyles.js'
import {useStore} from '@/store/store.js'
import History from '@/utilities/History.js'
import { LoadCommand, BatchCommand } from "@/utilities/Commands.js"

export class Loads{
  store = useStore()
  constructor(){
    this.elements = this.store.drawingLayer.children
    this.closestPoint = null
    this.lineToSplit = null
    this.pointIcon = this.newArrow()
    this.pointIcon.visible = false
    this.lineIcon = this.newLineIcon()
    this.hitElement = null
    this.hitOptions = {
      stroke: true,
      fill: true,
      curves: true,
      tolerance: 10,
    }
    this.subToolPoint = 'pointDown',
    this.subToolLine = 'lineDown'
    this.subTool = null
  }
  lineOnMouseDown = (e) => {
    if (this.store.onMobile){
      var hitResult = this.store.drawingLayer.hitTest(e.point, this.hitOptions)
      if (hitResult)
        this.hitElement = hitResult
    }
    if (this.hitElement){
      let direction = this.subToolLine.split('line')[1]
      let isLineMass = this.subTool=="Line Mass" ? true : false
      let loadGroup = this.scaleLineLoad(this.hitElement.item, this.store.lineLoad, direction, isLineMass)
      this.addLoadText(loadGroup)
      History.add(new LoadCommand(loadGroup, true))
    }
    else {
      this.store.snackbarText = 'Loads can only be placed on frame elements'
      this.store.snackbar = true
    }
  }
  translateLoad(existingLoads, loadToMove, placement){
    //let magnitude = existingLoad.data.magnitude
    // let translation = this.getTranslationPixels(magnitude)
    let loadsSameDirection = []

    existingLoads.forEach(existingLoad => {
      if ((loadToMove.data.dir == 'Up' || loadToMove.data.dir == 'Down') && 
      (existingLoad.data.dir == 'Up' || existingLoad.data.dir == 'Down')){
        insertIntoSortedArray(loadsSameDirection, existingLoad, 'y')
      }
      else if (loadToMove.data.dir == 'Right' && existingLoad.data.dir == 'Right'){
        loadsSameDirection.push(existingLoad)
      }
      else if (loadToMove.data.dir == 'Left' && existingLoad.data.dir == 'Left'){
        loadsSameDirection.push(existingLoad)
      }
      else {
        //Loads not in same direction --> no translation
      }
    })

    function insertIntoSortedArray(arr, newValue, dir){
      let index = 0
      while (index < arr.length && arr[index].bounds.center[dir] < newValue){
        index++
      } 
      arr.splice(index, 0, newValue)
    }

    // if ((loadToMove.data.dir == 'Up' || loadToMove.data.dir == 'Down') && 
    //   (existingLoad.data.dir == 'Up' || existingLoad.data.dir == 'Down')){

    //   loadToMove.translate({x:0, y:-translation})
    // }
    // else if (loadToMove.data.dir == 'Right' && existingLoad.data.dir == 'Right'){
    //   loadToMove.translate({x: -translation, y:0})
    // }
    // else if (loadToMove.data.dir == 'Left' && existingLoad.data.dir == 'Left'){
    //   loadToMove.translate({x: translation, y:0})
    // }
    // else {
    //   //Loads not in same direction --> no translation
    // }
  }
  getTranslationPixels(magnitude){
    let translation = 20+(magnitude/10)*4
    return Math.min(translation, 200)
  }
  lineOnMouseMove = (e) => {
    var hitResult = this.store.drawingLayer.hitTest(e.point, this.hitOptions)
    if (hitResult){
      this.lineIcon.strokeColor = this.subTool.includes('Mass') ? styles.massColor : styles.loadColor
      this.lineIcon.setPosition(e.point)
      this.hitElement = hitResult
    }
    else {
      this.lineIcon.strokeColor = styles.disabledColor
      if (this.subToolLine.includes("Up") || this.subToolLine.includes("Down")) this.lineIcon.setPosition({x: e.point.x, y: e.point.y - this.lineIcon.bounds.height/2})
      else {
        if (this.store.lineLoad > 0) this.lineIcon.setPosition({x: e.point.x - this.lineIcon.bounds.width/2, y: e.point.y})
        else this.lineIcon.setPosition({x: e.point.x + this.lineIcon.bounds.width/2, y: e.point.y})
      }
      this.lineIcon.data.curPos = e.point
    }
  }
  pointOnMouseMove = (e) => {
    this.closestPoint = null
    this.findClosestLineAndPoint(e)
    this.drawPointIcon(e)
  }
  pointOnMouseDown = (e) =>{
    if (this.store.onMobile){
      this.findClosestLineAndPoint(e)
    }
    if (this.closestPoint){
      let direction = this.subToolPoint.split('point')[1]
      let pointLoad = this.scalePointLoad(this.closestPoint, this.store.pointLoad, direction)
      let batchCommand = new BatchCommand()
      if (this.lineToSplit){
        let dist1 = utils.distance2Points(this.lineToSplit.segments[0].point, this.closestPoint)
        let dist2 = utils.distance2Points(this.lineToSplit.segments[1].point, this.closestPoint)
        if (dist1 < 1 || dist2 < 1) { 
          //do nothing 
        }
        else this.store.tools.draw.hitAndSplit(this.closestPoint, this.lineToSplit, batchCommand)
      }
      this.closestPoint = null
      this.lineToSplit = null
      this.addLoadText(pointLoad)
      batchCommand.add(new LoadCommand(pointLoad, true))
      History.add(batchCommand)
    }
    else {
      this.store.snackbarText = 'Loads can only be placed on frame elements'
      this.store.snackbar = true
    }
  }
  findClosestLineAndPoint(e){
    this.lineToSplit = null
    const [line, point] = utils.getClosestLinePoint(e.point, 10/this.store.grid.canvasZoom)
    if (point){this.closestPoint = point}
    if (line){this.lineToSplit = line}
  }
  scalePointLoad(insertionPoint, magnitude, direction){
    var arrow = this.rotate(this.newArrow(), direction)
    arrow.strokeWidth = 3/this.store.grid.canvasZoom
    let translation = this.getTranslationPixels(magnitude)
    arrow.scale((translation/20)/this.store.grid.canvasZoom)
    arrow.setPosition(insertionPoint)
    this.translate(arrow, direction)

    arrow.strokeColor = styles.loadColor
    arrow.data = {
      layer: this.store.loadLayer.name,
      type: 'Point Load',
      dir: direction,
      location: insertionPoint,
      magnitude: magnitude
    }
    this.store.loadLayer.addChild(arrow)
    return arrow
  }
  scaleLineLoad(element, magnitude, inputDirection, lineMass=false){
    var tempDirection
    if (inputDirection) {tempDirection = inputDirection}
    else {tempDirection = this.subTool}
    let existingLoads = utils.findAssociatedLoads(element)
    var upperBar = element.clone()
    upperBar.data = null
    upperBar.dashArray=null
    let translation = this.getTranslationPixels(magnitude)/this.store.grid.canvasZoom
    if (tempDirection.includes('Up') || tempDirection.includes('Down') || tempDirection.includes('Mass')){
      upperBar.translate({x:0, y:-translation})
    }
    else if (tempDirection.includes('Right')) {
      upperBar.translate({x:-translation, y:0})
    } 
    else{
      upperBar.translate({x:translation, y:0})
    }
    
    var loadGroup = new paper.Group()
    let arrowWidth = 10*translation/20 + 10
    var numSegments = Math.ceil(element.curves[0].length/arrowWidth)
    var distancebetweenSegments = element.curves[0].length/numSegments
    for (let i = 0; i < numSegments+1; i++){
      var arrow = this.newArrow()
      arrow.strokeWidth = 2/this.store.grid.canvasZoom
      var arrowRotated = this.rotate(arrow, inputDirection)
      arrowRotated.scale(translation/20, arrowRotated.bounds.center)
      var arrowPosition = element.curves[0].getLocationAt(i*distancebetweenSegments).point
      if (arrowPosition > element.curves[0].length){
        arrowPosition = element.segments[1].point
      }
      arrowRotated.setPosition({x: arrowPosition.x, y: arrowPosition.y})
      this.translate(arrowRotated, inputDirection)
      loadGroup.addChild(arrowRotated)
    }
    loadGroup.addChild(upperBar)
    loadGroup.strokeColor = lineMass ? styles.massColor : styles.loadColor
    loadGroup.data = {
      layer: this.store.loadLayer.name,
      type: lineMass ? 'Line Mass' : 'Line Load',
      dir: inputDirection,
      elementId: element.id,
      magnitude: magnitude,
    }

    //Translate load so they don't sit on top of each other if more than one per line element
    // if (existingLoads.length > 0){
    //   this.translateLoad(existingLoads, loadGroup)
    // }

    this.store.loadLayer.addChild(loadGroup)
    return loadGroup
  }
  translate(arrow, dir){
    if (dir.includes("Right")){
      arrow.translate({x: -arrow.bounds.width/2, y: 0})
    }
    else if (dir.includes("Left")){
      arrow.translate({x: arrow.bounds.width/2, y: 0}) 
    }
    else {
      arrow.translate({x: 0, y: -arrow.bounds.height/2})
    }
  }
  drawPointIcon(e){
    this.getInsertionPoint(e)
    this.pointIcon.strokeColor = this.closestPoint ? styles.loadColor : styles.disabledColor
  }
  getInsertionPoint(e){
    var loadPoint
    let referencePoint = this.closestPoint ? this.closestPoint : e.point 

    if (this.subToolPoint.includes("Up") || this.subToolPoint.includes("Down")){
      loadPoint = {x: referencePoint.x, y: referencePoint.y-this.pointIcon.bounds.height/2}
    }
    else if (this.subToolPoint.includes('Right')){
      loadPoint = {x: referencePoint.x-this.pointIcon.bounds.width/2, y: referencePoint.y}
    }
    else {
      loadPoint = {x: referencePoint.x+this.pointIcon.bounds.width/2, y: referencePoint.y}
    }
    this.pointIcon.setPosition(loadPoint)
  }
  activate(subTool){
    if (subTool.includes('point')){
      this.subTool = 'point'
      this.subToolPoint = subTool
      let direction = subTool.split('point')[1]
      var arrow = this.newArrow()
      this.scaleInitial(arrow)
      this.pointIcon = this.rotate(arrow, direction)
      this.pointIcon.visible = true
      this.pointOnMouseDown = this.pointOnMouseDown.bind(this)
      this.store.canvas.tool.onMouseDown = this.pointOnMouseDown
      if (!this.store.onMobile)
        this.store.canvas.tool.onMouseMove = this.pointOnMouseMove
    }
    if (subTool.includes('line')){
      let direction = "down"
      if (subTool == 'lineMass'){
        this.subTool = 'Line Mass'
        this.subToolLine = 'lineMass'
      }
      else {
        this.subTool = 'line'
        this.subToolLine = subTool
        direction = subTool.split('line')[1]
      }
      let lineIcon = this.newLineIcon()
      this.scaleInitial(lineIcon)
      this.lineIcon = this.rotate(lineIcon, direction)
      this.lineIcon.visible = true
      this.store.canvas.tool.onMouseDown = this.lineOnMouseDown
      if (!this.store.onMobile)
        this.store.canvas.tool.onMouseMove = this.lineOnMouseMove
    }
  }
  scaleInitial(load){
    load.scale(1/this.store.grid.canvasZoom)
    load.children.forEach(child => {
      child.strokeWidth = 2/this.store.grid.canvasZoom
    })
  }
  rotate(item, dir){    
    if (dir.includes("Up")){
      item.rotate(180)                
    }
    else if (dir.includes('Right')){
      item.rotate(-90)
    }
    else if (dir.includes('Left')){
      item.rotate(90)
    }
    else {}
    return item
  }
  addLoadText(load){
    var textPoint = this.getLoadTextLocation(load)
    var text = new paper.PointText()
    text.style = styles.loadValue
    text.content = Math.abs(load.data.magnitude)
    text.scale(1/this.store.grid.canvasZoom)
    text.position = {x: textPoint.x, y: textPoint.y}
    text.data.loadId = load.id
    this.store.loadValuesLayer.addChild(text)
    return text
  }
  getLoadTextLocation(load){
    //line load text location
    var textLocation
    var loadDirection
    if (load.data.type.includes('Line')){
      var loadItems = load.children
      //find direction of load
      for (let i = 0; i < loadItems.length; i++){
        var loadItem = loadItems[i]
        if (loadItem.className == "Group"){
          loadDirection = loadItem.children[0].segments[1].point.subtract(loadItem.children[0].segments[0].point).normalize()
          break
        }
      }
      //determine location of text for line load
      for (let i = 0; i < loadItems.length; i++){
        var loadItem = loadItems[i]
        if (loadItem.className == "Path"){
          var midPoint = loadItem.curves[0].getLocationAtTime(0.5).point
          if (loadDirection.y != 0){
            textLocation = {x: midPoint.x, y:midPoint.y-10/this.store.grid.canvasZoom}
            break
          }
          if (loadDirection.x > 0){
            textLocation = {x: midPoint.x - 10/this.store.grid.canvasZoom, y:midPoint.y}
            break
          }
          if (loadDirection.x < 0){
            textLocation = {x: midPoint.x + 10/this.store.grid.canvasZoom, y:midPoint.y}
            break
          }
          return textLocation
        }
      }
    }
    //point load text location - place at end of arrow 
    else{
      var textLocation
      if (load.data.dir == "Up") {
        let vector = load.children[0].segments[1].point.subtract(load.children[0].segments[0].point)
        textLocation = load.children[0].segments[1].point.add(vector.normalize().multiply(10/this.store.grid.canvasZoom))
      }
      else if (load.data.dir == "Down"){
        let vector = load.children[0].segments[1].point.subtract(load.children[0].segments[0].point)
        textLocation = load.children[0].segments[0].point.subtract(vector.normalize().multiply(10/this.store.grid.canvasZoom))
      }
      else{
        let vector = load.children[0].segments[0].point.subtract(load.children[0].segments[1].point)
        textLocation = load.children[0].segments[0].point.add(vector.normalize().multiply(10/this.store.grid.canvasZoom))
      }
    }
    return textLocation
  }
  updateLoadText(load){
    var loadTextToUpdate = this.store.loadValuesLayer.children.find(text => text.data.loadId == load.id)
    loadTextToUpdate.position = load.bounds.topCenter
    loadTextToUpdate.content = load.data.magnitude
  }
  deactivate(){
    this.lineIcon.visible = false
    this.pointIcon.visible = false
    this.store.canvas.tool.onMouseDown = null
    this.store.canvas.tool.onMouseMove = null
  }
  newArrow(){
    var arrowPart = new paper.Path({x: 0, y: 0}, {x: 0, y: 20})
    arrowPart.add({x: 3, y: 15})
    arrowPart.strokeJoin = "round"
    var arrowPart2 = new paper.Path({x: 0, y: 20}, {x: -3, y: 15})
    var arrowGroup = new paper.Group(arrowPart, arrowPart2)
    if (this.subToolPoint){
      let direction = this.subToolPoint.split('point')[1]
      arrowGroup.data = {
        type: "Point Load",
        dir: direction
      }
    }
    return arrowGroup
  }
  newLineIcon(){
    var upperBar = new paper.Path({x: 0, y:0}, {x:25, y:0})
    upperBar.strokeWidth = 2
    var arrow1 = this.newArrow()
    arrow1.setPosition({x: 0, y:10})
    var arrow2 = this.newArrow()
    arrow2.setPosition({x: 12.5, y:10})
    var arrow3 = this.newArrow()
    arrow3.setPosition({x: 25, y:10})
    var lineLoadIcon = new paper.Group([upperBar, arrow1, arrow2, arrow3])
    if (this.subToolLine){
      lineLoadIcon.data = {
        type: "Line Load",
        dir: this.subToolLine.split('line')[1]
      }
    }
    return lineLoadIcon
  }
}