import * as styles from "@/styles/paperStyles.js"
import { useStore } from "@/store/store.js"
import * as utils from "@/utilities/Utils.js"
import paper from "paper"
import * as symbols from "@/constants/Symbols.js"
import * as commands from "@/utilities/Commands.js"
import { findNodesSameLocation, rgbToHex } from "./Utils"

export function remove(item){
  const store = useStore()
  let allCommands = []
  var layer = item.data.layer
  let text = utils.findAssociatedText(item)
  if (text) text.remove()
  if (layer == "drawing"){
    let releasesToDelete = store.releaseLayer.children.filter(release => 
      release.data.elementId == item._id)
    releasesToDelete.forEach(release => {
      release.remove()
    })
    let nodesToRemove = store.nodeLayer.children.filter(node => 
      node.data.lineId == item._id)
    nodesToRemove.forEach(node => {
      let nodesSameLocation = utils.findNodesSameLocation(node)
      nodesSameLocation.forEach(node => node.strokeColor = 'black')
      node.remove()
    })
    let dimensionToRemove = store.dimensionLayer.children.filter(dim =>
      dim.data.elementId == item._id
    )
    dimensionToRemove.forEach(dim => dim.remove())
    let loadsToDelete = store.loadLayer.children.filter(load => 
      load.data.type == "Line Load" && load.data.elementId == item._id
    )
    loadsToDelete.forEach(load => {
      let loadText = utils.findAssociatedText(load)
      loadText.remove()
      allCommands.push(new commands.LoadCommand(load, false))
      load.remove()
    })
  }
  else if (layer =='load'){
    let loads = utils.findAssociatedLoads(item)
    loads.forEach(load => {
      let loadText = utils.findAssociatedText(load)
      loadText.remove()
      allCommands.push(new commands.LoadCommand(load, false))
      load.remove()
    })
  }
  allCommands.push(commands.removeCommandHelper(item))
  item.remove()
  if (item == store.selectedItem) store.selectedItem = null
  return allCommands
}

export function textRotation(path){
  var rotation
  var [leftPoint, rightPoint] = utils.reorientLine(path)

  if (leftPoint.x != rightPoint.x && leftPoint.y != rightPoint.y){
    rotation = Math.atan(Math.abs(leftPoint.y - rightPoint.y)/(Math.abs(leftPoint.x - rightPoint.x))) * (180/Math.PI)
    if (leftPoint.y > rightPoint.y) rotation = rotation*-1
  }
  else if (leftPoint.y != rightPoint.y) rotation = 270
  else rotation = 0
  return rotation
}

export function drawReleaseIcon(element, endPoint){
  const store = useStore()
  let releasePosition = getReleasePosition(element, endPoint)
  var circle = new paper.Path.Circle(releasePosition, 5/store.grid.canvasZoom)
  circle.data.elementId = element._id
  circle.data.end = endPoint
  circle.data.layer = 'release'
  circle.strokeWidth = 1/store.grid.canvasZoom
  store.releaseLayer.addChild(circle)
  if (store.selectedItems.map(i => i._id).includes(element._id)) circle.strokeColor = styles.highlightColor
  else circle.strokeColor = styles.releaseColor
  return circle
}

export function getReleasePosition(line, endPoint){
  var endPoints = utils.reorientLine(line)
  var releaseEnd
  var otherEnd
  if (endPoint == 'start'){
    releaseEnd = endPoints[0];
    otherEnd = endPoints[1]
  } 
  else {
    releaseEnd = endPoints[1];
    otherEnd = endPoints[0]
  }
  var vector = releaseEnd.subtract(otherEnd)
  var offsetVector = vector.normalize().multiply(.10*line.length)
  var releasePosition = releaseEnd.subtract(offsetVector)
  console.log(offsetVector, vector, line.length)
  return releasePosition
}

export function highlight(items){
  const store = useStore()
  if (items == null) items = []
  if (!Array.isArray(items)){items = [items]}

  items.forEach(item => {
    if (isHighlighted(item)) return 

    item.strokeColor = styles.highlightColor
    let elementText = utils.findAssociatedText(item)
    if (elementText){
      elementText.fillColor = styles.highlightColor
    }
    if (item.data.layer == 'drawing'){
      store.releaseLayer.children.forEach(release => {
        if (release.data.elementId == item._id) release.strokeColor = styles.highlightColor
      })
      store.nodeLayer.children.forEach(node => {
        if (node.data.lineId == item._id) {
          let nodesToHighlight = utils.findNodesSameLocation(node)
          nodesToHighlight.forEach(nodeToHighlight => {
            nodeToHighlight.strokeColor = styles.highlightColor
          })
        }
      })
    }
  })
}

export function unhighlight(items){
  if (items == null) items = []
  if (!Array.isArray(items)){items = [items]}
  
  items.forEach(item => {
    revertOriginalColor(item)
  })
}

export function isHighlighted(item){
  if (item.strokeColor && (rgbToHex(item.strokeColor.components) == styles.highlightColor)) return true
  else return false
}

export function selectionControl(items, selectedItems){
  if (items == null) items = []
  if (!Array.isArray(items)) items = [items]

  var itemsToAdd = []
  var itemsToRemove = []

  let selectedIds = selectedItems.map(s => s.id)
  let itemIds = items.map(i => i.id)

  items.forEach(item => {
    if (!selectedIds.includes(item.id)){
      itemsToAdd.push(item)
    }
  })
  selectedItems.forEach(selectedItem => {
    if (!itemIds.includes(selectedItem.id)){
      itemsToRemove.push(selectedItem)
    }
  })
  return [itemsToAdd, itemsToRemove, items]
}
export function removeSelection(){
  const store = useStore()
  if (store.selectedItems.length > 0){
    unhighlight(store.selectedItems)
    store.selectedItems = []
  }
}

export function revertOriginalColor(item){
  const store = useStore()
  if (!isHighlighted(item)) return 
  switch (item.data.layer){
    case 'drawing':
      item.strokeColor = styles.line().strokeColor
      store.releaseLayer.children.forEach(release => {
        if (release.data.elementId == item._id) release.strokeColor = styles.releaseColor
      })
      store.nodeLayer.children.forEach(node => {
        if (node.data.lineId == item._id) {
          let nodes = utils.findNodesSameLocation(node)
          nodes.forEach(node => node.strokeColor = 'black')
        }
      })
      break;
    case 'support':
      item.strokeColor = styles.supportIcon.strokeColor
      break;
    case 'load':
      if (item.data.type == "Line Mass"){
        item.strokeColor = styles.massColor
      }
      else {
        item.strokeColor = styles.loadColor
      }
      break;
  }

  let elementText = utils.findAssociatedText(item)
  if (elementText){
    elementText.fillColor = styles.textColor
  }
}