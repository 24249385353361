<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="5 -10 32 36">
    <g stroke="#696969" stroke-linecap="round" stroke-width="1">
      <text x="14" y="2" font-size="0.8em"  stroke-width="0.3">z</text>
      <line x1="10" x2="10" y1="0" y2="20" />
      <line x1="10" x2="14" y1="0" y2="6" />
      <line x1="10" x2="6" y1="0" y2="6" />
      <line x1="10" x2="30" y1="20" y2="20" />
      <line x1="30" x2="26" y1="20" y2="24" />
      <line x1="30" x2="26" y1="20" y2="16" />
      <text x="32" y="18" font-size="0.8em" stroke-width="0.3">x</text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'coordinatesIcon'
}
</script>