import { create, ShapeDiverResponseOutputDefinition, ShapeDiverRequestCustomization, ShapeDiverResponseOutput} from "@shapediver/sdk.geometry-api-sdk-v2"
import { apiService } from '../plugins/apiPlugin';

/**
 * Runs a computation and an export for a ShapeDiver Geometry Backend Model, which is 
 * supposed to fulfill the following assumptions: 
 * 
 *   * has a parameter of type "File" which supports images
 *   * has a parameter of type "String"
 *   * has a data output whose lowercase name contains "text"
 *   * has a downloadable export which is supposed to export an image
 * 
 */
export const runModel = async (input) => {

  /**
   * Create instance of ShapeDiver Geometry Backend SDK and open session for model
   */
  let config = await apiService({
    url: "/api/shapediver/_shapediver/get-config-keys",
    method: "GET"
  })

  const sdk = create(config.data.modelViewUrl)
  let dto
  try {
    dto = await sdk.session.init(config.data.ticket)
  } catch(error) {
    throw new Error('Unable to instantiate ShapeDiver session')
  } 
  

  // find text parameter
  const paramText1 = Object.keys(dto.parameters)
    .map( id => dto.parameters[id])
    .find( p => p.type === "String" && p.name.includes('text1'))
  const paramText2 = Object.keys(dto.parameters)
    .map( id => dto.parameters[id])
    .find( p => p.type === "String" && p.name.includes('text2'))
  const paramText3 = Object.keys(dto.parameters)
    .map( id => dto.parameters[id])
    .find( p => p.type === "String" && p.name.includes('text3'))
  const paramText4 = Object.keys(dto.parameters)
    .map( id => dto.parameters[id])
    .find( p => p.type === "String" && p.name.includes('text4'))
  const paramText5 = Object.keys(dto.parameters)
    .map( id => dto.parameters[id])
    .find( p => p.type === "String" && p.name.includes('text5'))


  // Get all data outputs
  const messages = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("messages") )
  if (!messages) {
    throw new Error("Could not find a output whose name includes 'messages'")
  }

  const elements = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("elements") )
  if (!elements) {
    throw new Error("Could not find a output whose name includes 'elements'")
  }

  const reactions = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("reactions") )
  if (!reactions) {
    throw new Error("Could not find a output whose name includes 'reactions'")
  }

  const maxValues = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("maxvalues") )
  if (!maxValues) {
    throw new Error("Could not find a output whose name includes 'maxValues'")
  }

  const mode1 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode1") )
  if (!mode1) {
    throw new Error("Could not find a output whose name includes 'mode1'")
  }

  const mode2 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode2") )
  if (!mode2) {
    throw new Error("Could not find a output whose name includes 'mode2'")
  }

  const mode3 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode3") )
  if (!mode3) {
    throw new Error("Could not find a output whose name includes 'mode3'")
  }
  
  const mode4 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode4") )
  if (!mode4) {
    throw new Error("Could not find a output whose name includes 'mode4'")
  }

  const mode5 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode5") )
  if (!mode5) {
    throw new Error("Could not find a output whose name includes 'mode5'")
  }

  const mode6 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode6") )
  if (!mode6) {
    throw new Error("Could not find a output whose name includes 'mode6'")
  }

  const mode7 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode7") )
  if (!mode7) {
    throw new Error("Could not find a output whose name includes 'mode7'")
  }

  const mode8 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode8") )
  if (!mode8) {
    throw new Error("Could not find a output whose name includes 'mode8'")
  }

  const mode9 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode9") )
  if (!mode9) {
    throw new Error("Could not find a output whose name includes 'mode9'")
  }

  const mode10 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode10") )
  if (!mode10) {
    throw new Error("Could not find a output whose name includes 'mode10'")
  }

  const mode11 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode11") )
  if (!mode11) {
    throw new Error("Could not find a output whose name includes 'mode11'")
  }

  const mode12 = Object.keys(dto.outputs)
    .map( id => dto.outputs[id])
    .find( o => o.name.toLowerCase().includes("mode12") )
  if (!mode12) {
    throw new Error("Could not find a output whose name includes 'mode12'")
  }

  /**
   * Preparations for sending the customization request
   */

  // check length of input text
  // if ( paramText.max < input.text.length ) {
  //     throw new Error (`The text input parameter does not accept strings whose size exceeds ${paramText.max} (${input.text.length})`)
  // }
  
  /**
   * Customization and export request
   */

  // send and wait for customization request
  const customizationBody = {
    [paramText1.id]: input.text1,
    [paramText2.id]: input.text2,
    [paramText3.id]: input.text3,
    [paramText4.id]: input.text4,
    [paramText5.id]: input.text5,
  }
  let customizationResult
  try {
    customizationResult = await sdk.utils.submitAndWaitForCustomization(sdk, dto.sessionId, customizationBody)
  } catch (error) {
    throw new Error('Unable to make ShapeDiver Compute')
  }
  
  // check customization result
  var customizationResultContent = {}
  customizationResultContent['messages'] = customizationResult.outputs[messages.id].content[0].data
  customizationResultContent['elements'] = customizationResult.outputs[elements.id].content[0].data.elements
  customizationResultContent['reactions'] = customizationResult.outputs[reactions.id].content[0].data.reactions
  customizationResultContent['maxValues'] = customizationResult.outputs[maxValues.id].content[0].data.maxValues
  customizationResultContent['modes'] = [
    customizationResult.outputs[mode1.id].content[0].data,
    customizationResult.outputs[mode2.id].content[0].data,
    customizationResult.outputs[mode3.id].content[0].data,
    customizationResult.outputs[mode4.id].content[0].data,
    customizationResult.outputs[mode5.id].content[0].data,
    customizationResult.outputs[mode6.id].content[0].data,
    customizationResult.outputs[mode7.id].content[0].data,
    customizationResult.outputs[mode8.id].content[0].data,
    customizationResult.outputs[mode9.id].content[0].data,
    customizationResult.outputs[mode10.id].content[0].data,
    customizationResult.outputs[mode11.id].content[0].data,
    customizationResult.outputs[mode12.id].content[0].data,
  ]
  
  /**
   * Return results
   */
  return customizationResultContent
}

