<template>
  <div class="body d-flex justify-center">
    <div v-if="!loading" class="d-flex flex-column">
      <div class="d-flex justify-center my-12">
        <v-avatar v-if="user && user.email" color="green" size="75" v-bind="props">
          <span class="text-white text-h5">{{user.email.slice(0,2)}}</span>
        </v-avatar>
        <div class="d-flex flex-column ml-5 justify-center">
          <span class="text-h6">{{user.name ? user.name : user.email}}</span>
          <span v-if="user.name" style="margin-top:-0px"> {{user.email}}</span>
          <v-btn v-if="!user.emailLowerCase.includes('thorntontomasetti')" 
            variant="outlined" size="x-small" color="purple" width="100"
            @click="editProfileClick">
            Edit Profile
          </v-btn>
        </div>
      </div>

      <!-- <div class="d-flex flex-wrap mt-8 justify-center">
        <div class="d-flex flex-column">
          <span class="text-h6 font-weight-bold">Your Plan</span>
          <div v-if="user.plan == 0" class="mb-4">
            <span v-if="computeLog.totalComputes < 25" >Computes Used: <span class="font-weight-bold text-orange">{{computeLog.totalComputes}}</span></span>
            <span v-else class="text-red font-weight-bold">You've used up all your computes!</span>
          </div>
          <br v-else>
          <FreePlan v-if="user.plan==0" :btnColor="'black'" :btnText="'Your Plan'" :disabled="true"></FreePlan>
          <StudentPlan v-else-if="user.plan==1" class="mt-4" :btnColor="'grey'" :btnText="'Go To Billing'" @selected="goToBilling()"></StudentPlan>
          <IndividualPlan v-else-if="user.plan==2" class="mt-4" :btnColor="'grey'" :btnText="'Go To Billing'" @selected="goToBilling()"></IndividualPlan>
          <EnterpriseSelected v-else class="mt-4" :planName="user.plan"></EnterpriseSelected>
        </div>
        <template v-if="!user.plan.toString().includes('Enterprise')">
          <v-divider vertical thickness="2" class="border-opacity-95 mx-5" ></v-divider>
          <div class="d-flex flex-column mb-12">
            <v-spacer></v-spacer>
            <span class="text-h6 d-flex">Change Plan</span>
            <br>
            <div class="d-flex flex-wrap mt-4">
              <div class="d-flex flex-column mr-5" v-if="user.isStudent">
                <StudentPlan :btnText="'Select'" @selected="selectPlan(1)"></StudentPlan>
              </div>
              <div class="d-flex flex-column mr-5">
                <IndividualPlan v-if="user.plan != 2" :btnText="'Select'" @selected="selectIndividual()"></IndividualPlan>
              </div>
              <div class="d-flex flex-column">
                <EnterprisePlan :btnText="'Select'"></EnterprisePlan>
              </div>
            </div>
          </div>
        </template>
      </div> -->
    </div>
  </div>

  <v-dialog v-model="editProfile" width=600>
    <v-card class="pa-3">
      <v-card-title>EDIT PROFILE</v-card-title>
        <v-card-item>
          <v-text-field
            class="px-4 mt-2" 
            label="Name" 
            variant="outlined"
            v-model="userCopy.name"
            hide-details
          ></v-text-field>
        </v-card-item>
        <v-card-item>
          <v-text-field
            class="px-4 mt-2" 
            label="Organization" 
            variant="outlined"
            v-model="userCopy.organization"
            hide-details
          ></v-text-field>
        </v-card-item>
        <v-card-item class="px-10">
          <v-btn block color="primary" class="mb-6" @click="saveProfileInfo" :loading="saveInfoLoading">
            <span class="text-white">Save</span>
          </v-btn>
        </v-card-item>
    </v-card>
  </v-dialog>
</template>

<script>
import {useStore} from '@/store/store'
import FreePlan from '@/components/plans/Free.vue'
import StudentPlan from '@/components/plans/Student.vue'
import IndividualPlan from '@/components/plans/Individual.vue'
import EnterprisePlan from '@/components/plans/Enterprise.vue'
import EnterpriseSelected from '@/components/plans/EnterpriseSelected.vue'
import {selectPlan, goToBilling } from '@/composables/billing.js'

export default {
  components: {FreePlan, StudentPlan, IndividualPlan, EnterprisePlan, EnterpriseSelected},
  setup(){
    const store = useStore()
    return {
      store,
      selectPlan,
      goToBilling
    }
  },
  data() {
    return {
      user: null,
      userCopy: null,
      computeLog: null,
      loading: false,
      saveInfoLoading: false,
      editProfile: false,
    };
  },
  async created(){
    this.loading = true
    this.user = await this.store.getUser()
    this.computeLog = await this.store.getComputeLog()
    this.loading = false
    this.saveInfoLoading = false
  },
  computed: {
  },
  watch: {
    "store.user": function(){
      //redirect user out of account page if logged out
    }
  },
  async mounted() {

  },
  computed: {},
  methods: {
    async saveProfileInfo(){
      this.saveInfoLoading = true
      let updated = await this.$api({
        url: "/api/user/_user/update-user",
        method: "POST",
        data: {
          user: this.userCopy
        }
      })
      if (updated.data){
        await this.store.setUser(updated.data)
        this.user.name = updated.data.name
        this.user.organization = updated.data.organization
      }
      this.saveInfoLoading = false
      this.editProfile = false
    },
    editProfileClick(){
      this.userCopy = JSON.parse(JSON.stringify(this.user))
      this.editProfile = true;
    },
    selectIndividual(){
      //If user has student plan --> go to billing to upgrade
      if (this.user.plan == 1){
        this.goToBilling()
      }
      //Else user is on free plan --> create new session to get indivual plan
      else {
        this.selectPlan(2)
      }
    }
  },
};
</script>

<style scoped>
.body {
  background: white;
  background-image: radial-gradient(rgb(194, 194, 194) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  height: 100%
}
</style>
