// This is not a paper.js tool and rather a series of functions that are activated on mousedown, mousemove, and mouseup
// It is always active and can be used at the same time as paper tools

import {useStore} from '@/store/store'
import paper from 'paper'

//Pan Props
var panning = false
var lastPoint = null
var mouseStartX
var mouseStartY

export function PanActivate(canvas){
  Reset()
  //Pan event listeners (desktop)
  canvas.addEventListener('mousedown', PanMouseDown)
  canvas.addEventListener('mousemove', PanMouseMove)
  canvas.addEventListener('mouseup', PanMouseUp)

  const store = useStore()
  if (store.onMobile){
    if (!store.pinchHammer) {
      const canvasElement = store.canvas.view.element;
      store.pinchHammer = new Hammer(canvasElement, {});
    }
    store.pinchHammer.get('pan').set({enable: true})
    store.pinchHammer.on('panstart', e => {
      PanMouseDown(e)
    })
    store.pinchHammer.on('pan', e => {
      PanMouseMove(e)
    })
    store.pinchHammer.on('panend', e => {
      PanMouseUp(e)
    })
  }

}

export function PanMouseDown(e){
  const store = useStore()
  if (store.onMobile && store.subTool == 'lasso') return

  if (store.onMobile && store.subTool == 'select'){
    lastPoint = new paper.Point(e.center)
    mouseStartX = e.center.x
    mouseStartY = e.center.y
    panning = true
  }
  else if (e.button == 1 || e.button == 2){
    mouseStartX = parseInt(e.clientX)
    mouseStartY = parseInt(e.clientY)
    lastPoint = {x: e.clientX, y: e.clientY}
    panning = true
  }
  else {
    //On Desktop w/ Select or Lasso --> Do Nothing
  }
}

export function PanMouseMove(e){
  if (!panning) return
  const store = useStore()
  if (!store.onMobile && panning || 
    store.onMobile && store.subTool == 'select' && 
    store.tools.select.selectedNodes.length == 0 && 
    store.selectedItems.length == 0){
      //Pan Canvas
      let eventPoint = store.onMobile ? e.center : {x: e.clientX, y: e.clientY}
      let currentMousePoint = new paper.Point(eventPoint)
      var panOffset = currentMousePoint.subtract(lastPoint)
      let panOffsetCalibrateToZoomLevel = panOffset.divide(store.canvas.view.zoom)
      lastPoint = currentMousePoint
      store.canvas.view.center = store.canvas.view.center.subtract(panOffsetCalibrateToZoomLevel);
      //Pan Grid
      store.grid.setPan(mouseStartX, mouseStartY, eventPoint.x, eventPoint.y);
  }
}

export function PanMouseUp(e){
  if (!panning) { Reset(); return}
  if (panning || store.onMobile && store.subTool == 'select'){
    const store = useStore()
    store.grid.lastAxisPosX = store.grid.axisPosX
    store.grid.lastAxisPosY = store.grid.axisPosY
    panning = false
  }
}

export function Reset(){
  panning = false
  lastPoint = null
}