<template>
  <v-card width="300" height="320" class="pa-6 d-flex flex-column" style="font-size:20px">
    <div class="font-weight-bold">
      ENTERPRISE
    </div>
    <div class="mb-2">
      <v-icon size="20" color="purple">fa:far fa-message</v-icon>
      <span class="ml-1 text-grey">Please Contact</span>
    </div>
    <div>
      <span >Unlimited Computes</span>
    </div>
    <div>
      <span>Image Underlay</span>
    </div>
    <div>
      <span >Save Sketches</span>
    </div>
    <div>
      <span >Share Sketches</span>
    </div>
    <div>
      <span>SSO Integration</span>
    </div>
    <v-btn :disabled="disabled" @click="clickContact()"
      class="mt-auto" :color="btnColor" v-if="btnText != ''" >
      <span class="text-white">Contact Us</span>  
    </v-btn>

    <v-dialog v-model="contact" width="400">
      <v-card class="pa-6">
        <h4 class="text-purple">Thank you for your interest in ForceSketch!</h4>
        <br>
        You can contact us at:
        <br>
        <div class="d-flex justify-space-between align-center">
          <span class="font-weight-bold">forcesketch.contact@gmail.com</span>
          <v-btn icon variant="plain" @click="copy" v-bind="props">
            <v-icon>fa: far fa-copy</v-icon>
          </v-btn>
        </div>
      </v-card>
      <v-snackbar :timeout="2000" v-model="copied" contained location="top" color="primary">
        <span class="text-white">Copied!</span>
      </v-snackbar>
    </v-dialog>
    
  </v-card>
</template>

<script>
import { useStore } from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import History from "@/utilities/History";
import * as modifiers from '@/utilities/Modifiers'

export default {
  props: {
    btnColor: {
      type: String,
      default: 'orange'
    },
    btnText: { 
      type: String,
      default: 'Get Started'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { toggleButton},
  setup(){
    const store = useStore()
    return {store, History, modifiers}
  },
  data: () => ({
    copied: false,
    contact: false
  }),
  created(){},
  computed: {},
  mounted() {},
  methods: {
    copy(){
      navigator.clipboard.writeText('forcesketch.contact@gmail.com')
      this.copied = true
    },
    clickContact(){
      this.contact = true
    }
  }
}
</script>