<template>
  <toggleButton :tooltip="'Save'">
    <v-icon>
      mdi:mdi-content-save
    </v-icon>
  
    <v-menu activator="parent">
      <v-list density="compact" width="190">
        <v-list-item 
          class="d-flex"
          v-if="(store.projectId == null || (store.projectId && !store.projectId.includes('template'))) && 
            store.userPermissions != 'Viewer'"
          @click="store.projectId == null ? openSaveDialog('saveNew') : openSaveDialog('save')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-content-save" color="grey-darken-2"></v-icon>
            Save
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="store.projectId"
          @click="openSaveDialog('saveAs')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-content-save-all" color="grey-darken-2"></v-icon>
            Save Copy
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="user.email == 'SXMoore@ThorntonTomasetti.com' || user.email == 'more.smoore@gmail.com'"
          @click="openSaveDialog('saveAsTemplate')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-note-plus" color="grey-darken-2"></v-icon>
            Create Template
          </v-list-item-title>
        </v-list-item>
        <v-list-item 
          v-if="user.email == 'SXMoore@ThorntonTomasetti.com' || user.email == 'more.smoore@gmail.com'"
          @click="openSaveDialog('saveTemplate')">
          <v-list-item-title>
            <v-icon size="small" icon="mdi:mdi-note-plus" color="grey-darken-2"></v-icon>
            Overwrite Template
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    
    <!--Dialog -->
    <v-dialog
        v-model="saveDialog"
        style="width: 500px"
    >
      <v-card>
        <v-card-title v-if="saveType=='save'">Save Sketch</v-card-title>
        <v-card-title v-if="saveType=='saveNew'">Save Sketch</v-card-title>
        <v-card-title v-if="saveType=='saveAs'">Save Copy of Sketch</v-card-title>
        <v-card-title v-if="saveType=='saveAsTemplate'">Save Sketch As A New Template</v-card-title>
        <v-card-title v-if="saveType=='saveTemplate'">Overwrite Existing Template</v-card-title>
        <v-card-subtitle>The current canvas view will be used as the thumbnail image</v-card-subtitle>
        <v-text-field
          v-if="saveType != 'save' "
          class="px-4 mt-2" 
          :label="saveType=='saveAsTemplate' ? 'Template Name' : 'Sketch Name'" 
          variant="outlined" density="compact"
          v-model="newName"
        ></v-text-field>
        <v-card-actions class="d-flex justify-space-around px-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn @click="Cancel()" color="grey" class="white--text">
          Cancel
          </v-btn>
          <v-btn @click="Save()" color="primary" :loading = "saveLoading">
          Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </toggleButton>
</template>

<script>
import {useStore} from '@/store/store'
import toggleButton from '@/components/ui/toggleButton.vue'
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  components: {
    toggleButton
  },
  setup(){
    const store = useStore()
    const { isAuthenticated, user } = useAuth0();
    return {
      store,
      isAuthenticated, 
      user,
    }
  },
  data: () => ({
    saveDialog: false,
    newName: '',
    saveLoading: false,
    saveType: null,
    imageId:""
  }),
  created(){
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    openSaveDialog(saveType){
      this.saveType=saveType
      this.saveDialog=true
    },
    async Save(){
      if (!this.isAuthenticated) return 

      this.saveLoading = true
      var elements = []
      var supports = []
      var pointLoads = []
      var lineLoads = []
      let imageInfo = []

      //Get Line Info
      for (let i = 0; i < this.store.drawingLayer.children.length; i++){
        let line = this.store.drawingLayer.children[i]
        var lineProps = {
          id: line.id,
          start: {x: line.segments[0].point.x, y: line.segments[0].point.y},
          end: {x: line.segments[1].point.x, y: line.segments[1].point.y},
          startFixity: line.data.start,
          endFixity: line.data.end,
          frameProps: line.data.frameProps,
        }
        elements.push(lineProps)
      }
      //Get Support Info
      for (let i = 0; i < this.store.supportLayer.children.length; i++){
        var support = this.store.supportLayer.children[i]
        var supportProps = {
          type: support.data.type,
          location: {x: support.data.location.x, y: support.data.location.y},
          id: support.id
        }
        supports.push(supportProps)
      }
      var pointL = this.store.loadLayer.children.filter((load) => load.data.type == 'Point Load')
      var lineL = this.store.loadLayer.children.filter((load) => load.data.type == 'Line Load')
      
      //Get Load Info
      for (let i=0; i < pointL.length; i++){
        var pointLoad = pointL[i]
        var pointLoadProps = {
          type: pointLoad.data.type,
          dir: pointLoad.data.dir,
          location: {x: pointLoad.data.location.x, y: pointLoad.data.location.y},
          magnitude: pointLoad.data.magnitude,
        }
        pointLoads.push(pointLoadProps)
      }
      for (let i=0; i < lineL.length; i++){
        var lineLoad = lineL[i]
        var lineLoadProps = {
          type: lineLoad.data.type,
          dir: lineLoad.data.dir,
          elementId: lineL[i].data.elementId,
          magnitude: lineLoad.data.magnitude,
        }
        lineLoads.push(lineLoadProps)
      }
      // Save and Download an SVG
      //var fileName = this.newName + ".svg"
      //var url = "data:image/svg+xml;utf8," + encodeURIComponent(this.store.canvas.project.exportSVG({asString:true, matchShapes:true}));
      // var link = document.createElement("a");
      // link.download = fileName;
      // link.href = url;
      // link.click()

      var dataStored = []
      this.store.loadLayer.children.forEach(child => {
        dataStored.push(child.data)
        child.data = null
      })
      
      //Hide grid and image layer before taking snapshot of canvas
      this.store.gridLayer.visible = false
      this.store.imageLayer.visible = false

      //Create Image Info
      if (this.store.imageLayer.children.length > 0){
        let raster = this.store.imageLayer.children[0]
        let imageInfoObj = {
          id:this.store.imageFile.id,
          scaling:{x: raster.scaling.x, y: raster.scaling.y},
          imgPosition: {x : raster.position.x , y :raster.position.y},
          rotation: raster.rotation,
        }
        imageInfo.push(imageInfoObj)
      }

      //Combine all canvas info      
      var canvasInfo = {
        createdBy: this.user,
        settings: {
          units: this.store.units,
          gridSize: this.store.gridSize,
          gridUnitMetric: this.store.gridUnitMetric,
          gridUnitImperial: this.store.gridUnitImperial,
          pointLoadMetric: this.store.pointLoadMetric,
          pointLoadImperial: this.store.pointLoadImperial,
          lineLoadMetric: this.store.lineLoadMetric,
          lineLoadImperial: this.store.lineLoadImperial,
          deflectionUnitMetric: this.store.deflectionUnitMetric,
          deflectionUnitImperial: this.store.deflectionUnitImperial,
          resultantForceUnitMetric: this.store.resultantForceUnitMetric,
          resultantForceUnitImperial: this.store.resultantForceUnitImperial,
          gravity: this.store.gravity,
          beamDegreesFromHorz: this.store.beamDegreesFromHorz,
          columnDegreesFromVert: this.store.columnDegreesFromVert,
          imageOpacity: this.store.imageOpacity,
          canvasCenter: {x: this.store.canvasCenter.x, y: this.store.canvasCenter.y}
        },
        elements: elements,
        supports: supports,
        pointLoads:pointLoads,
        lineLoads: lineLoads,
        imageInfo: imageInfo,
        SVG: "data:image/svg+xml;utf8," + encodeURIComponent(this.store.canvas.project.exportSVG({asString:true, matchShapes:true, embedImages:false})),
      }

      this.store.loadLayer.children.forEach((child, index) => {
        child.data = dataStored[index]
      })
      this.store.gridLayer.visible = true
      this.store.imageLayer.visible = true

      let unableToSave = false

      //Create new firestore canvas document
      var sketchObject = {...canvasInfo, name: this.newName}
      try { 
        if (this.saveType=='saveAs' || this.saveType=='saveNew'){
          let savedSketch = await this.$api({
            url: "/api/sketch/_sketch/create-sketch",
            method: "POST",
            data: {
              sketchInfo: JSON.stringify(sketchObject),
            }
          })
          if (savedSketch.data){
            this.$router.push("/canvas/" + savedSketch.data.id).catch((err) => {});
            this.store.projectId = savedSketch.data.id
            try{
              let permission = await this.$api({
                url: "/api/permission/_permission/create-permission",
                method: "POST",
                data: {
                  projectId: savedSketch.data.id,
                  userId: this.user.sub,
                  email: this.user.email.toLowerCase(),
                  permission: 'Owner'
                }
              })
              this.store.users = [permission.data]
              this.store.projectName = this.newName
            } catch(error){
              console.log('unable to create new permissions object: ', error)
            } 
          }
        } 
        //Make New Template - Only sxmoore@thorntontomasetti.com can make templates
        else if (this.saveType=='saveAsTemplate') {
          let savedTemplate = await this.$api({
            url: "/api/template/_template/create-template",
            method: "POST",
            data: {
              templateInfo: JSON.stringify(sketchObject),
            }
          })
          if(savedTemplate.data){
            this.$router
          .push("/template/" + savedTemplate.data.id)
          .catch((err) => {});
          this.store.projectName = this.newName
          }
        }
        //Update Template 
        else if (this.saveType=='saveTemplate'){
          let id = this.store.projectId.split('template')[1]
          let savedTemplate = await this.$api({
            url: "/api/template/_template/update-template",
            method: "POST",
            data: {
              templateInfo: JSON.stringify(sketchObject),
              id: id
            }
          })
          this.store.projectName = this.newName
        }
        //update existing project   
        else {
          let savedSketch = await this.$api({
            url: "/api/sketch/_sketch/update-sketch",
            method: "POST",
            data: {
              sketchInfo: JSON.stringify(canvasInfo),
              sketchId: this.store.projectId,
            }
          })
        }  
      }
      catch (error) {
        console.log(error)
        this.saveLoading = false
        this.saveType = false
        this.store.snackbar = true
        this.store.snackbarText = "Unable to Save, Try Again"
        unableToSave = true
      }
      
      if (this.store.imageFile && !unableToSave){
        try {
          if (this.store.imageFile.file){
            let fileId = this.store.imageFile.id
            let signedUrl = await this.$api({
              url: "/api/sketch/_sketch/upload-file",
              method: "POST",
              data: {
                fileId: fileId,
                projectId: this.store.projectId,
                contentType: this.store.imageFile.file.type
              }
            })
            if (signedUrl.data){
              const response = await fetch(signedUrl.data[0], {
                method: 'PUT',
                body: this.store.imageFile.file,
                headers: {
                  'Content-Type': this.store.imageFile.file.type,
                },
              });
              if (response.ok){
                let metadataAdded = await this.$api({
                  url: "/api/sketch/_sketch/add-file-metadata",
                  method: "POST",
                  data: {
                    fileId: fileId,
                    projectId: this.store.projectId,
                  }
                })
              }
            }
          }
          else {
            if (this.saveType.includes('saveAs')){
              let metadataAdded = await this.$api({
                url: "/api/sketch/_sketch/add-file-metadata",
                method: "POST",
                data: {
                  fileId: this.store.imageFile.id,
                  projectId: this.store.projectId,
                }
              })
            }
          }
        }
        catch(error){
          console.log("error uploading image:", error)
          throw error
        }
      }

      this.newName = null
      this.saveType = null
      this.saveLoading = false
      this.saveDialog = false
    },
    Cancel(){
      this.newName = ''
      this.saveDialog = false
      this.saveType=null
    }
  }
}
</script>

<style scoped>
</style>
