<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
  preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M413 4760 c-12 -5 -29 -20 -39 -34 -14 -23 -16 -64 -15 -376 l2 -350
    -149 0 c-143 0 -150 -1 -175 -24 -14 -13 -28 -39 -32 -57 -3 -19 -4 -425 -3
    -904 l3 -870 394 -883 c306 -686 400 -887 419 -900 14 -9 43 -17 65 -17 31 0
    45 7 68 33 l29 32 0 1035 0 1035 680 0 680 0 0 -987 c0 -962 1 -989 20 -1020
    30 -49 77 -59 181 -38 245 49 443 231 529 484 11 34 36 137 56 229 63 305 146
    569 252 807 l49 110 815 5 c896 5 837 1 865 67 20 48 19 630 -1 678 -30 70 69
    66 -1392 63 l-1316 -3 -29 -33 c-25 -28 -29 -40 -29 -92 l0 -60 -120 0 -120 0
    0 79 c0 70 -3 83 -25 109 -32 39 -72 48 -119 28 -47 -19 -66 -64 -66 -151 l0
    -65 -125 0 -125 0 0 70 c0 83 -17 123 -61 146 -40 20 -88 12 -120 -22 -21 -22
    -24 -37 -28 -110 l-3 -84 -124 0 -124 0 -1 63 c0 90 -16 128 -61 150 -48 24
    -89 15 -123 -25 -22 -26 -25 -39 -25 -108 l0 -78 -70 -4 c-60 -3 -74 -8 -98
    -31 l-27 -27 -5 -841 -5 -841 -278 628 -277 629 0 791 0 792 143 4 c154 4 182
    13 205 64 8 17 12 123 12 339 l0 315 53 -32 c62 -39 128 -103 154 -149 16 -31
    18 -70 23 -404 5 -405 5 -401 66 -430 28 -13 133 -15 753 -15 l721 0 0 -84 c0
    -79 2 -86 29 -115 l29 -31 1326 0 1326 0 32 29 33 29 3 290 c3 242 1 296 -12
    326 -29 70 64 66 -1441 66 l-1355 0 0 350 c0 386 -1 396 -60 420 -90 37 -354
    -60 -482 -178 -68 -64 -123 -166 -138 -257 -5 -33 -10 -189 -10 -347 l0 -288
    -305 0 -305 0 0 300 c0 276 -2 306 -20 361 -58 170 -214 309 -452 399 -57 21
    -90 24 -125 10z m1687 -574 l0 -344 31 -31 c29 -30 34 -31 115 -31 65 0 84 -3
    84 -14 0 -7 3 -28 6 -45 l7 -31 -257 0 -256 0 0 310 c0 219 4 317 12 337 21
    49 69 102 116 131 44 26 119 60 135 61 4 1 7 -154 7 -343z m2810 -566 l0 -160
    -1180 0 -1180 0 0 160 0 160 1180 0 1180 0 0 -160z m-2150 -1034 c0 -74 3 -86
    24 -109 28 -30 65 -42 102 -33 14 4 37 19 50 33 21 23 24 35 24 109 l0 84 130
    0 130 0 0 -81 c0 -79 1 -82 34 -115 38 -38 70 -43 117 -18 40 20 59 68 59 149
    l0 65 124 0 124 0 3 -85 c4 -77 7 -87 32 -112 39 -40 105 -40 144 0 25 25 28
    35 32 112 l3 85 124 0 124 0 0 -65 c0 -81 19 -129 59 -149 47 -25 79 -20 117
    18 33 33 34 36 34 115 l0 81 125 0 125 0 0 -47 c0 -77 11 -121 35 -148 43 -46
    107 -45 151 2 21 23 24 35 24 109 l0 84 50 0 50 0 0 -195 0 -195 -782 0 c-900
    0 -823 9 -877 -99 -141 -278 -260 -631 -331 -976 -17 -82 -38 -172 -47 -200
    -46 -144 -136 -258 -253 -319 l-70 -37 0 1010 0 1011 105 0 105 0 0 -84z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'caliperIcon',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>