<template>
  <div class="body d-flex justify-center" v-if="!loading">
    <div class="d-flex justify-center flex-wrap flex-column">
      <div class="d-flex align-center justify-center mb-8">
        <span class="font-weight-bold"
          style="letter-spacing:0px; font-size:25px">
          CHOOSE YOUR PLAN
        </span>
      </div>
      <div class="mt-4 d-flex flex-wrap justify-center">
        <FreePlan class="mx-3 my-2" :btnColor="'primary'" :btnText="'Start Now'" :disabled="false" @selected="selectFreePlan"></FreePlan>
        <StudentPlan v-if="user.isStudent" class="mx-3 my-2" :btnColor="'primary'" :btnText="'Select'" @selected="selectPlan(1)"></StudentPlan>
        <IndividualPlan class="mx-3 my-2" :btnColor="'primary'" :btnText="'Select'" @selected="selectPlan(2)"></IndividualPlan>
        <EnterprisePlan class="mx-3 my-2" :btnColor="'primary'"></EnterprisePlan>
      </div>
    </div>

  </div>
</template>

<script>
import {useStore} from '@/store/store'
import FreePlan from '@/components/plans/Free.vue'
import StudentPlan from '@/components/plans/Student.vue'
import IndividualPlan from '@/components/plans/Individual.vue'
import EnterprisePlan from '@/components/plans/Enterprise.vue'
import {selectPlan} from '@/composables/billing.js'

export default {
  components: {
    FreePlan, 
    StudentPlan, 
    IndividualPlan,
    EnterprisePlan
  },
  setup(){
    const store = useStore()
    return {
      store,
      selectPlan
    }
  },
  data() {
    return {
      user: null,
      loading: false
    };
  },
  async created(){
    this.loading = true
    this.user = await this.store.getUser()
    this.loading = false
  },
  computed: {
    
  },
  watch: {
  },
  async mounted() {
  },
  computed: {},
  methods: {
    goToDashboard(){
      this.$router.push('/dashboard')
    },
    async selectFreePlan(){
      try{
        this.user.plan = 0
        let updated = await this.$api({
          url: "/api/user/_user/update-user",
          method: "POST",
          data: {
            user: this.user
          }
        })
        if (updated.data){
          this.$router.push('/dashboard')
        }
      } catch(error) {
        console.log(error)
      }
    }
  },
};
</script>

<style scoped>
.body {
  background: white;
  background-image: radial-gradient(rgb(194, 194, 194) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -19px -19px;
  height: 100%
  }
</style>
