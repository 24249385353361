<template>
  <div style="width: 24px; height: 24px; margin-bottom: 5px">
    <v-icon :color="color" size="20" style="margin-bottom: 3px;">mdi:mdi-checkbox-blank-circle-outline</v-icon>
    <v-icon class="mdi-rotate-90" :color="color" size="30" style="margin-bottom: 3px; margin-left: -16px">mdi:mdi-minus</v-icon>
  </div>
</template>

<script>
export default {
  name: 'rollerY',
  props: {
    color: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>
