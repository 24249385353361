<template>
  <v-card 
    elevation="1" 
    :key = "cardKey"
    style="position:absolute !important; width: auto; border: 1px solid black; height: fit-content"
    :style="elementPropsStyle">
    <!-- Top Bar -->
    <div class="d-flex px-2 justify-space-between" 
      style="height: 50px; border-bottom: 1px solid black; width:auto"
      :style="store.analyzeMode && (selectedType.includes('Load') || selectedType=='support') ? 'border: none' : '' "
      >

      <!-- Item Type -->
      <div v-if="getSelectedItemTypes.length > 1" style="width: 100%; max-width:150px; min-width:110px">
        <v-select 
          label="filter Type"
          density="compact"
          variant="outlined"
          :items="getSelectedItemTypes"
          :model-value="selectedType"
          class="text-capitalize mt-2"
          max-width=100
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true,
          }"
        >
          <template v-slot:selection="item">
            {{item.item.title}}{{item.item.raw.count>1 && item.item.title != 'All'? 's' : ''}} 
            ({{item.item.raw.count}})
          </template>
          <template v-slot:item="item">
            <v-list-item @click="changeSelectedItemType(item.item.title)" :value="item">
              <v-list-item-title class="text-capitalize">
                {{item.item.title}}{{item.item.raw.count>1 && item.item.title != 'All'? 's' : ''}} 
                ({{item.item.raw.count}})
            </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div v-else class="d-flex align-center text-capitalize mr-2 ml-1" 
        style="font-size:14px">
        {{selectedType}}
      </div>

      <div class="d-flex align-center mr-2" style="width:100%" v-if="!(selectedType == 'support' || selectedType.includes('Load'))">
        <!-- Section Size -->
        <template v-if="selectedType != 'All'">
          <v-divider vertical inset thickness="2" class="border-opacity-100 mx-2"></v-divider>
          <div style="margin-top: -0px" v-if="!store.analyzeMode">
            <SectionSizeDropDownVue :inputSize="filteredItems.data.frameProps.size" @sizeSelected="changeMemberSize($event)">
            </SectionSizeDropDownVue>
          </div>
          <div v-else class="d-flex flex-column ml-2" style="min-width: 80px">
            <span style="font-size:12px; color:#BDBDBD; margin-top: -18px">Size</span>
            <span v-if="filteredItems.data.frameProps.size != 'varies'" 
              class="text-white" style="font-size: 16px; position:absolute; top: 18px">
              {{filteredItems.data.frameProps.size}}
            </span>
            <span v-else class="text-white" style="font-size: 16px; position:absolute; top: 18px">varies</span>
          </div>
          <v-divider vertical inset thickness="2" class="border-opacity-100 mx-2"></v-divider>

          <!-- Length -->
          <!-- <div class="d-flex flex-column ml-2" style="min-width: 60px">
            <span style="font-size:12px; margin-top: -20px" class="text-grey">Length</span>
            <span v-if="filteredItems.length != 'varies'" 
              class="" style="font-size: 14px; position:absolute; top: 18px">
              {{((filteredItems.length/store.grid.gridSpacing*store.gridSize)).toFixed(2)}} 
              {{store.units =='metric' ? store.gridUnitMetric : store.gridUnitImperial}}
            </span>
            <span v-else style="font-size: 16px; position:absolute; top: 18px">varies</span>
          </div> -->
        </template>
        
        <!-- Weight -->
        <div v-if="filteredItems.weight" class="d-flex flex-colum ml-1" style="min-width: 80px">
          <span style="font-size:12px; margin-top: -20px" class="text-grey">Weight</span>
          <span style="font-size: 14px; position:absolute; top: 20px">
            {{filteredItems.weight.toFixed(3)}} {{store.units == 'imperial' ? 'kips' : 'kg'}}
          </span>
        </div>
      </div>

      <div style="width: fit-content" class="d-flex">
        <!-- Move -->
        <v-btn v-if="!store.analyzeMode && 
          (selectedType=='beam' || selectedType=='brace' || selectedType=='column' || selectedType=='All')" 
          @click="move" size="26" class="mt-3 ml-2"
          icon elevation="0" variant="plain">
          <v-icon size="small">mdi: mdi-cursor-move</v-icon>
          <v-tooltip activator="parent" location="top">Move</v-tooltip>
        </v-btn>
        <!-- Copy -->
        <v-btn v-if="!store.analyzeMode && 
          (selectedType=='beam' || selectedType=='brace' || selectedType=='column' || selectedType=='All')" 
          @click="copy" size="26" class="mt-3 ml-2"
          icon elevation="0" variant="plain">
          <v-icon size="small">fa:far fa-copy</v-icon>
          <v-tooltip activator="parent" location="top">Copy</v-tooltip>
        </v-btn>
        <!--Trash Can -->
        <v-btn v-if="!store.analyzeMode" @click="removeSelected" size="25" class="mt-3 ml-2"
          icon elevation="0" variant="plain">
          <v-icon size="small">fa:fas fa-trash-can</v-icon>
          <v-tooltip activator="parent" location="top">Delete</v-tooltip>
        </v-btn>
      </div>
    </div>    

    <!-- Line Props -->
    <template v-if="selectedType == 'beam' || selectedType =='column' || selectedType=='brace' || selectedType=='Frame'">
      <div class="d-flex mx-2" style="height:55px; max-width:355px">
        <div class="px-0 mr-2 d-flex align-center" style="width:130px">
          <div style="width:140px; margin-top:-10px">
            <span class="text-grey" style="font-size:12px;">Orientation</span>
            <v-btn height="30" width="130" style="margin-top:-3px" variant="outlined" @click="toggleOrientation(filteredItems.data.frameProps.orientation)">
                <span class="text-none font-weight-regular" style="font-size:14px; letter-spacing:normal">
                  {{filteredItems.data.frameProps.orientation==0 ? 'Strong Axis' : 
                    filteredItems.data.frameProps.orientation==90 ? 'Weak Axis' : 'Varies'}}
                </span>
            </v-btn>
          </div>
        </div>
        <v-divider vertical inset thickness="2" class="border-opacity-100 mx-2"></v-divider>
        <div class="pl-2 d-flex align-center" style="width:100%">
          <div style="width:90px; margin-top:-10px" class="mr-2">
            <span class="text-grey" style="font-size:12px;">Start</span>
            <v-btn height="30" width="90" style="margin-top:-3px" variant="outlined" 
              @click="changeRelease('start', filteredItems.data.start)">
              <span class="text-none font-weight-regular text-capitalize" style="font-size:14px; letter-spacing:normal">{{filteredItems.data.start}}</span>
            </v-btn>
          </div>
          <div style="width:80px; margin-top:-10px" class="mr-2">
            <span class="text-grey" style="font-size:12px;">End</span>
            <v-btn height="30" width="90" style="margin-top:-3px" variant="outlined" 
              @click="changeRelease('end', filteredItems.data.end)">
              <span class="text-none font-weight-regular text-capitalize" style="font-size:14px; letter-spacing:normal">{{filteredItems.data.end}}</span>
            </v-btn>
          </div>
        </div>
      </div>
    </template>

    <!-- Load props -->
    <template v-if="selectedType=='Point Load' || selectedType=='Line Load'">
      <div class="d-flex ma-2">
        <toggleButton 
          class="d-flex align-center"
          @click="changeLoadDirection('Down')"
          :active="filteredItems.data.dir == 'Down'">
          <v-icon size="25" class="mt-1" v-if="selectedType=='Point Load'">
            mdi:mdi-arrow-down
          </v-icon>
          <LineLoad v-else />
        </toggleButton>
        <toggleButton 
          class="d-flex align-center"
          @click="changeLoadDirection('Up')"
          :active="filteredItems.data.dir == 'Up'">
          <v-icon size="25" class="mt-1" v-if="selectedType=='Point Load'">
            mdi:mdi-arrow-up
          </v-icon>
          <LineLoad v-else style="transform:rotate(180deg)"/>
        </toggleButton>
        <toggleButton 
          class="d-flex align-center"
          @click="changeLoadDirection('Right')"
          :active="filteredItems.data.dir == 'Right'">
          <v-icon size="25" class="mt-1" v-if="selectedType=='Point Load'">
            mdi:mdi-arrow-right
          </v-icon>
          <LineLoad v-else style="transform:rotate(-90deg)"/>
        </toggleButton>
        <toggleButton 
          class="d-flex align-center" 
          @click="changeLoadDirection('Left')"
          :active="filteredItems.data.dir == 'Left'">
          <v-icon size="25" class="mt-1" v-if="selectedType=='Point Load'">
            mdi:mdi-arrow-left
          </v-icon>
          <LineLoad v-else style="transform:rotate(90deg)"/>
        </toggleButton>
        <v-btn v-if="filteredItems.data.dir == 'varies'" flat :style="filteredItems.data.dir == 'varies' ? 
          'border: 1px solid rgb(var(--v-theme-primary)); background-color: #E3FFFD' : ''" 
          style="margin:1px; padding: 0px !important" class="text-none">
          <span class="font-italic">varies</span>
        </v-btn>
        
        <v-divider vertical thickness="2" class="border-opacity-100 mx-2 my-2"></v-divider>
        
        <div class="d-flex align-center" v-if="!store.analyzeMode">
          <div v-if="filteredItems.data.magnitude =='varies'">
            <v-btn variant="outlined" density="compact" style="height: 36px"
              class="px-1" @click="editVariedLoadEntry()">
              <span class="font-italic" style="letter-spacing: 0px">magnitude varies</span>
              <v-icon class="ml-2">mdi:mdi-lead-pencil</v-icon>
            </v-btn>
          </div>

          <template v-else>
            <div style="width: fit-contents" class="mt-1">
              <NumberInput :input="filteredItems.data.magnitude"
                :label="filteredItems.data.type=='Point Load' ? 
                  store.getPointLoadUnit : store.getLineLoadUnit"
                @valueChange="updateLoadValue($event)"
                class="load"
              ></NumberInput>
            </div>
          </template>
          <v-btn
            v-if="!store.analyzeMode && 
            filteredItems.data.wasModified" 
            @click="scaleLoad()" icon size="25" flat   
            class="ml-2 bg-primary" density="compact"
            >
              <v-icon size="20" color="white">mdi:mdi-check</v-icon>
              <v-tooltip activator="parent" location="top">Update Load</v-tooltip>
          </v-btn>  
        </div>
      </div>

    </template>

    <!-- Line Mass Props -->
    <template v-else-if="selectedType=='Line Mass' && !store.analyzeMode">
      <div class="pa-2 d-flex justify-space-between align-center" style="min-width: 150px">
        <div v-if="filteredItems.data.magnitude =='varies'">
          <v-btn variant="outlined" density="compact" style="height: 36px"
            class="px-1" @click="editVariedLoadEntry()">
            <span class="font-italic" style="letter-spacing: 0px">magnitude varies</span>
            <v-icon class="ml-2">mdi:mdi-lead-pencil</v-icon>
          </v-btn>
        </div>
        <div v-else style="width: fit-contents"  class="mt-1">
          <NumberInput :input="filteredItems.data.magnitude"
            label="Line Mass"
            @valueChange="updateLoadValue($event)"
            class="load"
          >
          </NumberInput>
        </div>
        <v-btn
          v-if="!store.analyzeMode && 
          filteredItems.data.wasModified" 
          @click="scaleLoad()" icon size="25" flat   
          class="ml-2 bg-primary" density="compact"
          >
            <v-icon size="20" color="white">mdi:mdi-check</v-icon>
            <v-tooltip activator="parent" location="top">Update Load</v-tooltip>
        </v-btn> 
      </div>
    </template>

    <!-- support props -->
    <template v-else-if="selectedType=='support' && !store.analyzeMode">
      <div class="pa-2 d-flex justify-space-between align-center">
        <toggleButton 
          :tooltip="'Pin'" :active="filteredItems.data.type == 'pin'"
          @click="changeSupport('pin')">
          <v-icon>
          mdi:mdi-triangle-outline
        </v-icon>
        </toggleButton>
        <toggleButton 
          :tooltip="'Fix'" :active="filteredItems.data.type == 'fix'"
          @click="changeSupport('fix')">
          <fixIcon/>
        </toggleButton>
        <toggleButton 
          :tooltip="'RollerX'" :active="filteredItems.data.type == 'rollerX'"
          @click="changeSupport('rollerX')">
          <rollerX/>
        </toggleButton>
        <toggleButton 
          :tooltip="'RollerY'" :active="filteredItems.data.type == 'rollerY'"
          @click="changeSupport('rollerY')">
          <rollerY/>
        </toggleButton>
        <v-btn v-if="filteredItems.data.type == 'varies'" flat :style="filteredItems.data.type == 'varies' ? 
          'border: 1px solid rgb(var(--v-theme-primary)); background-color: #E3FFFD' : ''" 
          style="margin:1px; padding: 0px !important" class="text-none">
          <span class="font-italic">varies</span>
        </v-btn>
      </div>
    </template>
  </v-card> 
</template>

<script>
import paper from 'paper'
import ScrollPicker from '@/components/ui/ScrollPicker.vue'
import {useStore} from '@/store/store'
import * as utils from '../utilities/Utils'
import * as styles from '../styles/paperStyles'
import * as modifiers from "@/utilities/Modifiers"
import History from "@/utilities/History"
import * as commands from "@/utilities/Commands"
import SectionSizeDropDownVue from '@/components/ui/SectionSizeDropDown.vue'
import toggleButton from '@/components/ui/toggleButton.vue'
import fixIcon from '@/assets/icons/fixIcon.vue'
import rollerX from '@/assets/icons/rollerX.vue'
import rollerY from '@/assets/icons/rollerY.vue'
import NumberInput from '@/components/ui/NumberInput.vue'
import LineLoad from '../assets/icons/lineLoad.vue';
import {copy} from '@/composables/CopyPasteMove.js'

export default {
  setup(){
    const store = useStore()
    return {store, copy}
  },
  components: {
    ScrollPicker,
    SectionSizeDropDownVue,
    toggleButton,
    fixIcon,
    rollerX,
    rollerY,
    NumberInput,
    LineLoad
  },
  data: () => ({
    endReleases: ['fixed', 'pin'],
    selectedType: null,
    filteredItems: null,
    distributedLoadKey: 0,
    cardKey: 0
  }),
  created() {
    this.selectedType = this.getSelectedItemTypes[0].title
    this.filteredItems = this.getFilteredItems()
  },
  mounted() {
    if (!this.store.onMobile){
      document.addEventListener('keydown', this.deleteKeydown)
    }
  },
  unmounted(){
    document.removeEventListener('keydown', this.deleteKeydown)
  },
  computed: {
    getSelectedItems(){
      return this.store.selectedItems
    },
    getSelectedItemTypes(){
      let uniqueTypes = []
      var countFrames = 0
      this.store.selectedItems.forEach(item => {
        let uniqueTypesTitle = uniqueTypes.map(type => type.title)
        if (item.data.layer == 'drawing'){
          countFrames++
          if (item.data.frameProps.type == 'beam'){
            if (!uniqueTypesTitle.includes('beam')) {
              uniqueTypes.push({title: 'beam', count: 1})
            }
            else {
              uniqueTypes.find(element => element.title == 'beam').count++
            }
          }
          if (item.data.frameProps.type == 'column'){
            if (!uniqueTypesTitle.includes('column')) {
              uniqueTypes.push({title: 'column', count: 1})
            }
            else {
              uniqueTypes.find(element => element.title == 'column').count++
            }
          }
          if (item.data.frameProps.type == 'brace'){
            if (!uniqueTypesTitle.includes('brace')) {
              uniqueTypes.push({title: 'brace', count: 1})
            }
            else {
              uniqueTypes.find(element => element.title == 'brace').count++
            }
          }
        }
        if (item.data.layer == 'support'){
          if (!uniqueTypesTitle.includes('support')){
            uniqueTypes.push({title: 'support', count: 1})
          }
          else {
            uniqueTypes.find(element => element.title == 'support').count++
          }
        }
        if (item.data.layer == 'load'){
          if (item.data.type == 'Point Load'){
            if (!uniqueTypesTitle.includes('Point Load')){
              uniqueTypes.push({title: 'Point Load', count: 1})
            }
            else {
              uniqueTypes.find(element => element.title == 'Point Load').count++
            }
          }
          else if (item.data.type == 'Line Mass'){
            if (!uniqueTypesTitle.includes('Line Mass')){
              uniqueTypes.push({title: 'Line Mass', count: 1})
            }
            else {
              uniqueTypes.find(element => element.title == 'Line Mass').count++
            }
          }
          else {
            if (!uniqueTypesTitle.includes('Line Load')){
              uniqueTypes.push({title: 'Line Load', count: 1})
            }
            else {
              uniqueTypes.find(element => element.title == 'Line Load').count++
            }
          }
        }
      })
      if (uniqueTypes.length > 1) {
        let frameTypes = ['beam', 'brace', 'column']
        let numFrameTypes = 0
        uniqueTypes.forEach(type => {
          if (frameTypes.includes(type.title)){
            numFrameTypes++
          }
        })
        if (numFrameTypes > 1) uniqueTypes.unshift({title: 'Frame', count: countFrames})
        uniqueTypes.unshift({title: 'All', count: this.store.selectedItems.length})
      }
      return uniqueTypes
    },
    elementPropsStyle(){
      let bbox = utils.getBoundingBox(this.store.selectedItems)
      if (bbox){
        let elemTop = this.store.canvas.view.projectToView(bbox.topCenter)
        let elemBottom = this.store.canvas.view.projectToView(bbox.bottomCenter)
        let elemBaseLocation
        let elemBase
        // If the top center is close to the top edge of the window => Use bottom of bounding box instead
        if (elemTop.y < 200){
          elemBase = elemBottom
          elemBaseLocation = 'bottom'
        }
        else {
          elemBase = elemTop
          elemBaseLocation = 'top'
        }

        //Force property panel to stay within bounds of window screen
        let position = 'position: fixed;'
        if ((elemBase.x + 200) > window.innerWidth){
          position += 'right: 0px;'
        }
        if ((elemBase.x - 200) < 0){
          position += 'left: 0px;'
        }
        if (elemBase.y > (window.innerHeight + 50)){
          position += 'bottom: 0px;'
        }
        if (elemTop.y < 50){
          position += "top: 50px;"
        }
        
        //Construct panel position
        let yAddition = elemBaseLocation=='top' ? 150 : -25
        if (!(position.includes('top') || position.includes('bottom'))) position+=`top: ${elemBase.y-yAddition}px;`
        if (!(position.includes('left') || position.includes('right'))) position+=`left: ${elemBase.x-138}px;`
        return position
      }
    } 
  },
  methods: {
    deleteKeydown(e){
      if(e.code == 'Delete' || e.code == 'Backspace'){
        this.removeSelected()
      }
    },
    changeSelectedItemType(type){
      this.selectedType = type
      this.filteredItems = this.getFilteredItems()
      if (type == 'All') {
        modifiers.highlight(this.store.selectedItems)
        return
      }
      this.store.selectedItems.forEach(item => {
        if (item.data.layer == 'drawing' && (item.data.frameProps.type == type || this.selectedType == 'Frame')){
          modifiers.highlight(item)
        }
        else if (item.data.layer == 'load' && item.data.type == type){
          modifiers.highlight(item)
        }
        else if (item.data.layer == type){
          modifiers.highlight(item)
        }
        else {
          modifiers.unhighlight(item)
        }
      })
    },
    editVariedLoadEntry(){
      let loads = this.store.selectedItems.filter(item => item.data.layer == 'load' &&
        item.data.type == this.selectedType)
      loads.forEach(item => {
        item.data.originalMagnitude = JSON.parse(JSON.stringify(item.data.magnitude))
      })
      this.filteredItems.data.magnitude = loads[0].data.magnitude
      this.filteredItems.data.wasModified = true
    },
    getFilteredItems(){
      if (this.selectedType == 'beam' || this.selectedType == 'column' || this.selectedType == 'brace' || this.selectedType == 'Frame'){
        let type = this.selectedType == 'beam' ? 'beam' : this.selectedType == 'column' ? 'column' : 'brace'
        let filteredItems
        if (this.selectedType == 'Frame') filteredItems = this.store.selectedItems.filter(item => item.data.layer == 'drawing')
        else filteredItems = this.store.selectedItems.filter(item => item.data.layer == 'drawing' && 
          item.data.frameProps.type == type)
        let weight = this.getWeight(filteredItems)
        if (filteredItems.length == 1){
          let length
          if (!filteredItems[0]._length) {
            length = utils.distance2Points(filteredItems[0].segments[0].point, filteredItems[0].segments[1].point)
          }
          else length = filteredItems[0]._length
          return {
            data: utils.shallowClone(filteredItems[0].data),
            length: length,
            weight: weight
          }
        }
        else {
          let data = {
            start: [...new Set(filteredItems.map(item => item.data.start))].length == 1 ? 
              filteredItems[0].data.start : 'varies',
            end: [...new Set(filteredItems.map(item => item.data.end))].length == 1 ? 
              filteredItems[0].data.end : 'varies', 
            frameProps: {
              type: [...new Set(filteredItems.map(item => item.data.frameProps.type))].length == 1 ? 
              filteredItems[0].data.frameProps.type : 'varies',
              size: [...new Set(filteredItems.map(item => item.data.frameProps.size.name))].length == 1 ? 
              filteredItems[0].data.frameProps.size : 'varies',
              orientation: [...new Set(filteredItems.map(item => item.data.frameProps.orientation))].length == 1 ? 
              filteredItems[0].data.frameProps.orientation : 'varies',
            },
          }
          let itemLengths = filteredItems.map(item => {
            if (!item.length) return utils.distance2Points(filteredItems[0].segments[0].point, filteredItems[0].segments[1].point)
            else return item.length
          })
          let length = [...new Set(itemLengths)].length == 1 ? 
            filteredItems[0].length : 'varies'
          return {
            data: data,
            length: length,
            count: filteredItems.length,
            weight: weight
          }
        }
      }
      else if (this.selectedType == 'Point Load' || this.selectedType == 'Line Load' || this.selectedType=="Line Mass"){
        let filteredItems = this.store.selectedItems.filter(item => item.data.layer == 'load' && 
          item.data.type == this.selectedType)
        if (filteredItems.length == 1){
          let dataClone = utils.shallowClone(filteredItems[0].data)
          return {data: dataClone}
        }
        else {
          return {
            data: {
              dir: [...new Set(filteredItems.map(item => item.data.dir))].length == 1 ? 
                filteredItems[0].data.dir : 'varies',
              magnitude: [...new Set(filteredItems.map(item => item.data.magnitude))].length == 1 ? 
                filteredItems[0].data.magnitude : 'varies',
              type: [...new Set(filteredItems.map(item => item.data.type))].length == 1 ?
                filteredItems[0].data.type : 'varies',
              count: filteredItems.length  
            }
          }
        }
      }
      else if (this.selectedType == 'support'){
        let filteredItems = this.store.selectedItems.filter(item => item.data.layer == 'support')
        if (filteredItems.length == 1){
          let dataClone = utils.shallowClone(filteredItems[0].data)
          return {data: dataClone}
        }
        else {
          return {
            data: {
              type: [...new Set(filteredItems.map(item => item.data.type))].length == 1 ? 
              filteredItems[0].data.type : 'varies',
              count: filteredItems.length
            }
          }
        }
      } 
      //All
      else {
        let frameItems = this.store.selectedItems.filter(item => item.data.layer == 'drawing')
        let weight = this.getWeight(frameItems)
        return {data: "", weight: weight}
      }
    },
    getWeight(items){
        let totWeight = 0
        items.forEach(item => {
          //Calc weight for custom section
          let weightPerLength
          if (item.data.frameProps.size.userId){
            weightPerLength = utils.getWeightPerLength(item.data.frameProps.size)[this.store.units]
          }
          else {
            weightPerLength = item.data.frameProps.size.weight
            if (!item.data.frameProps.size.family.includes(this.store.units)){
              let convertedWeight = utils.weightPerLengthConversion(item.data.frameProps.size.weight)
              weightPerLength = convertedWeight
            }
          }
          let gridCellSizeProjected = this.store.grid.cellSize/this.store.grid.canvasZoom
          let length = (item.length/gridCellSizeProjected)*this.store.grid.realGridSpacing
          totWeight += weightPerLength*length
        })
        if (this.store.units == 'imperial') totWeight = totWeight/1000
        return totWeight
    },
    updateLoadValue(e){
      let loads = this.store.selectedItems.filter(item => item.data.type == this.selectedType)
      loads.forEach(load => {
        if (!load.data.hasOwnProperty('originalMagnitude')){
          load.data.originalMagnitude = JSON.parse(JSON.stringify(load.data.magnitude))
        }
      })
      this.filteredItems.data.wasModified = true
      this.filteredItems.data.magnitude = Number(e)
    },
    changeLoadDirection(newDirection){
      let batchCommand = new commands.BatchCommand()
      let loads = this.store.selectedItems.filter(item => item.data.type == this.selectedType)
      
      loads.forEach(load => {
        if (load.data.dir == newDirection) return 
        let loadCommand = new commands.LoadCommand()
        loadCommand.addPrevLoad(load)
        let newLoad
        if (this.selectedType == 'Line Load'){
          let lineElement = utils.findAssociatedLine(load)
          newLoad = this.store.tools.loads.scaleLineLoad(
            lineElement, 
            load.data.magnitude, 
            newDirection
          )
        }
        else {
          newLoad = this.store.tools.loads.scalePointLoad(load.data.location, load.data.magnitude, newDirection)
        }
        newLoad._id = load._id
        modifiers.remove(load)
        this.store.tools.loads.addLoadText(newLoad)
        this.store.replaceInSelection(newLoad)
        loadCommand.addCurLoad(newLoad)
        batchCommand.add(loadCommand)
      })
      History.add(batchCommand)
      this.filteredItems = this.getFilteredItems()
    },
    scaleLoad(){
      let batchCommand = new commands.BatchCommand()
      let loads = this.store.selectedItems.filter(item => item.data.type == this.selectedType)
      loads.forEach(item => {
        var newMagnitude = JSON.parse(JSON.stringify(this.filteredItems.data.magnitude))
        var originalMagnitude
        if (item.data.hasOwnProperty('originalMagnitude') && 
          item.data.originalMagnitude != newMagnitude){
            originalMagnitude = item.data.originalMagnitude
            delete item.data.originalMagnitude
          }
        else {
          return
        }
        //Scale Point Load  
        if (item.data.type == "Point Load"){
          let loadCommand = new commands.LoadCommand()
          loadCommand.addPrevLoad(item)
          item.data.magnitude = originalMagnitude
          var insertionPoint = item.data.location
          let curPointLoad = this.store.tools.loads.scalePointLoad(insertionPoint, newMagnitude, item.data.dir)
          curPointLoad._id = item._id
          modifiers.remove(item)
          this.store.tools.loads.addLoadText(curPointLoad)
          this.store.replaceInSelection(curPointLoad)
          loadCommand.addCurLoad(curPointLoad)
          batchCommand.add(loadCommand)
        }
        //Scale Line Load
        else {
          let loadCommand = new commands.LoadCommand()
          loadCommand.addPrevLoad(item)
          item.data.magnitude = originalMagnitude
          let lineElement = utils.findAssociatedLine(item)
          let direction = item.data.dir
          let isLineMass = item.data.type == 'Line Mass' ? true : false
          var scaledLoadGroup = this.store.tools.loads.scaleLineLoad(
            lineElement, 
            newMagnitude, 
            direction,
            isLineMass
          )
          scaledLoadGroup._id = item._id
          modifiers.remove(item)
          this.store.tools.loads.addLoadText(scaledLoadGroup)
          this.store.replaceInSelection(scaledLoadGroup)
          loadCommand.addCurLoad(scaledLoadGroup)
          batchCommand.add(loadCommand)
        }
      })
      History.add(batchCommand)
      delete this.filteredItems.data.wasModified
      this.filteredItems = this.getFilteredItems()
    },
    removeSelected(){
      let batchCommand = new commands.BatchCommand()
      let indexesToRemove = []
      this.store.selectedItems.forEach((item, index) => {
        if (modifiers.isHighlighted(item)){
          let removeCommands = modifiers.remove(item)
          batchCommand.add(removeCommands)
          indexesToRemove.push(index)
        }
      })
      this.store.selectedItems = this.store.selectedItems.filter((item, index) => !indexesToRemove.includes(index))
      if (this.store.selectedItems.length > 0){
        this.selectedType = this.getSelectedItemTypes[0].title
        this.filteredItems = this.getFilteredItems()
        this.changeSelectedItemType(this.selectedType)
      }
      History.add(batchCommand)
      this.cardKey++
    },
    changeSupport(newSupportType){
      let newSupports = []
      let batchCommand = new commands.BatchCommand()
      let supports = this.store.selectedItems.filter(item => item.data.layer == 'support')
      supports.forEach(item => {
        var supportLocation = item.data.location
        var currentSupportType = item.data.type
        if (currentSupportType == newSupportType){
          newSupports.push(item)
        }
        else{
          let supportCommand = new commands.SupportCommand()
          supportCommand.addPrevSupport(item)
          modifiers.remove(item)
          var newSupportIcon = this.store.tools.supports.newSupportIcon(newSupportType)
          this.store.tools.supports.scaleInitial(newSupportIcon)
          newSupportIcon.position = {x: supportLocation.x, y: supportLocation.y + newSupportIcon.bounds.height/2}
          newSupportIcon.data = {
            type: newSupportType,
            location: supportLocation,
            layer: 'support'
          }
          this.store.supportLayer.addChild(newSupportIcon)
          newSupports.push(newSupportIcon)
          supportCommand.addCurSupport(newSupportIcon)
          batchCommand.add(supportCommand)
        }
      })
      this.store.setSelectedItem(newSupports)
      History.add(batchCommand)
      this.filteredItems = this.getFilteredItems()
    },
    changeRelease(endPoint, releaseType){
      let batchCommand = new commands.BatchCommand()
      let lines = this.store.selectedItems.filter(item => item.data.layer == 'drawing' && 
        (item.data.frameProps.type == this.selectedType || this.selectedType=='Frame'))
      lines.forEach(item => {
        if (item.data[endPoint] == 'fixed' && releaseType != 'varies'){
          item.data[endPoint] = 'released'
          modifiers.drawReleaseIcon(item, endPoint)
          batchCommand.add(new commands.ReleaseCommand(item._id, endPoint, true))
        }
        else if (item.data[endPoint] == 'released' || ((item.data[endPoint] == 'released' && releaseType == 'varies'))) {
          item.data[endPoint] = 'fixed'
          var releaseToRemove = this.store.releaseLayer.children.find(element => 
            element.data.elementId == item.id &&
            element.data.end == endPoint
          );
          releaseToRemove.remove()
          batchCommand.add(new commands.ReleaseCommand(item._id, endPoint, false))
        }
      })
      History.add(batchCommand)
      this.filteredItems = this.getFilteredItems()
    },
    toggleOrientation(orientation){
      let batchCommand = new commands.BatchCommand()
      let lines = this.store.selectedItems.filter(item => item.data.layer == 'drawing' && 
        (item.data.frameProps.type == this.selectedType || this.selectedType=='Frame'))
      lines.forEach(item => {
        if (orientation == 0){
          let lineCommand = new commands.LineCommand()
          lineCommand.addPrevPath(item)
          item.data.frameProps.orientation = 90
          item.dashArray=[10/this.store.grid.canvasZoom, 10/this.store.grid.canvasZoom]
          lineCommand.addCurPath(item)
          batchCommand.add(lineCommand)
        }
        else {
          let lineCommand = new commands.LineCommand()
          lineCommand.addPrevPath(item)
          item.data.frameProps.orientation = 0
          item.dashArray = null
          lineCommand.addCurPath(item)
          batchCommand.add(lineCommand)
        }
      })  
      History.add(batchCommand)
      this.filteredItems = this.getFilteredItems()
    },
    changeMemberSize(size){
      let batchCommand = new commands.BatchCommand()
      let lines = this.store.selectedItems.filter(item => item.data.layer == 'drawing' 
        && (item.data.frameProps.type == this.selectedType || this.selectedType=='Frame'))
      lines.forEach(item => {
        let lineCommand = new commands.LineCommand()
        lineCommand.addPrevPath(item)
        item.data.frameProps.size = size
        var textToModify = this.store.memberSizesLayer.children.find(text => text.data.elementId == item.id)
        textToModify.content = size.name
        lineCommand.addCurPath(item)
        batchCommand.add(lineCommand)
      })
      History.add(batchCommand)
    },
    move(){
      this.store.moving = true
      this.store.snackbarText = "Select Point To Move From"
      this.store.snackbarTimeOut = -1
      this.store.snackbar = true
    }
  },
  beforeUnmount(){
    if (this.filteredItems.data.hasOwnProperty('wasModified')){
      delete this.filteredItems.data.wasModified
    }
    this.selectedType = null
  }
}
</script>

<style scoped>
#elementProps{
  background-color: gray;
  opacity: 0.8;
}
span{
  font-size: 14px;
}
/* v-select modifiers to make more compact*/
.v-input:deep(.v-field--appended) {
  padding-inline-end: 6px !important;
}
.v-input:deep(.v-field__input){
  padding-inline-start: 12px !important;
  padding-inline-end: 0px !important;
  min-height: 36px !important;
  max-height: 36px !important;
}
.load.v-input:deep(.v-field__input){
  padding-inline-end: 12px !important;
}
.v-input:deep(.v-select__selection-text){
  font-size: 14px
}
.v-input:deep(.v-select__selection){
  font-size: 14px
}
.compactSelect:deep(.v-field__field){
  max-width: 80px !important
}
</style>